import { DateTime } from 'luxon';
import { formatSessionTime } from 'web/utils/dateFormat';
import { nanoid } from 'nanoid/non-secure';
import { DateRange, EventType } from 'web/components/calendar/common/types';
import useAvailability from './useAvailability';
import { useCallback, useMemo } from 'react';
import useAvailabilityMutations from './useAvailabilityMutations';

const weekdays = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

const addHoursMinutes = (startOfDay: DateTime, time: string) => {
  // time is HH:MM "09:00"
  const [hh, mm] = time.split(/:/g).map(parseFloat);
  return startOfDay.plus({ hours: hh, minutes: mm });
};

const expandWeeklyPeriod = (
  expertTimezone: string,
  dateOnWeek: Date,
  day: string,
  startTime: string,
  endTime: string,
) => {
  const weekDay = DateTime.fromJSDate(dateOnWeek)
    .setZone(expertTimezone, { keepLocalTime: true })
    .startOf('week')
    .plus({ days: weekdays.indexOf(day) });
  const start = addHoursMinutes(weekDay, startTime).toLocal();
  const end = addHoursMinutes(weekDay, endTime).toLocal();

  if (start.day != end.day && !(end.hour === 0 && end.minute === 0)) {
    const midDate = end.startOf('day').toJSDate();
    return [
      { start: start.toJSDate(), end: midDate },
      { start: midDate, end: end.toJSDate() },
    ];
  }

  return [{ start: start.toJSDate(), end: end.toJSDate() }];
};

const convertRulesToEvents = (rules: introwise.AvailabilityRules, dateRange: DateRange) => {
  const result: EventType[] = [];
  if (rules && dateRange) {
    rules.weeklyPeriods.forEach((period) => {
      const { day, startTime, endTime } = period;
      const dateRanges = expandWeeklyPeriod(
        rules.timeZone,
        new Date(dateRange.start.getTime() / 2 + dateRange.end.getTime() / 2),
        day,
        startTime,
        endTime,
      );
      dateRanges.forEach(({ start, end }) => {
        result.push({
          id: nanoid(),
          start,
          end,
          title: formatSessionTime(start),
          extendedProps: {
            mobileTitle: formatSessionTime(start),
            recurring: true,
          },
        });
      });
    });
  }
  return result;
};

const convertPeriodsToEvents = (periods: introwise.AvailabilityPeriod[]) => {
  let result: Array<EventType> = [];
  if (periods) {
    result = periods.map((period) => {
      const { start, end, id } = period;
      return {
        id,
        start: new Date(start),
        end: new Date(end),
        title: formatSessionTime(new Date(start)),
        extendedProps: {
          mobileTitle: formatSessionTime(new Date(start)),
          recurring: false,
        },
      };
    });
  }
  return result;
};

const to24hrTime = (date: number) =>
  `${new Date(date).getHours().toFixed(0).padStart(2, '0')}:${new Date(date).getMinutes().toFixed(0).padStart(2, '0')}`;

const convertEventToWeeklyPeriods = (event: EventType) => {
  const { start, end } = event;
  const result: introwise.AvailabilityRules['weeklyPeriods'] = [];
  let current = start.getTime();
  const endTimeStamp = end.getTime();
  while (current < endTimeStamp) {
    const day = weekdays[(new Date(current).getDay() + 6) % 7]; // sunday: -1 to 6
    const endIter = Math.min(current + 24 * 60 * 60 * 1000, endTimeStamp); // full day or limited by end date

    const startTime = to24hrTime(current);
    const endTime = to24hrTime(endIter);
    result.push({
      day,
      startTime,
      endTime: endTime === '00:00' ? '24:00' : endTime,
    });

    current = endIter;
  }

  return result;
};

const convertEventsToRules = (events: EventType[]) => {
  const weeklyPeriods = events
    .filter((e) => e.extendedProps.recurring)
    .reduce((acc, e) => acc.concat(convertEventToWeeklyPeriods(e)), [] as introwise.AvailabilityRules['weeklyPeriods']);
  return weeklyPeriods;
};

const useAvailabilityCalendar = ({
  dateRange,
  timeZone,
  availabilityId,
}: {
  dateRange: DateRange;
  timeZone: string;
  availabilityId: string;
}) => {
  const { rules, periods, loading } = useAvailability({ dateRange, availabilityId });
  const { deletePeriod, savePeriod, saveRules, submitting } = useAvailabilityMutations(availabilityId);
  const events = useMemo(
    () => [...convertRulesToEvents(rules, dateRange), ...convertPeriodsToEvents(periods)],
    [dateRange, periods, rules],
  );
  const deleteEvent = useCallback(
    async (event: EventType) => {
      if (event.extendedProps.recurring) {
        const recurringEventsNew = events.filter((e) => e.extendedProps.recurring && e.id !== event.id);
        const weeklyPeriods = convertEventsToRules(recurringEventsNew);
        await saveRules({ timeZone, weeklyPeriods });
      } else {
        await deletePeriod(event.id);
      }
    },
    [deletePeriod, events, saveRules, timeZone],
  );
  const updateEvent = useCallback(
    async (event: EventType) => {
      if (event.extendedProps.recurring) {
        const recurringEventsPrev = events.filter((e) => e.extendedProps.recurring && e.id !== event.id);
        const recurringEventsNew = recurringEventsPrev.concat(event);
        const weeklyPeriods = convertEventsToRules(recurringEventsNew);
        await saveRules({ timeZone, weeklyPeriods });
      } else {
        await savePeriod(event.id, {
          start: event.start,
          end: event.end,
        });
      }
    },
    [events, savePeriod, saveRules, timeZone],
  );

  return useMemo(
    () => ({ events, deleteEvent, updateEvent, loading, submitting }),
    [deleteEvent, events, loading, submitting, updateEvent],
  );
};

export default useAvailabilityCalendar;
