import React, { useMemo } from 'react';
import StyledReactSelect from '../StyledReactSelect';
import { formatTimeValue, generateDataListOptions, parseTime, TimeValue } from './common';
import { InputProps, components } from 'react-select';

const timeFilter = (candidate: { value: string; label: string; data: unknown }, input: string) => {
  const parsed = parseTime(input);
  const compareValue = parsed ? formatTimeValue(parsed) : input;
  const compareLabel = parsed ? formatTimeValue(parsed) : input;
  return !input || candidate.value.startsWith(compareValue) || candidate.label.startsWith(compareLabel);
};

const NumericInput = (props: InputProps) => (
  <components.Input
    {...props}
    // ReactSelect InputProps are missing custom input props
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    inputMode="numeric"
    pattern="[0-9]*"
  />
);

const TimeSelect = ({
  min,
  max,
  value,
  onChange,
  className,
}: {
  min: TimeValue;
  max: TimeValue;
  value: TimeValue;
  onChange: (value: TimeValue) => void;
  className?: string;
}) => {
  const parseAndSet = (strValue: string) => {
    const parsed = parseTime(strValue);
    onChange(parsed);
  };
  const options = useMemo(() => generateDataListOptions(min, max), [min, max]);
  const selected = options.find((option) => option.value === formatTimeValue(value));
  return (
    <StyledReactSelect
      className={className}
      onChange={(option: { value: string }) => {
        parseAndSet(option.value);
      }}
      value={selected}
      options={options}
      filterOption={timeFilter}
      maxMenuHeight={112}
      blurInputOnSelect
      components={{
        Input: NumericInput,
      }}
    />
  );
};

export default TimeSelect;
