import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import sc from 'web/components/styled';
import { dropdown, dropdownItem, dropdownItemActive, dropdownItemIcon, dropdownItemText } from './dropdown.css';

const DropdownItemText = sc.span(dropdownItemText);
const DropdownItemActive = sc.span(dropdownItemActive);

const DropdownItemIcon = ({ icon }: { icon: IconProp }) => (
  <FontAwesomeIcon className={dropdownItemIcon} icon={icon} fixedWidth />
);

export { dropdown, dropdownItem, DropdownItemText, DropdownItemActive, DropdownItemIcon };
