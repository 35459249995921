import { LexicalEditor } from 'lexical';
import React, { useEffect, useMemo, useState } from 'react';
import { Button, FormDescription, FormError, FormGroup, Input, Label, LabelText } from 'web/components/elements';
import Spinner from 'web/components/Spinner';
import useErrorStateHandler from 'web/hooks/useErrorStateHandler';
import themeClasses from 'web/styles/themeClasses.css';
import { fetchOembed } from 'web/utils/oembed';
import { CloudAppCommandPayload, INSERT_CLOUDAPP_COMMAND } from '../CloudAppPlugin';

const regex = /(share\.getcloudapp\.com)\/(.*?((?=[&#?])|$))/m;
const getDropId = (youtubeUrl: string) => {
  const match = youtubeUrl.match(regex);
  return match && match.length >= 2 ? match[2] : null;
};

const InsertCloudAppDialog = ({ activeEditor, onClose }: { activeEditor: LexicalEditor; onClose: () => void }) => {
  const [text, setText] = useState('');
  const [error, setError] = useErrorStateHandler();
  const [loading, setLoading] = useState(false);
  const [payload, setPayload] = useState<CloudAppCommandPayload | null>(null);

  const onClick = async () => {
    const dropId = getDropId(text);
    if (dropId) {
      setLoading(true);
      try {
        const oembed = await fetchOembed(text, 'cloudapp');
        setPayload({
          dropId,
          url: text,
          oembed,
          caption: '',
        });
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    }
  };

  const dispatchedRef = React.useRef(false);

  useEffect(() => {
    if (payload && !dispatchedRef.current) {
      activeEditor.dispatchCommand(INSERT_CLOUDAPP_COMMAND, payload);
      dispatchedRef.current = true;
      onClose();
    }
  }, [payload, activeEditor, onClose]);

  const isDisabled = useMemo(() => text === '' || !getDropId(text), [text]);

  return (
    <>
      <h4 className={themeClasses({ marginY: 0 })}>Insert CloudApp video or image</h4>
      <FormGroup>
        <Label>
          <LabelText>CloudApp URL</LabelText>
          <Input
            placeholder="https://share.getcloudapp.com/QwUnRDXZ"
            onChange={(event) => setText(event.target.value)}
            value={text}
          />
        </Label>
        <FormDescription>CloudApp shareable Drop URL</FormDescription>
      </FormGroup>
      <FormGroup>
        <div>
          <Button disabled={isDisabled || loading} onClick={onClick} size="md">
            {loading && <Spinner />}
            <span>Insert</span>
          </Button>
        </div>
      </FormGroup>
      {error && (
        <FormGroup>
          <FormError>Cannot embed this media. Please try again later.</FormError>
        </FormGroup>
      )}
    </>
  );
};

export default InsertCloudAppDialog;
