import { collection, Firestore, getDocs, getDocsFromServer, limit, query, where } from 'firebase/firestore';
import { useMemo } from 'react';
import useFirestore from 'web/components/FirebaseContext/useFirestore';

export type SlugValidator = (slug: string, useCache?: boolean, useFirestoreCache?: boolean) => Promise<boolean>;

const makeSlugUniquenessValidator = (initialSlug: string | null, firestore: Firestore): SlugValidator => {
  const cache: { [key: string]: boolean } = {};
  return async (slug: string, useCache = true, useFirestoreCache = true) => {
    if (initialSlug && slug === initialSlug) {
      return true;
    }
    const cachedRes = cache[slug];
    if (cachedRes !== undefined && useCache) {
      return cachedRes;
    } else {
      const pageQuery = query(collection(firestore, 'pages'), where('slug', '==', slug), limit(1));
      const res = await (useFirestoreCache ? getDocs(pageQuery) : getDocsFromServer(pageQuery)).then(
        (snapshot) => snapshot.empty,
      );
      cache[slug] = res;
      return res;
    }
  };
};

const useSlugUniquenessValidator = (initialSlug: string) => {
  const firestore = useFirestore();
  const slugValidator = useMemo(() => makeSlugUniquenessValidator(initialSlug, firestore), [firestore, initialSlug]);

  return slugValidator;
};

export default useSlugUniquenessValidator;
