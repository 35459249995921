type CountryCommon = { id: string; name: string };
type StripeUnsupportedCountry = { stripe?: false };
type StripeSupportedCountry = { stripe: true; currency: introwise.Currency };
type PayPalSupportedCountry = { paypal: true; currency: introwise.Currency };
type PayPalUnsupportedCountry = { paypal?: false };
type SupportedCountry = { unsupported?: false };
type UnsupportedCountry = { unsupported: true };
type Country = CountryCommon &
  (SupportedCountry | UnsupportedCountry) &
  (StripeSupportedCountry | StripeUnsupportedCountry) &
  (PayPalSupportedCountry | PayPalUnsupportedCountry);

const countries: Country[] = [
  {
    id: 'AD',
    name: 'Andorra',
    paypal: true,
    currency: 'EUR',
  },
  {
    id: 'AE',
    name: 'United Arab Emirates',
    stripe: true,
    paypal: true,
    currency: 'AED',
  },
  {
    id: 'AF',
    name: 'Afghanistan',
    unsupported: true,
  },
  {
    id: 'AG',
    name: 'Antigua and Barbuda',
    paypal: true,
    currency: 'USD',
  },
  {
    id: 'AI',
    name: 'Anguilla',
  },
  {
    id: 'AL',
    name: 'Albania',
    paypal: true,
    currency: 'EUR',
  },
  {
    id: 'AM',
    name: 'Armenia',
  },
  {
    id: 'AO',
    name: 'Angola',
  },
  {
    id: 'AQ',
    name: 'Antarctica',
  },
  {
    id: 'AR',
    name: 'Argentina',
    paypal: true,
    currency: 'USD',
  },
  {
    id: 'AS',
    name: 'American Samoa',
  },
  {
    id: 'AT',
    name: 'Austria',
    stripe: true,
    paypal: true,
    currency: 'EUR',
  },
  {
    id: 'AU',
    name: 'Australia',
    stripe: true,
    paypal: true,
    currency: 'AUD',
  },
  {
    id: 'AW',
    name: 'Aruba',
  },
  {
    id: 'AX',
    name: 'Aland',
  },
  {
    id: 'AZ',
    name: 'Azerbaijan',
  },
  {
    id: 'BA',
    name: 'Bosnia and Herzegovina',
    paypal: true,
    currency: 'EUR',
  },
  {
    id: 'BB',
    name: 'Barbados',
    paypal: true,
    currency: 'USD',
  },
  {
    id: 'BD',
    name: 'Bangladesh',
  },
  {
    id: 'BE',
    name: 'Belgium',
    stripe: true,
    paypal: true,
    currency: 'EUR',
  },
  {
    id: 'BF',
    name: 'Burkina Faso',
  },
  {
    id: 'BG',
    name: 'Bulgaria',
    stripe: true,
    paypal: true,
    currency: 'BGN',
  },
  {
    id: 'BH',
    name: 'Bahrain',
    paypal: true,
    currency: 'USD',
  },
  {
    id: 'BI',
    name: 'Burundi',
  },
  {
    id: 'BJ',
    name: 'Benin',
  },
  {
    id: 'BL',
    name: 'Saint Barthélemy',
  },
  {
    id: 'BM',
    name: 'Bermuda',
    paypal: true,
    currency: 'USD',
  },
  {
    id: 'BN',
    name: 'Brunei',
  },
  {
    id: 'BO',
    name: 'Bolivia',
  },
  {
    id: 'BQ',
    name: 'Bonaire',
  },
  {
    id: 'BR',
    name: 'Brazil',
    paypal: true,
    currency: 'BRL',
  },
  {
    id: 'BS',
    name: 'Bahamas',
    paypal: true,
    currency: 'USD',
  },
  {
    id: 'BT',
    name: 'Bhutan',
  },
  {
    id: 'BV',
    name: 'Bouvet Island',
  },
  {
    id: 'BW',
    name: 'Botswana',
    paypal: true,
    currency: 'USD',
  },
  {
    id: 'BY',
    name: 'Belarus',
    unsupported: true,
  },
  {
    id: 'BZ',
    name: 'Belize',
    paypal: true,
    currency: 'USD',
  },
  {
    id: 'CA',
    name: 'Canada',
    stripe: true,
    paypal: true,
    currency: 'CAD',
  },
  {
    id: 'CC',
    name: 'Cocos [Keeling] Islands',
  },
  {
    id: 'CD',
    name: 'Democratic Republic of the Congo',
    unsupported: true,
  },
  {
    id: 'CF',
    name: 'Central African Republic',
    unsupported: true,
  },
  {
    id: 'CG',
    name: 'Republic of the Congo',
  },
  {
    id: 'CH',
    name: 'Switzerland',
    stripe: true,
    paypal: true,
    currency: 'CHF',
  },
  {
    id: 'CI',
    name: 'Ivory Coast',
    unsupported: true,
  },
  {
    id: 'CK',
    name: 'Cook Islands',
  },
  {
    id: 'CL',
    name: 'Chile',
    paypal: true,
    currency: 'USD',
  },
  {
    id: 'CM',
    name: 'Cameroon',
  },
  {
    id: 'CN',
    name: 'China',
    paypal: true,
    currency: 'USD',
  },
  {
    id: 'CO',
    name: 'Colombia',
    paypal: true,
    currency: 'USD',
  },
  {
    id: 'CR',
    name: 'Costa Rica',
    paypal: true,
    currency: 'USD',
  },
  {
    id: 'CU',
    name: 'Cuba',
    unsupported: true,
  },
  {
    id: 'CV',
    name: 'Cape Verde',
  },
  {
    id: 'CW',
    name: 'Curacao',
  },
  {
    id: 'CX',
    name: 'Christmas Island',
  },
  {
    id: 'CY',
    name: 'Cyprus',
    stripe: true,
    paypal: true,
    currency: 'EUR',
  },
  {
    id: 'CZ',
    name: 'Czech Republic',
    stripe: true,
    paypal: true,
    currency: 'CZK',
  },
  {
    id: 'DE',
    name: 'Germany',
    stripe: true,
    paypal: true,
    currency: 'EUR',
  },
  {
    id: 'DJ',
    name: 'Djibouti',
  },
  {
    id: 'DK',
    name: 'Denmark',
    stripe: true,
    paypal: true,
    currency: 'DKK',
  },
  {
    id: 'DM',
    name: 'Dominica',
    paypal: true,
    currency: 'USD',
  },
  {
    id: 'DO',
    name: 'Dominican Republic',
    paypal: true,
    currency: 'USD',
  },
  {
    id: 'DZ',
    name: 'Algeria',
    paypal: true,
    currency: 'USD',
  },
  {
    id: 'EC',
    name: 'Ecuador',
    paypal: true,
    currency: 'USD',
  },
  {
    id: 'EE',
    name: 'Estonia',
    stripe: true,
    paypal: true,
    currency: 'EUR',
  },
  {
    id: 'EG',
    name: 'Egypt',
    paypal: true,
    currency: 'USD',
  },
  {
    id: 'EH',
    name: 'Western Sahara',
  },
  {
    id: 'ER',
    name: 'Eritrea',
  },
  {
    id: 'ES',
    name: 'Spain',
    stripe: true,
    paypal: true,
    currency: 'EUR',
  },
  {
    id: 'ET',
    name: 'Ethiopia',
  },
  {
    id: 'FI',
    name: 'Finland',
    stripe: true,
    paypal: true,
    currency: 'EUR',
  },
  {
    id: 'FJ',
    name: 'Fiji',
    paypal: true,
    currency: 'USD',
  },
  {
    id: 'FK',
    name: 'Falkland Islands',
  },
  {
    id: 'FM',
    name: 'Micronesia',
  },
  {
    id: 'FO',
    name: 'Faroe Islands',
    paypal: true,
    currency: 'EUR',
  },
  {
    id: 'FR',
    name: 'France',
    stripe: true,
    paypal: true,
    currency: 'EUR',
  },
  {
    id: 'GA',
    name: 'Gabon',
  },
  {
    id: 'GB',
    name: 'United Kingdom',
    stripe: true,
    paypal: true,
    currency: 'GBP',
  },
  {
    id: 'GD',
    name: 'Grenada',
    paypal: true,
    currency: 'USD',
  },
  {
    id: 'GE',
    name: 'Georgia',
    paypal: true,
    currency: 'USD',
  },
  {
    id: 'GF',
    name: 'French Guiana',
    paypal: true,
    currency: 'EUR',
  },
  {
    id: 'GG',
    name: 'Guernsey',
  },
  {
    id: 'GH',
    name: 'Ghana',
  },
  {
    id: 'GI',
    name: 'Gibraltar',
  },
  {
    id: 'GL',
    name: 'Greenland',
    paypal: true,
    currency: 'USD',
  },
  {
    id: 'GM',
    name: 'Gambia',
  },
  {
    id: 'GN',
    name: 'Guinea',
  },
  {
    id: 'GP',
    name: 'Guadeloupe',
    paypal: true,
    currency: 'EUR',
  },
  {
    id: 'GQ',
    name: 'Equatorial Guinea',
  },
  {
    id: 'GR',
    name: 'Greece',
    stripe: true,
    paypal: true,
    currency: 'EUR',
  },
  {
    id: 'GS',
    name: 'South Georgia and the South Sandwich Islands',
  },
  {
    id: 'GT',
    name: 'Guatemala',
    paypal: true,
    currency: 'USD',
  },
  {
    id: 'GU',
    name: 'Guam',
  },
  {
    id: 'GW',
    name: 'Guinea-Bissau',
  },
  {
    id: 'GY',
    name: 'Guyana',
  },
  {
    id: 'HK',
    name: 'Hong Kong',
    stripe: true,
    paypal: true,
    currency: 'HKD',
  },
  {
    id: 'HM',
    name: 'Heard Island and McDonald Islands',
  },
  {
    id: 'HN',
    name: 'Honduras',
    paypal: true,
    currency: 'USD',
  },
  {
    id: 'HR',
    name: 'Croatia',
    stripe: true,
    paypal: true,
    currency: 'EUR',
  },
  {
    id: 'HT',
    name: 'Haiti',
  },
  {
    id: 'HU',
    name: 'Hungary',
    stripe: true,
    paypal: true,
    currency: 'HUF',
  },
  {
    id: 'ID',
    name: 'Indonesia',
    paypal: true,
    currency: 'USD',
  },
  {
    id: 'IE',
    name: 'Ireland',
    stripe: true,
    paypal: true,
    currency: 'EUR',
  },
  {
    id: 'IL',
    name: 'Israel',
    paypal: true,
    currency: 'USD',
  },
  {
    id: 'IM',
    name: 'Isle of Man',
  },
  {
    id: 'IN',
    name: 'India',
    paypal: true,
    currency: 'USD',
  },
  {
    id: 'IO',
    name: 'British Indian Ocean Territory',
  },
  {
    id: 'IQ',
    name: 'Iraq',
    unsupported: true,
  },
  {
    id: 'IR',
    name: 'Iran',
    unsupported: true,
  },
  {
    id: 'IS',
    name: 'Iceland',
    paypal: true,
    currency: 'EUR',
  },
  {
    id: 'IT',
    name: 'Italy',
    stripe: true,
    paypal: true,
    currency: 'EUR',
  },
  {
    id: 'JE',
    name: 'Jersey',
  },
  {
    id: 'JM',
    name: 'Jamaica',
    paypal: true,
    currency: 'USD',
  },
  {
    id: 'JO',
    name: 'Jordan',
    paypal: true,
    currency: 'USD',
  },
  {
    id: 'JP',
    name: 'Japan',
    stripe: true,
    paypal: true,
    currency: 'JPY',
  },
  {
    id: 'KE',
    name: 'Kenya',
    paypal: true,
    currency: 'USD',
  },
  {
    id: 'KG',
    name: 'Kyrgyzstan',
  },
  {
    id: 'KH',
    name: 'Cambodia',
  },
  {
    id: 'KI',
    name: 'Kiribati',
  },
  {
    id: 'KM',
    name: 'Comoros',
  },
  {
    id: 'KN',
    name: 'Saint Kitts and Nevis',
    paypal: true,
    currency: 'USD',
  },
  {
    id: 'KP',
    name: 'North Korea',
    unsupported: true,
  },
  {
    id: 'KR',
    name: 'South Korea',
    paypal: true,
    currency: 'USD',
  },
  {
    id: 'KW',
    name: 'Kuwait',
    paypal: true,
    currency: 'USD',
  },
  {
    id: 'KY',
    name: 'Cayman Islands',
    paypal: true,
    currency: 'USD',
  },
  {
    id: 'KZ',
    name: 'Kazakhstan',
    paypal: true,
    currency: 'USD',
  },
  {
    id: 'LA',
    name: 'Laos',
  },
  {
    id: 'LB',
    name: 'Lebanon',
    unsupported: true,
  },
  {
    id: 'LC',
    name: 'Saint Lucia',
    paypal: true,
    currency: 'USD',
  },
  {
    id: 'LI',
    name: 'Liechtenstein',
    paypal: true,
    stripe: true,
    currency: 'EUR',
  },
  {
    id: 'LK',
    name: 'Sri Lanka',
  },
  {
    id: 'LR',
    name: 'Liberia',
  },
  {
    id: 'LS',
    name: 'Lesotho',
    paypal: true,
    currency: 'USD',
  },
  {
    id: 'LT',
    name: 'Lithuania',
    stripe: true,
    paypal: true,
    currency: 'EUR',
  },
  {
    id: 'LU',
    name: 'Luxembourg',
    stripe: true,
    paypal: true,
    currency: 'EUR',
  },
  {
    id: 'LV',
    name: 'Latvia',
    stripe: true,
    paypal: true,
    currency: 'EUR',
  },
  {
    id: 'LY',
    name: 'Libya',
    unsupported: true,
  },
  {
    id: 'MA',
    name: 'Morocco',
    paypal: true,
    currency: 'USD',
  },
  {
    id: 'MC',
    name: 'Monaco',
    paypal: true,
    currency: 'EUR',
  },
  {
    id: 'MD',
    name: 'Moldova',
    paypal: true,
    currency: 'EUR',
  },
  {
    id: 'ME',
    name: 'Montenegro',
  },
  {
    id: 'MF',
    name: 'Saint Martin',
  },
  {
    id: 'MG',
    name: 'Madagascar',
  },
  {
    id: 'MH',
    name: 'Marshall Islands',
  },
  {
    id: 'MK',
    name: 'North Macedonia',
  },
  {
    id: 'ML',
    name: 'Mali',
  },
  {
    id: 'MM',
    name: 'Myanmar [Burma]',
    unsupported: true,
  },
  {
    id: 'MN',
    name: 'Mongolia',
  },
  {
    id: 'MO',
    name: 'Macao',
  },
  {
    id: 'MP',
    name: 'Northern Mariana Islands',
  },
  {
    id: 'MQ',
    name: 'Martinique',
    paypal: true,
    currency: 'EUR',
  },
  {
    id: 'MR',
    name: 'Mauritania',
  },
  {
    id: 'MS',
    name: 'Montserrat',
  },
  {
    id: 'MT',
    name: 'Malta',
    stripe: true,
    paypal: true,
    currency: 'EUR',
  },
  {
    id: 'MU',
    name: 'Mauritius',
    paypal: true,
    currency: 'USD',
  },
  {
    id: 'MV',
    name: 'Maldives',
  },
  {
    id: 'MW',
    name: 'Malawi',
    paypal: true,
    currency: 'USD',
  },
  {
    id: 'MX',
    name: 'Mexico',
    paypal: true,
    stripe: true,
    currency: 'MXN',
  },
  {
    id: 'MY',
    name: 'Malaysia',
    paypal: true,
    currency: 'USD',
  },
  {
    id: 'MZ',
    name: 'Mozambique',
    paypal: true,
    currency: 'USD',
  },
  {
    id: 'NA',
    name: 'Namibia',
  },
  {
    id: 'NC',
    name: 'New Caledonia',
    paypal: true,
    currency: 'USD',
  },
  {
    id: 'NE',
    name: 'Niger',
  },
  {
    id: 'NF',
    name: 'Norfolk Island',
  },
  {
    id: 'NG',
    name: 'Nigeria',
  },
  {
    id: 'NI',
    name: 'Nicaragua',
    paypal: true,
    currency: 'USD',
  },
  {
    id: 'NL',
    name: 'Netherlands',
    stripe: true,
    paypal: true,
    currency: 'EUR',
  },
  {
    id: 'NO',
    name: 'Norway',
    stripe: true,
    paypal: true,
    currency: 'NOK',
  },
  {
    id: 'NP',
    name: 'Nepal',
  },
  {
    id: 'NR',
    name: 'Nauru',
  },
  {
    id: 'NU',
    name: 'Niue',
  },
  {
    id: 'NZ',
    name: 'New Zealand',
    stripe: true,
    paypal: true,
    currency: 'NZD',
  },
  {
    id: 'OM',
    name: 'Oman',
    paypal: true,
    currency: 'USD',
  },
  {
    id: 'PA',
    name: 'Panama',
    paypal: true,
    currency: 'USD',
  },
  {
    id: 'PE',
    name: 'Peru',
    paypal: true,
    currency: 'USD',
  },
  {
    id: 'PF',
    name: 'French Polynesia',
    paypal: true,
    currency: 'USD',
  },
  {
    id: 'PG',
    name: 'Papua New Guinea',
  },
  {
    id: 'PH',
    name: 'Philippines',
    paypal: true,
    currency: 'USD',
  },
  {
    id: 'PK',
    name: 'Pakistan',
  },
  {
    id: 'PL',
    name: 'Poland',
    stripe: true,
    paypal: true,
    currency: 'PLN',
  },
  {
    id: 'PM',
    name: 'Saint Pierre and Miquelon',
  },
  {
    id: 'PN',
    name: 'Pitcairn Islands',
  },
  {
    id: 'PR',
    name: 'Puerto Rico',
  },
  {
    id: 'PS',
    name: 'Palestine',
  },
  {
    id: 'PT',
    name: 'Portugal',
    stripe: true,
    paypal: true,
    currency: 'EUR',
  },
  {
    id: 'PW',
    name: 'Palau',
    paypal: true,
    currency: 'USD',
  },
  {
    id: 'PY',
    name: 'Paraguay',
    paypal: true,
    currency: 'USD',
  },
  {
    id: 'QA',
    name: 'Qatar',
    paypal: true,
    currency: 'USD',
  },
  {
    id: 'RE',
    name: 'Réunion',
    paypal: true,
    currency: 'EUR',
  },
  {
    id: 'RO',
    name: 'Romania',
    stripe: true,
    paypal: true,
    currency: 'RON',
  },
  {
    id: 'RS',
    name: 'Serbia',
    paypal: true,
    currency: 'EUR',
  },
  {
    id: 'RU',
    name: 'Russia',
  },
  {
    id: 'RW',
    name: 'Rwanda',
  },
  {
    id: 'SA',
    name: 'Saudi Arabia',
    paypal: true,
    currency: 'USD',
  },
  {
    id: 'SB',
    name: 'Solomon Islands',
  },
  {
    id: 'SC',
    name: 'Seychelles',
    paypal: true,
    currency: 'USD',
  },
  {
    id: 'SD',
    name: 'Sudan',
    unsupported: true,
  },
  {
    id: 'SE',
    name: 'Sweden',
    stripe: true,
    paypal: true,
    currency: 'SEK',
  },
  {
    id: 'SG',
    name: 'Singapore',
    stripe: true,
    paypal: true,
    currency: 'SGD',
  },
  {
    id: 'SH',
    name: 'Saint Helena',
  },
  {
    id: 'SI',
    name: 'Slovenia',
    stripe: true,
    paypal: true,
    currency: 'EUR',
  },
  {
    id: 'SJ',
    name: 'Svalbard and Jan Mayen',
  },
  {
    id: 'SK',
    name: 'Slovakia',
    stripe: true,
    paypal: true,
    currency: 'EUR',
  },
  {
    id: 'SL',
    name: 'Sierra Leone',
  },
  {
    id: 'SM',
    name: 'San Marino',
    paypal: true,
    currency: 'EUR',
  },
  {
    id: 'SN',
    name: 'Senegal',
    paypal: true,
    currency: 'USD',
  },
  {
    id: 'SO',
    name: 'Somalia',
    unsupported: true,
  },
  {
    id: 'SR',
    name: 'Suriname',
  },
  {
    id: 'SS',
    name: 'South Sudan',
    unsupported: true,
  },
  {
    id: 'ST',
    name: 'São Tomé and Príncipe',
  },
  {
    id: 'SV',
    name: 'El Salvador',
    paypal: true,
    currency: 'USD',
  },
  {
    id: 'SX',
    name: 'Sint Maarten',
  },
  {
    id: 'SY',
    name: 'Syria',
    unsupported: true,
  },
  {
    id: 'SZ',
    name: 'Swaziland',
  },
  {
    id: 'TC',
    name: 'Turks and Caicos Islands',
    paypal: true,
    currency: 'USD',
  },
  {
    id: 'TD',
    name: 'Chad',
  },
  {
    id: 'TF',
    name: 'French Southern Territories',
  },
  {
    id: 'TG',
    name: 'Togo',
  },
  {
    id: 'TH',
    name: 'Thailand',
    stripe: true,
    paypal: true,
    currency: 'THB',
  },
  {
    id: 'TJ',
    name: 'Tajikistan',
  },
  {
    id: 'TK',
    name: 'Tokelau',
  },
  {
    id: 'TL',
    name: 'East Timor',
    unsupported: true,
  },
  {
    id: 'TM',
    name: 'Turkmenistan',
  },
  {
    id: 'TN',
    name: 'Tunisia',
  },
  {
    id: 'TO',
    name: 'Tonga',
  },
  {
    id: 'TR',
    name: 'Turkey',
  },
  {
    id: 'TT',
    name: 'Trinidad and Tobago',
    paypal: true,
    currency: 'USD',
  },
  {
    id: 'TV',
    name: 'Tuvalu',
  },
  {
    id: 'TW',
    name: 'Taiwan',
    paypal: true,
    currency: 'USD',
  },
  {
    id: 'TZ',
    name: 'Tanzania',
  },
  {
    id: 'UA',
    name: 'Ukraine',
  },
  {
    id: 'UG',
    name: 'Uganda',
  },
  {
    id: 'UM',
    name: 'U.S. Minor Outlying Islands',
  },
  {
    id: 'US',
    name: 'United States',
    stripe: true,
    paypal: true,
    currency: 'USD',
  },
  {
    id: 'UY',
    name: 'Uruguay',
    paypal: true,
    currency: 'USD',
  },
  {
    id: 'UZ',
    name: 'Uzbekistan',
  },
  {
    id: 'VA',
    name: 'Vatican City',
  },
  {
    id: 'VC',
    name: 'Saint Vincent and the Grenadines',
  },
  {
    id: 'VE',
    name: 'Venezuela',
    paypal: true,
    currency: 'USD',
  },
  {
    id: 'VG',
    name: 'British Virgin Islands',
  },
  {
    id: 'VI',
    name: 'U.S. Virgin Islands',
  },
  {
    id: 'VN',
    name: 'Vietnam',
    paypal: true,
    currency: 'USD',
  },
  {
    id: 'VU',
    name: 'Vanuatu',
  },
  {
    id: 'WF',
    name: 'Wallis and Futuna',
  },
  {
    id: 'WS',
    name: 'Samoa',
  },
  {
    id: 'XK',
    name: 'Kosovo',
  },
  {
    id: 'YE',
    name: 'Yemen',
  },
  {
    id: 'YT',
    name: 'Mayotte',
  },
  {
    id: 'ZA',
    name: 'South Africa',
    paypal: true,
    currency: 'USD',
  },
  {
    id: 'ZM',
    name: 'Zambia',
  },
  {
    id: 'ZW',
    name: 'Zimbabwe',
    unsupported: true,
  },
];

const getCountryByISO = (isoCode: string) =>
  isoCode ? ((isoCodeUpper) => countries.find((c) => c.id === isoCodeUpper))(isoCode.toUpperCase()) : null;

export { getCountryByISO };
export default countries;
