import { useContext } from 'react';
import UserContext from 'web/components/UserContext';

const useFeatureOverrideCheck = (feature?: keyof introwise.OverrideFeatureFlags) => {
  const { loading, userData } = useContext(UserContext);
  return (
    !loading &&
    feature &&
    userData &&
    typeof userData.featuresOverride === 'object' &&
    userData.featuresOverride?.[feature]
  );
};

export default useFeatureOverrideCheck;
