import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import type { LexicalCommand } from 'lexical';
import {
  $createParagraphNode,
  $getSelection,
  $isRangeSelection,
  COMMAND_PRIORITY_EDITOR,
  createCommand,
} from 'lexical';
import React, { useEffect } from 'react';
import { OembedDataVideo } from 'web/utils/oembed';
import { $createSendsparkNode, SendsparkNode } from '../nodes/SendsparkNode';

export type SendsparkCommandPayload = { shareId: string; url: string; oembed: OembedDataVideo; caption: string };

export const INSERT_SENDSPARK_COMMAND: LexicalCommand<SendsparkCommandPayload> = createCommand();

const SendsparkPlugin = () => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    if (!editor.hasNodes([SendsparkNode])) {
      throw new Error('SendsparkPlugin: SendsparkNode not registered on editor');
    }

    return editor.registerCommand<SendsparkCommandPayload>(
      INSERT_SENDSPARK_COMMAND,
      (payload) => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
          const focusNode = selection.focus.getNode();

          if (focusNode !== null) {
            const sendsparkNode = $createSendsparkNode(payload.shareId, payload.url, payload.oembed, payload.caption);
            selection.focus.getNode().getTopLevelElementOrThrow().insertAfter(sendsparkNode);
            const paragraphNode = $createParagraphNode();
            sendsparkNode.insertAfter(paragraphNode);
            paragraphNode.select();
          }
        }

        return true;
      },
      COMMAND_PRIORITY_EDITOR,
    );
  }, [editor]);

  return <></>;
};

export default SendsparkPlugin;
