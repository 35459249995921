import { LinkNode } from '@lexical/link';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $isDecoratorNode } from 'lexical';
import React, { useEffect } from 'react';

const LinkNormalizationPlugin = () => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    return editor.registerNodeTransform(LinkNode, (node) => {
      const child = node.getFirstChild();
      if (node.getChildrenSize() === 1 && $isDecoratorNode(child) && !child.isInline()) {
        node.insertAfter(child);
        node.remove();
      }
    });
  }, [editor]);

  return <></>;
};

export default LinkNormalizationPlugin;
