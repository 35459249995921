import { faCheck, faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { $isLinkNode, TOGGLE_LINK_COMMAND } from '@lexical/link';
import { mergeRegister } from '@lexical/utils';
import { $getSelection, $isRangeSelection, LexicalEditor, SELECTION_CHANGE_COMMAND } from 'lexical';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { AnchorStyled, InlineButton, Input } from 'web/components/elements';
import ThemeContext from 'web/components/ThemeContext';
import joinClassNames from 'web/utils/joinClassNames';
import { linkEditor, linkView } from './floatingLinkEditor.css';
import { getSelectedNode, LowPriority } from './utils';

function positionEditorElement(editor: HTMLElement, rect: DOMRect) {
  if (rect === null) {
    editor.style.opacity = '0';
    editor.style.top = '-1000px';
    editor.style.left = '-1000px';
  } else {
    editor.style.opacity = '1';
    editor.style.top = `${Math.max(0, rect.top + rect.height + window.pageYOffset + 10)}px`;
    editor.style.left = `${Math.max(0, rect.left + window.pageXOffset - editor.offsetWidth / 2 + rect.width / 2)}px`;
  }
}

const sanititzeUrl = (url: string) => url.trim().replace(/^(?!(?:\w+?:)?\/\/)/, 'https://');

const FloatingLinkEditor = ({ editor, isInitialEditMode }: { editor: LexicalEditor; isInitialEditMode?: boolean }) => {
  const editorRef = useRef(null);
  const inputRef = useRef(null);
  const mouseDownRef = useRef(false);
  const [linkUrl, setLinkUrl] = useState('');
  const prevEditMode = useRef(isInitialEditMode);
  const [isEditMode, setEditMode] = useState(isInitialEditMode);
  const [lastSelection, setLastSelection] = useState(null);

  const updateLinkEditor = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      const node = getSelectedNode(selection);
      const parent = node.getParent();
      if ($isLinkNode(parent)) {
        setLinkUrl(parent.getURL());
      } else if ($isLinkNode(node)) {
        setLinkUrl(node.getURL());
      } else {
        setLinkUrl('');
      }
    }
    const editorElem = editorRef.current;
    const nativeSelection = window.getSelection();
    const activeElement = document.activeElement;

    if (editorElem === null) {
      return;
    }

    const rootElement = editor.getRootElement();
    if (
      selection !== null &&
      // !nativeSelection.isCollapsed &&
      rootElement !== null &&
      rootElement.contains(nativeSelection.anchorNode)
    ) {
      const domRange = nativeSelection.getRangeAt(0);
      let rect;
      if (nativeSelection.anchorNode === rootElement) {
        let inner = rootElement;
        while (inner.firstElementChild != null) {
          inner = inner.firstElementChild as HTMLElement;
        }
        rect = inner.getBoundingClientRect();
      } else {
        rect = domRange.getBoundingClientRect();
      }

      if (!mouseDownRef.current) {
        positionEditorElement(editorElem, rect);
      }
      setLastSelection(selection);
    } else if (!activeElement || !activeElement.className?.includes('link-input')) {
      positionEditorElement(editorElem, null);
      setLastSelection(null);
      setEditMode(false);
      setLinkUrl('');
    }

    return true;
  }, [editor]);

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateLinkEditor();
        });
      }),

      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        () => {
          updateLinkEditor();
          return true;
        },
        LowPriority,
      ),
    );
  }, [editor, updateLinkEditor]);

  useEffect(() => {
    editor.getEditorState().read(() => {
      updateLinkEditor();
    });
  }, [editor, updateLinkEditor]);

  useEffect(() => {
    if (isEditMode && !prevEditMode.current && inputRef.current) {
      inputRef.current.focus();
      prevEditMode.current = true;
    }
  }, [isEditMode]);

  const [themeClass, themeStyle] = useContext(ThemeContext);

  const saveUrl = () => {
    if (lastSelection !== null) {
      if (linkUrl !== '') {
        const sanitizedUrl = sanititzeUrl(linkUrl);
        editor.dispatchCommand(TOGGLE_LINK_COMMAND, sanitizedUrl);
      } else {
        editor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
      }
      setEditMode(false);
    }
  };

  return (
    <div ref={editorRef} className={joinClassNames(linkEditor, themeClass)} style={themeStyle}>
      {isEditMode ? (
        <>
          <Input
            className="link-input"
            placeholder="https://example.com"
            ref={inputRef}
            value={linkUrl}
            onChange={(event) => {
              setLinkUrl(event.target.value);
            }}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                event.preventDefault();
                saveUrl();
              } else if (event.key === 'Escape') {
                event.preventDefault();
                setEditMode(false);
              }
            }}
          />
          <InlineButton
            onClick={() => {
              saveUrl();
            }}
          >
            <FontAwesomeIcon icon={faCheck} fixedWidth />
          </InlineButton>
        </>
      ) : (
        <>
          <div className={linkView}>
            {linkUrl ? (
              <AnchorStyled href={linkUrl} target="_blank" rel="noopener noreferrer">
                {linkUrl}
              </AnchorStyled>
            ) : (
              <span>Empty link</span>
            )}
          </div>
          <InlineButton
            onClick={() => {
              setEditMode(true);
            }}
          >
            <FontAwesomeIcon icon={faPencilAlt} fixedWidth />
          </InlineButton>
        </>
      )}
    </div>
  );
};

export default FloatingLinkEditor;
