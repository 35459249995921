import type { PaymentIntent } from '@stripe/stripe-js';
import { SubscriptionPricingPlanId, SubscriptionPricingPriceId } from 'web/utils/pricingPlans';
import { assign, createMachine } from 'xstate';

type ErrorEvent = {
  type: 'ERROR';
  message: string;
};

type PaymentFailedEvent = {
  type: 'PAYMENT_FAILED';
  message?: string;
  stripeSubscriptionId?: string;
};

type RequiresConfirmationEvent = {
  type: 'REQUIRES_CONFIRMATION';
  stripeSubscriptionId?: string;
  stripePaymentIntent: PaymentIntent;
};

type CardChangeEvent = {
  type: 'CARD_CHANGED';
  complete: boolean;
};

type PromoCodeResult = {
  id: string;
  code: string;
  duration: 'once' | 'forever' | 'repeating';
  durationMonths?: number;
  amountOff?: number;
  percentOff?: number;
  billingRestriction?: string;
};

type PromoCodeApplyEvent = {
  type: 'PROMO_CODE_APPLY';
  code: string;
};

type PromoCodeFailedEvent = {
  type: 'PROMO_CODE_FAILED';
  message?: string;
};

type PromoCodeVerifiedEvent = {
  type: 'PROMO_CODE_VERIFIED';
  promoCodeResult: PromoCodeResult;
};

type PromoCodeSuccessEvent = {
  type: 'PROMO_CODE_SUCCESS';
};

type PriceIdChangedEvent = {
  type: 'PRICE_ID_CHANGED';
  priceId: SubscriptionPricingPriceId;
  trialPeriodDays?: number;
};

type PlanIdChangedEvent = {
  type: 'PLAN_ID_CHANGED';
  planId: SubscriptionPricingPlanId;
  priceId: SubscriptionPricingPriceId;
  trialPeriodDays?: number;
};

type SubscriptionEvents =
  | ErrorEvent
  | PaymentFailedEvent
  | RequiresConfirmationEvent
  | CardChangeEvent
  | {
      type: 'READY';
    }
  | {
      type: 'CREATE_OR_UPDATE';
    }
  | {
      type: 'PAYMENT_SUCCESS';
    }
  | {
      type: 'ACTIVATED';
    }
  | {
      type: 'CONFIRM_CARD';
    }
  | {
      type: 'CHANGE_CARD';
    }
  | PromoCodeApplyEvent
  | PromoCodeFailedEvent
  | {
      type: 'PROMO_CODE_CHANGED';
    }
  | PromoCodeVerifiedEvent
  | PromoCodeSuccessEvent
  | {
      type: 'PROMO_CODE_REMOVE';
    }
  | PriceIdChangedEvent
  | PlanIdChangedEvent;

export type SubcriptionContext = {
  planId?: SubscriptionPricingPlanId;
  priceId?: SubscriptionPricingPriceId;
  trialPeriodDays?: number;
  cardComplete?: boolean;
  error?: string;
  stripeSubscriptionId?: string;
  stripePaymentIntent?: PaymentIntent;
  promoCode?: string;
  promoCodeResult?: PromoCodeResult;
  promoCodeError?: string;
};

type SubcriptionCreateState = {
  value:
    | 'initializing'
    | 'init'
    | 'activating'
    | 'readyUpdate'
    | 'updating'
    | 'confirming'
    | 'readyCreate'
    | 'creating'
    | 'active'
    | 'alreadyActive';
  context: SubcriptionContext;
};

type SubcriptionUpdateState = {
  value: 'initializing' | 'init' | 'readyUpdate' | 'updating' | 'confirming' | 'updated' | 'readyConfirm';
  context: SubcriptionContext;
};

const subscriptionCreateMachine = createMachine<SubcriptionContext, SubscriptionEvents, SubcriptionCreateState>({
  id: 'subscription',
  initial: 'initializing',
  states: {
    initializing: {
      on: {
        READY: 'init',
        // Here and below we respond to all activation events
        // as they may come out of the normal flow. E.g. when Firestore
        // update comes before a function result, or when subscription
        // is created elsewhere
        ACTIVATED: 'alreadyActive',
      },
    },
    init: {
      always: [{ target: 'readyCreate', cond: (ctx) => !!ctx.planId }],
      on: {
        PLAN_ID_CHANGED: {
          target: 'readyCreate',
          actions: assign((ctx, event: PlanIdChangedEvent) => ({
            ...ctx,
            planId: event.planId,
            priceId: event.priceId,
            trialPeriodDays: event.trialPeriodDays,
            promoCodeError: undefined,
          })),
        },
      },
    },
    readyCreate: {
      initial: 'promoCodeInit',
      on: {
        CREATE_OR_UPDATE: {
          target: 'creating',
          cond: (ctx) => ctx.cardComplete === true,
          actions: assign((ctx) => ({
            ...ctx,
          })),
        },
        ACTIVATED: 'alreadyActive',
        CARD_CHANGED: {
          actions: assign((ctx, event: CardChangeEvent) => ({
            ...ctx,
            error: undefined,
            cardComplete: event.complete,
          })),
        },
      },
      states: {
        promoCodeInit: {
          always: [
            {
              target: 'promoCodeApplied',
              cond: (ctx) => !!ctx.promoCodeResult,
            },
            {
              target: 'promoCodeReady',
            },
          ],
        },
        promoCodeReady: {
          on: {
            PROMO_CODE_APPLY: {
              target: 'promoCodeVerifying',
              actions: assign((ctx, event: PromoCodeApplyEvent) => ({
                ...ctx,
                promoCode: event.code,
              })),
            },
            PROMO_CODE_CHANGED: {
              actions: assign((ctx) => ({
                ...ctx,
                promoCodeError: undefined,
              })),
            },
            PRICE_ID_CHANGED: {
              actions: assign((ctx, event: PriceIdChangedEvent) => ({
                ...ctx,
                priceId: event.priceId,
                trialPeriodDays: event.trialPeriodDays,
                promoCodeError: undefined,
              })),
            },
          },
        },
        promoCodeVerifying: {
          entry: 'promoCodeVerify',
          on: {
            PROMO_CODE_VERIFIED: {
              target: 'promoCodeApplying',
              actions: assign((ctx, event: PromoCodeVerifiedEvent) => ({
                ...ctx,
                promoCodeResult: event.promoCodeResult,
              })),
            },
            PROMO_CODE_FAILED: {
              target: 'promoCodeReady',
              actions: assign((ctx, event: PromoCodeFailedEvent) => ({
                ...ctx,
                promoCodeError: event.message,
              })),
            },
          },
        },
        promoCodeApplying: {
          entry: 'promoCodeApply',
          on: {
            PROMO_CODE_SUCCESS: {
              target: 'promoCodeApplied',
            },
            PROMO_CODE_FAILED: {
              target: 'promoCodeReady',
              actions: assign((ctx, event: PromoCodeFailedEvent) => ({
                ...ctx,
                promoCodeResult: undefined,
                promoCodeError: event.message,
              })),
            },
          },
        },
        promoCodeApplied: {
          on: {
            PROMO_CODE_REMOVE: {
              target: 'promoCodeReady',
              actions: assign((ctx) => ({
                ...ctx,
                promoCode: undefined,
                promoCodeError: undefined,
                promoCodeResult: undefined,
              })),
            },
            // Price Id change may affect promo code applicability
            PRICE_ID_CHANGED: {
              target: 'promoCodeApplying',
              actions: assign((ctx, event: PriceIdChangedEvent) => ({
                ...ctx,
                priceId: event.priceId,
              })),
            },
          },
        },
      },
    },
    creating: {
      entry: [
        'create',
        assign((ctx) => ({
          ...ctx,
          error: undefined,
        })),
      ],
      on: {
        PAYMENT_SUCCESS: 'activating',
        ACTIVATED: 'active',
        PAYMENT_FAILED: {
          target: 'readyUpdate',
          actions: assign((ctx, event: PaymentFailedEvent) => ({
            ...ctx,
            stripeSubscriptionId: event.stripeSubscriptionId,
            error: event.message,
          })),
        },
        REQUIRES_CONFIRMATION: {
          target: 'confirming',
          actions: assign((ctx, event: RequiresConfirmationEvent) => ({
            ...ctx,
            stripeSubscriptionId: event.stripeSubscriptionId,
            stripePaymentIntent: event.stripePaymentIntent,
          })),
        },
        PROMO_CODE_FAILED: {
          target: 'readyCreate.promoCodeReady',
          actions: assign((ctx, event: PromoCodeFailedEvent) => ({
            ...ctx,
            error: "Couldn't create a subscription. Please check your promotion code.",
            promoCodeError: event.message,
            promoCodeResult: undefined,
          })),
        },
        ERROR: {
          target: 'readyCreate',
          actions: [
            assign((ctx, event: ErrorEvent) => ({
              ...ctx,
              error: event.message,
            })),
            'reportError',
          ],
        },
      },
    },
    activating: {
      on: {
        ACTIVATED: 'active',
      },
    },
    active: {
      entry: ['track'],
    },
    alreadyActive: {},
    confirming: {
      entry: ['confirmCard'],
      on: {
        PAYMENT_SUCCESS: 'activating',
        ACTIVATED: 'active',
        PAYMENT_FAILED: {
          target: 'readyUpdate',
          actions: assign((ctx: SubcriptionContext, event: PaymentFailedEvent) => ({
            ...ctx,
            error: event.message,
          })),
        },
        ERROR: {
          target: 'readyUpdate',
          actions: [
            assign((ctx: SubcriptionContext, event: ErrorEvent) => ({
              ...ctx,
              error: event.message,
            })),
            'reportError',
          ],
        },
      },
    },
    readyUpdate: {
      on: {
        CREATE_OR_UPDATE: {
          target: 'updating',
          cond: (ctx: SubcriptionContext) => ctx.cardComplete === true,
        },
        ACTIVATED: 'alreadyActive',
        CARD_CHANGED: {
          actions: assign((ctx: SubcriptionContext, event: CardChangeEvent) => ({
            ...ctx,
            error: undefined,
            cardComplete: event.complete,
          })),
        },
        // Price Id change requires a brand new subscription
        PRICE_ID_CHANGED: {
          target: 'readyCreate',
          actions: assign((ctx, event: PriceIdChangedEvent) => ({
            ...ctx,
            stripeSubscriptionId: undefined,
            priceId: event.priceId,
          })),
        },
      },
    },
    updating: {
      entry: [
        'update',
        assign((ctx) => ({
          ...ctx,
          error: undefined,
        })),
      ],
      on: {
        PAYMENT_SUCCESS: 'activating',
        ACTIVATED: 'active',
        PAYMENT_FAILED: {
          target: 'readyUpdate',
          actions: assign((ctx: SubcriptionContext, event: PaymentFailedEvent) => ({
            ...ctx,
            error: event.message,
          })),
        },
        REQUIRES_CONFIRMATION: {
          target: 'confirming',
          actions: assign((ctx: SubcriptionContext, event: RequiresConfirmationEvent) => ({
            ...ctx,
            stripeSubscriptionId: event.stripeSubscriptionId,
            stripePaymentIntent: event.stripePaymentIntent,
          })),
        },
        ERROR: {
          target: 'readyUpdate',
          actions: [
            assign((ctx: SubcriptionContext, event: ErrorEvent) => ({
              ...ctx,
              error: event.message,
            })),
            'reportError',
          ],
        },
      },
    },
  },
});

const subscriptionUpdateMachine = createMachine<SubcriptionContext, SubscriptionEvents, SubcriptionUpdateState>({
  id: 'subscription',
  initial: 'initializing',
  states: {
    initializing: {
      on: {
        READY: 'init',
      },
    },
    init: {
      always: [{ target: 'retrieving', cond: (ctx) => !!ctx.stripeSubscriptionId }],
    },
    retrieving: {
      entry: ['retrieve'],
      on: {
        PAYMENT_SUCCESS: 'readyUpdate',
        PAYMENT_FAILED: {
          target: 'readyUpdate',
        },
        REQUIRES_CONFIRMATION: {
          target: 'readyConfirm',
          actions: assign((ctx: SubcriptionContext, event: RequiresConfirmationEvent) => ({
            ...ctx,
            stripeSubscriptionId: event.stripeSubscriptionId,
            stripePaymentIntent: event.stripePaymentIntent,
          })),
        },
        ERROR: {
          target: 'readyUpdate',
          actions: [
            assign((ctx: SubcriptionContext, event: ErrorEvent) => ({
              ...ctx,
              error: event.message,
            })),
            'reportError',
          ],
        },
      },
    },
    readyConfirm: {
      on: {
        CONFIRM_CARD: 'confirming',
        CHANGE_CARD: 'readyUpdate',
      },
    },
    confirming: {
      entry: ['confirmCard'],
      on: {
        PAYMENT_SUCCESS: 'updated',
        PAYMENT_FAILED: {
          target: 'readyUpdate',
          actions: assign((ctx: SubcriptionContext, event: PaymentFailedEvent) => ({
            ...ctx,
            error: event.message,
          })),
        },
        ERROR: {
          target: 'readyUpdate',
          actions: [
            assign((ctx: SubcriptionContext, event: ErrorEvent) => ({
              ...ctx,
              error: event.message,
            })),
            'reportError',
          ],
        },
      },
    },
    readyUpdate: {
      on: {
        CREATE_OR_UPDATE: {
          target: 'updating',
          cond: (ctx: SubcriptionContext) => ctx.cardComplete === true,
        },
        CARD_CHANGED: {
          actions: assign((ctx: SubcriptionContext, event: CardChangeEvent) => ({
            ...ctx,
            error: undefined,
            cardComplete: event.complete,
          })),
        },
      },
    },
    updating: {
      entry: [
        'update',
        assign((ctx) => ({
          ...ctx,
          error: undefined,
        })),
      ],
      on: {
        PAYMENT_SUCCESS: 'updated',
        PAYMENT_FAILED: {
          target: 'readyUpdate',
          actions: assign((ctx: SubcriptionContext, event: PaymentFailedEvent) => ({
            ...ctx,
            error: event.message,
          })),
        },
        REQUIRES_CONFIRMATION: {
          target: 'confirming',
          actions: assign((ctx: SubcriptionContext, event: RequiresConfirmationEvent) => ({
            ...ctx,
            stripeSubscriptionId: event.stripeSubscriptionId,
            stripePaymentIntent: event.stripePaymentIntent,
          })),
        },
        ERROR: {
          target: 'readyUpdate',
          actions: [
            assign((ctx: SubcriptionContext, event: ErrorEvent) => ({
              ...ctx,
              error: event.message,
            })),
            'reportError',
          ],
        },
      },
    },
    updated: {},
  },
});

export { subscriptionUpdateMachine, subscriptionCreateMachine };
