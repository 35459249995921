import { CardElement } from '@stripe/react-stripe-js';
import type { PaymentIntent, Stripe, StripeElements } from '@stripe/stripe-js';
import { Functions, FunctionsError, httpsCallable } from 'firebase/functions';

type SubscriptionFunctionResult = {
  subscription: {
    id: string;
    latest_invoice: {
      payment_intent: PaymentIntent;
    };
  };
};

const createPaymentMethod = async (stripe: Stripe, elements: StripeElements) => {
  const cardElement = elements.getElement(CardElement);
  const paymentMethodResult = await stripe.createPaymentMethod({
    type: 'card',
    card: cardElement,
  });
  if (paymentMethodResult.error) {
    // This includes client-side card validations
    throw new Error(paymentMethodResult.error.message);
  }
  return paymentMethodResult.paymentMethod.id;
};

const createSubscription = async ({
  planId,
  priceId,
  stripePaymentMethodId,
  stripePromotionCodeId,
  trialPeriodDays,
  functions,
}: {
  planId: string;
  priceId: string;
  stripePaymentMethodId: string;
  stripePromotionCodeId?: string;
  trialPeriodDays?: number;
  functions: Functions;
}) => {
  const subscriptionResult = await httpsCallable(
    functions,
    'stripeCreateSubscription',
  )({
    stripePaymentMethodId,
    planId,
    priceId,
    ...(stripePromotionCodeId && { stripePromotionCodeId }),
    ...(trialPeriodDays && { trialPeriodDays }),
  });

  if (!subscriptionResult.data) {
    throw new Error(`Failed to create a subscription: function response is empty`);
  }

  const data = subscriptionResult.data as SubscriptionFunctionResult;
  if (!data?.subscription) {
    throw new Error(`Failed to create a subscription: function response doesn't have a subscription`);
  }
  const { subscription } = data;
  const paymentIntent = subscription.latest_invoice.payment_intent;
  return {
    subscription,
    paymentIntent,
  };
};

const updateSubscriptionPayment = async (
  stripeSubscriptionId: string,
  stripePaymentMethodId: string,
  functions: Functions,
) => {
  const subscriptionResult = await httpsCallable(
    functions,
    'stripeUpdateSubscriptionPayment',
  )({
    stripePaymentMethodId,
    stripeSubscriptionId,
  });

  const data = subscriptionResult.data as SubscriptionFunctionResult | null;
  if (!data?.subscription) {
    throw new Error(`Failed to update a subscription: function response is empty`);
  }
  const { subscription } = data;
  const paymentIntent = subscription.latest_invoice.payment_intent;
  return { subscription, paymentIntent };
};

const retrieveSubscription = async (stripeSubscriptionId: string, functions: Functions) => {
  let subscriptionResult;
  try {
    subscriptionResult = await httpsCallable<unknown, SubscriptionFunctionResult>(
      functions,
      'stripeRetrieveSubscription',
    )({
      stripeSubscriptionId,
    });
  } catch (err) {
    if ((err as FunctionsError).code === 'not-found') {
      return {
        subscription: undefined,
        paymentIntent: undefined,
      };
    } else {
      throw err;
    }
  }

  const data = subscriptionResult.data;
  if (!data?.subscription) {
    throw new Error(`Failed to retrieve a subscription: function response is empty`);
  }
  const { subscription } = data;
  const paymentIntent = subscription.latest_invoice.payment_intent;
  return { subscription, paymentIntent };
};

const confirmCardPayment = async (paymentIntent: PaymentIntent, stripe: Stripe) =>
  stripe.confirmCardPayment(paymentIntent.client_secret, {
    payment_method: paymentIntent.payment_method,
  });

type PromotionFunctionResult = {
  promotionCodeError?: string;
  promotionCode?: {
    id: string;
    code: string;
    duration: 'once' | 'forever' | 'repeating';
    durationMonths?: number;
    amountOff?: number;
    percentOff?: number;
    billingRestriction?: string;
  };
};

const verifyPromotionCode = async (code: string, functions: Functions) => {
  const res = await httpsCallable<unknown, PromotionFunctionResult>(
    functions,
    'stripeVerifyPromotionCode',
  )({
    code,
  });

  const { promotionCode, promotionCodeError } = res.data;

  return { promotionCode, promotionCodeError };
};

export {
  confirmCardPayment,
  updateSubscriptionPayment,
  retrieveSubscription,
  createSubscription,
  createPaymentMethod,
  verifyPromotionCode,
};
