import React from 'react';
import { useContext, useMemo } from 'react';
import StyledReactSelect from './StyledReactSelect';
import UserContext from '../UserContext';
import { Control, Controller, FieldPath, FieldPathValue } from 'react-hook-form';

const PaymentAccountField = <TFieldValues,>({
  control,
  name,
  initialValue,
  disabled,
  supportPaymentPlans,
}: {
  control: Control<TFieldValues>;
  name: FieldPath<TFieldValues>;
  initialValue?: FieldPathValue<TFieldValues, FieldPath<TFieldValues>> | null;
  disabled?: boolean;
  supportPaymentPlans?: boolean;
}) => {
  const { userData } = useContext(UserContext);

  const defaultPaymentAccount = (userData.settings?.payments?.gateway || 'none') as FieldPathValue<
    TFieldValues,
    FieldPath<TFieldValues>
  >;

  const hasPayPal = !!userData.paypalMerchantId;
  const hasStripe =
    !!userData.stripeAccountId ||
    (userData.settings?.payments?.gateway === 'stripe' && userData.payoutGateway === 'manual');

  const options = useMemo(
    () => [
      {
        label: hasStripe ? 'Stripe' : 'Stripe (not connected)',
        value: 'stripe',
        isDisabled: !hasStripe,
      },
      {
        label: hasPayPal ? 'PayPal' : 'PayPal (not connected)',
        value: 'paypal',
        isDisabled: !hasPayPal,
      },
      {
        label: 'No payments',
        value: 'none',
      },
    ],
    [hasPayPal, hasStripe],
  );

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={initialValue || defaultPaymentAccount}
      rules={{
        validate: (value) => {
          if (supportPaymentPlans && value !== 'stripe') {
            return 'Payment plans are only supported with Stripe payment processing';
          }
        },
      }}
      render={({ field: { onChange, onBlur, value }, formState: { errors } }) => (
        <StyledReactSelect
          options={options}
          onChange={(option: { value: string }) => onChange(option.value)}
          onBlur={onBlur}
          value={options.find((o) => o.value === value)}
          isDisabled={disabled}
          hasError={!!(errors as { [key: string]: unknown })?.[name]}
        />
      )}
    />
  );
};

export default PaymentAccountField;
