import { doc, serverTimestamp, writeBatch } from 'firebase/firestore';
import { customAlphabet } from 'nanoid/non-secure';
import React, { useContext, useState } from 'react';
import { useLocation } from 'react-router-dom';
import BookingPageContext from 'web/components/BookingPageContext';
import { FormError } from 'web/components/elements';
import useFirestore from 'web/components/FirebaseContext/useFirestore';
import Spinner from 'web/components/Spinner';
import UserContext from 'web/components/UserContext';
import useErrorHandler from 'web/hooks/useErrorHandler';
import useErrorStateHandler from 'web/hooks/useErrorStateHandler';
import useFeatureOverrideCheck from 'web/hooks/useFeatureOverrideCheck';
import useTracking from 'web/components/TrackingContext/useTracking';
import { convertToDecimal } from 'web/utils/currency';
import ServiceForm, { FormValues } from './ServiceForm';
import useFeatureIncludedCheck from 'web/hooks/useFeatureIncludedCheck';

const serviceIdNanoId = customAlphabet('1234567890abcdef', 10);

const ServiceCreate = ({
  onSuccess,
  withWorkflows,
  withLocation,
  withAvailability,
}: {
  onSuccess: () => void;
  withWorkflows?: boolean;
  withLocation?: boolean;
  withAvailability?: boolean;
}) => {
  const { state } = useLocation();
  const { userData } = useContext(UserContext);
  const firestore = useFirestore();
  const tracking = useTracking();
  const [page, pageLoading, pageError] = useContext(BookingPageContext);
  useErrorHandler(pageError);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useErrorStateHandler();
  const increasedPriceLimit = useFeatureOverrideCheck('increasedPriceLimit');
  const schedulingRulesEnabled = useFeatureIncludedCheck('multipleSchedulingRules');
  const phoneNumberCollectionEnabled = useFeatureOverrideCheck('phoneNumberCollection');

  const availabilities = userData.availabilities
    ? Object.values(userData.availabilities).sort((a, b) => a.order - b.order)
    : undefined;

  const { bookingPageId } = userData;

  const serviceToClone = (state as { service?: introwise.Service } | undefined)?.service;

  const create = async (values: FormValues) => {
    const id = serviceIdNanoId();
    const service: introwise.FirestoreWriteData<introwise.Service> = {
      createdAt: serverTimestamp(),
      order: Date.now(), // current time will always be bigger than any other stored value
      ...values,
    };

    const update: introwise.FirestoreUpdateData<introwise.Page> = {
      [`services.${id}`]: {
        id,
        ...service,
      },
    };

    const pageRef = doc(firestore, 'pages', bookingPageId);
    const serviceRef = doc(pageRef, 'services', id);
    const batch = writeBatch(firestore);
    batch.update(pageRef, update);
    batch.set(serviceRef, service);

    setSubmitting(true);
    try {
      await batch.commit();
      tracking.trackEvent('Service Created', {
        price: convertToDecimal(values.price, page.currency),
        currency: page.currency,
        clone: !!serviceToClone,
      });
      onSuccess();
    } catch (err) {
      setError(err);
      setSubmitting(false);
    }
  };

  return (
    <>
      {!page && pageLoading && <Spinner />}
      {!page && !pageLoading && <p>Something went wrong. Please try again later.</p>}
      {page && (
        <>
          <ServiceForm
            onSubmit={create}
            submitting={submitting}
            currency={page.currency}
            increasedPriceLimit={increasedPriceLimit}
            availabilities={availabilities}
            initialValues={serviceToClone ? { ...serviceToClone, title: `${serviceToClone.title} (clone)` } : undefined}
            defaultSchedulingRules={userData.settings?.schedulingRules}
            schedulingRulesEnabled={schedulingRulesEnabled}
            withWorkflows={withWorkflows}
            withLocation={withLocation}
            withAvailability={withAvailability}
            phoneNumberCollectionEnabled={phoneNumberCollectionEnabled}
          />
          {error && <FormError>Failed to create a service. Please try again later.</FormError>}
        </>
      )}
    </>
  );
};

export default ServiceCreate;
