import { useContext } from 'react';
import UserContext from 'web/components/UserContext';
import useFeatureOverrideCheck from 'web/hooks/useFeatureOverrideCheck';
import { isFeatureIncluded } from 'web/utils/pricingPlans';

const useFeatureIncludedCheck = (feature: keyof introwise.PaidFeaturesFlags) => {
  const { userData } = useContext(UserContext);
  const featureOverriden = useFeatureOverrideCheck(feature);

  return featureOverriden || (userData && isFeatureIncluded(userData.pricingPlanId, feature));
};
export default useFeatureIncludedCheck;
