import React from 'react';
import { Button, FormDescription } from 'web/components/elements';

const PromoCodeInfo = ({
  promoCodeResult,
  subscriptionPrice,
  onRemove,
  disabled,
}: {
  promoCodeResult: {
    code: string;
    duration: 'once' | 'forever' | 'repeating';
    durationMonths?: number;
    amountOff?: number;
    percentOff?: number;
  };
  subscriptionPrice: number;
  onRemove: () => void;
  disabled: boolean;
}) => {
  const discountAmount = Math.min(
    subscriptionPrice,
    promoCodeResult.amountOff || (subscriptionPrice * promoCodeResult.percentOff) / 100,
  );

  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{ flex: '1', padding: '0 20px' }}>
          <b>{promoCodeResult.code}</b>
        </div>
        <div style={{ flex: 'none' }}>-${discountAmount / 100}</div>
        <Button secondary sm style={{ flex: 'none', marginLeft: 12 }} onClick={onRemove} disabled={disabled}>
          Remove
        </Button>
      </div>
      <div>
        <FormDescription style={{ padding: '0 20px' }}>
          ({promoCodeResult.duration === 'once' && 'One-time '}
          {promoCodeResult.percentOff ? `${promoCodeResult.percentOff}%` : `$${promoCodeResult.amountOff / 100}`}
          {' discount'}
          {promoCodeResult.durationMonths && ` for ${promoCodeResult.durationMonths} months`})
        </FormDescription>
      </div>
    </>
  );
};

export default PromoCodeInfo;
