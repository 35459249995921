import { collection, doc, writeBatch } from 'firebase/firestore';
import React, { useContext, useState } from 'react';
import BookingPageContext from 'web/components/BookingPageContext';
import { Button, FormError } from 'web/components/elements';
import useFirestore from 'web/components/FirebaseContext/useFirestore';
import { ReorderingList, useOrderableItems } from 'web/components/ReorderingList';
import Spinner from 'web/components/Spinner';
import useErrorHandler from 'web/hooks/useErrorHandler';
import useErrorStateHandler from 'web/hooks/useErrorStateHandler';
import { formatCurrencyAmount } from 'web/utils/currency';
import { ServicesButtonsGroup, ServicesLoading } from './ServicesCommon';

const ServicesReorderImpl = ({ page, onSuccess }: { page: introwise.Page; onSuccess: () => void }) => {
  const firestore = useFirestore();
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useErrorStateHandler();

  const [servicesSorted, setServicesSorted, initServicesOrder] = useOrderableItems(Object.values(page.services));

  const save = async () => {
    const bookingPageId = page?.id;
    const pageRef = doc(firestore, 'pages', bookingPageId);
    const servicesRef = collection(pageRef, 'services');
    const batch = writeBatch(firestore);

    const update: introwise.FirestoreUpdateData<introwise.Page> = {};
    for (const service of servicesSorted) {
      if (initServicesOrder[service.id] !== service.order) {
        update[`services.${service.id}.order`] = service.order;
        batch.update(doc(servicesRef, service.id), { order: service.order });
      }
    }

    batch.update(pageRef, update);

    setSubmitting(true);
    try {
      await batch.commit();
      onSuccess();
    } catch (err) {
      setError(err);
      setSubmitting(false);
    }
  };

  if (!servicesSorted.length) {
    return <p>There are no services to reorder on your booking page.</p>;
  }

  return (
    <>
      <p style={{ marginTop: 0 }}>
        Use the arrows or drag services to change the order that they appear on your booking page.
      </p>
      <ReorderingList
        items={servicesSorted}
        onItemsChange={setServicesSorted}
        itemRender={(service) => (
          <>
            {service.title} &mdash; {service.price ? formatCurrencyAmount(service.price, page.currency) : 'Free'}
          </>
        )}
      ></ReorderingList>
      <ServicesButtonsGroup>
        <Button primary onClick={save} disabled={submitting}>
          {submitting && <Spinner />}
          <span>Save</span>
        </Button>
      </ServicesButtonsGroup>
      {error && <FormError>{`${error}`}</FormError>}
    </>
  );
};

const ServicesReorder = ({ onSuccess }: { onSuccess: () => void }) => {
  const [page, pageLoading, pageError] = useContext(BookingPageContext);
  useErrorHandler(pageError);

  return (
    <>
      {!page && pageLoading && <ServicesLoading />}
      {!page && !pageLoading && <p>Something went wrong. Please try again later.</p>}
      {page && <ServicesReorderImpl page={page} onSuccess={onSuccess} />}
    </>
  );
};

export default ServicesReorder;
