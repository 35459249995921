import { connectStorageEmulator, getStorage } from 'firebase/storage';
import { useContext } from 'react';
import { shouldUseEmulators } from './common';
import FirebaseContext, { firebase } from './FirebaseContext';

if (shouldUseEmulators) {
  connectStorageEmulator(getStorage(firebase), 'localhost', 9199);
}

const useStorage = () => {
  const firebase = useContext(FirebaseContext);
  return getStorage(firebase);
};

export default useStorage;
