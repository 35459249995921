import React from 'react';
import { RegisterOptions, useFormContext } from 'react-hook-form';
import { FormDescription, FormError, FormGroup, Input, InputPrefix, Label, LabelText } from 'web/components/elements';
import useSlugUniquenessValidator, { SlugValidator } from 'web/hooks/useSlugUniquenessValidator';

const minSlugLength = 3;
const maxSlugLength = 30;

const slugValidationRulesConst: RegisterOptions = {
  required: 'Requried',
  minLength: {
    value: minSlugLength,
    message: `Too short, please use at least ${minSlugLength} character`,
  },
  maxLength: {
    value: maxSlugLength,
    message: `Too long, please limit the link to ${maxSlugLength} characters`,
  },
  pattern: {
    value: /^[a-z0-9]+(?:-[a-z0-9]+)*$/,
    message: 'Please use lowercase letters, numbers and dash symbols, like this-format-of-url',
  },
};

const forbiddenSlugs = [
  'create',
  'blog',
  'index',
  'login',
  'logout',
  'get-started',
  'email-action',
  'reset-password',
  'terms-of-service',
  'privacy-policy',
  'pricing',
  'faq',
  'contact',
  'careers',
  'free-trial',
  'stories',
  'blog',
  'directory',
  'community',
  'help',
  'welcome',
  'onboarding',
  'admin',
  'payment-action',
  'home',
  'settings',
  'dashboard',
  'academy',
  's',
  'session',
  'uses',
  'p',
];

const makeSlugValidationRules = (slugValidator: SlugValidator): RegisterOptions => ({
  ...slugValidationRulesConst,
  validate: {
    unique: async (slug: string) => (!(await slugValidator(slug)) ? 'This short link is already taken' : true),
    allowed: (slug: string) => (forbiddenSlugs.includes(slug) ? 'This short link is not allowed' : true),
  },
});

type AddressFormValues = Pick<introwise.Page, 'slug'>;

const BookingPageFormPartialSlug = ({ initialSlug }: { initialSlug: string }) => {
  const slugValidator = useSlugUniquenessValidator(initialSlug);

  const {
    register,
    formState: { errors },
  } = useFormContext<AddressFormValues>();

  return (
    <>
      <FormGroup>
        <Label>
          <InputPrefix>introwise.com/</InputPrefix>
          <Input
            {...register('slug', makeSlugValidationRules(slugValidator))}
            placeholder="some-short-name"
            style={{ paddingLeft: '132px' }}
            hasError={!!errors.slug}
          />
          <LabelText>Short link</LabelText>
        </Label>
        <FormDescription>Customize your Introwise URL</FormDescription>
        {errors.slug && <FormError>{errors.slug.message}</FormError>}
      </FormGroup>
    </>
  );
};

export default BookingPageFormPartialSlug;
