import React from 'react';
import Select, { Props } from 'react-select';
import themeVars from 'web/styles/themeVars.css';
import joinClassNames from 'web/utils/joinClassNames';
import select from '../elements/select.css';
import selectArrow from '../elements/selectArrow.css';

const StyledReactSelect = ({
  className,
  components,
  styles,
  withLabel,
  hasError,
  ...props
}: Props & { withLabel?: boolean; hasError?: boolean }) => (
  <Select
    className={className}
    components={{
      IndicatorSeparator: null,
      DropdownIndicator: null,
      Control: ({ className, children, innerRef, innerProps, isFocused }) => (
        <div
          ref={innerRef}
          className={joinClassNames(className, selectArrow, select)}
          style={{
            ...(hasError && {
              boxShadow: `0 0 0 2px ${themeVars.form.color.inputBorderError}`,
            }),
            ...(isFocused && {
              boxShadow: `0 0 0 1px ${themeVars.form.color.borderFocus}`,
            }),
          }}
          {...innerProps}
        >
          {children}
        </div>
      ),
      ...components,
    }}
    styles={{
      valueContainer: (provided) => ({ ...provided, padding: 0, height: 37 }),
      placeholder: (provided) => ({ ...provided, marginLeft: 0 }),
      input: (provided) => ({ ...provided, marginLeft: -2 }),
      singleValue: (provided) => ({ ...provided, margin: 0 }),
      option: (provided, state) => ({
        ...provided,
        ...(state.isSelected && { background: themeVars.button.background.primary }),
        ...(state.isFocused && { background: themeVars.color.accentBackground }),
        ...(state.isSelected && state.isFocused && { color: themeVars.color.accentOutline }),
        ['&:hover']: { background: themeVars.color.accentBackground },
      }),
      menu: (provided) => ({ ...provided, zIndex: 10 }),
      ...styles,
    }}
    {...props}
  />
);

export default StyledReactSelect;
