import { BlockWithAlignableContents } from '@lexical/react/LexicalBlockWithAlignableContents';
import { DecoratorBlockNode, SerializedDecoratorBlockNode } from '@lexical/react/LexicalDecoratorBlockNode';
import { assignInlineVars } from '@vanilla-extract/dynamic';
import { EditorConfig, ElementFormatType, LexicalEditor, LexicalNode, NodeKey } from 'lexical';
import React from 'react';
import { InlineButton } from 'web/components/elements';
import { aspectRatio, FullWidthFigure, FullWidthIframeWrapper } from 'web/components/elements/FullWidthFigure';
import themeClasses from 'web/styles/themeClasses.css';
import { OembedDataVideo } from 'web/utils/oembed';
import CaptionAltEditor from './CaptionAltEditor';

function SendsparkComponent({
  className,
  nodeKey,
  shareId,
  format,
  oembed,
  caption,
  onRemove,
  onCaptionChange,
}: {
  className: {
    base: string;
    focus: string;
  };
  nodeKey: NodeKey;
  format: ElementFormatType | null;
  shareId: string;
  oembed: OembedDataVideo;
  caption: string;
  onRemove: () => void;
  onCaptionChange: (caption: string) => void;
}) {
  return (
    <BlockWithAlignableContents format={format} nodeKey={nodeKey} className={className}>
      <FullWidthFigure>
        {oembed.type === 'video' && (
          <FullWidthIframeWrapper
            dangerouslySetInnerHTML={{
              __html: oembed.html,
            }}
            style={assignInlineVars({
              [aspectRatio]: (oembed.width / oembed.height).toString(),
            })}
          />
        )}
        {!oembed && (
          <>
            <FullWidthIframeWrapper
              style={assignInlineVars({
                [aspectRatio]: (560 / 315).toString(),
              })}
            >
              <iframe
                src={`https://sendspark.com/embed/${shareId}`}
                width="560"
                height="315"
                frameBorder="0"
                allowFullScreen={true}
                title="Sendspark Share"
              />
            </FullWidthIframeWrapper>
          </>
        )}
        <div className={themeClasses({ display: 'flex', justifyContent: 'flex-end', gap: 4, paddingTop: 1 })}>
          {caption && (
            <>
              <figcaption
                className={themeClasses({
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  flex: 'auto',
                })}
              >
                {caption}
              </figcaption>
            </>
          )}
          <CaptionAltEditor value={caption} onChange={onCaptionChange} kind="caption" />
          <InlineButton onClick={onRemove}>Remove</InlineButton>
        </div>
      </FullWidthFigure>
    </BlockWithAlignableContents>
  );
}

type SerializedSendsparkNode = SerializedDecoratorBlockNode & {
  type: 'cloudapp';
  shareId: string;
  url: string;
  oembed: OembedDataVideo;
  caption: string;
};

export class SendsparkNode extends DecoratorBlockNode {
  __shareId: string;
  __url: string;
  __oembed: OembedDataVideo;
  __caption: string;

  getShareId(): string {
    const self = this.getLatest();
    return self.__shareId;
  }

  setShareId(id: string): void {
    const self = this.getWritable();
    self.__shareId = id;
  }

  getUrl(): string {
    const self = this.getLatest();
    return self.__url;
  }

  setUrl(url: string): void {
    const self = this.getWritable();
    self.__url = url;
  }

  getOembed(): OembedDataVideo {
    const self = this.getLatest();
    return self.__oembed;
  }

  setOembed(oembed: OembedDataVideo): void {
    const self = this.getWritable();
    self.__oembed = oembed;
  }

  getCaption(): string {
    const self = this.getLatest();
    return self.__caption;
  }

  setCaption(caption: string): void {
    const self = this.getWritable();
    self.__caption = caption;
  }

  static getType(): 'cloudapp' {
    return 'cloudapp';
  }

  static clone(node: SendsparkNode): SendsparkNode {
    return new SendsparkNode(node.__shareId, node.__url, node.__oembed, node.__caption, node.__format, node.__key);
  }

  constructor(
    shareId: string,
    url: string,
    oembed: OembedDataVideo,
    caption: string,
    format?: ElementFormatType | null,
    key?: NodeKey,
  ) {
    super(format, key);
    this.__shareId = shareId;
    this.__url = url;
    this.__oembed = oembed;
    this.__caption = caption;
  }

  updateDOM(): false {
    return false;
  }

  decorate(editor: LexicalEditor, config: EditorConfig): JSX.Element {
    const className = {
      base: config.theme.embedBlock?.base || '',
      focus: config.theme.embedBlock?.focus || '',
    };
    return (
      <SendsparkComponent
        format={this.__format}
        nodeKey={this.getKey()}
        shareId={this.__shareId}
        oembed={this.__oembed}
        className={className}
        caption={this.__caption}
        onRemove={() => editor.update(() => this.remove())}
        onCaptionChange={(caption) => editor.update(() => this.setCaption(caption))}
      />
    );
  }

  isTopLevel(): true {
    return true;
  }

  isIsolated(): true {
    return true;
  }

  isInline(): false {
    return false;
  }

  exportJSON(): SerializedSendsparkNode {
    return {
      ...super.exportJSON(),
      type: SendsparkNode.getType(),
      shareId: this.__shareId,
      url: this.__url,
      oembed: this.__oembed,
      caption: this.__caption,
    };
  }

  static importJSON(json: SerializedSendsparkNode): SendsparkNode {
    const node = $createSendsparkNode(json.shareId, json.url, json.oembed, json.caption);
    node.setFormat(json.format);
    return node;
  }
}

export function $createSendsparkNode(
  shareId: string,
  url: string,
  oembed: OembedDataVideo,
  caption: string,
): SendsparkNode {
  return new SendsparkNode(shareId, url, oembed, caption);
}

export function $isSendsparkNode(node: SendsparkNode | LexicalNode | null | undefined): node is SendsparkNode {
  return node instanceof SendsparkNode;
}
