import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Button,
  FormDescription,
  FormError,
  FormGroup,
  InlineButton,
  Input,
  Label,
  LabelText,
} from 'web/components/elements';
import GenericReactModal from 'web/components/GenericReactModal';
import themeClasses from 'web/styles/themeClasses.css';

const captionMaxLength = 160;

const CaptionForm = ({
  value,
  onSubmit,
  kind,
}: {
  value: string;
  onSubmit: (caption: string) => void;
  kind: 'caption' | 'alt';
}) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    defaultValues: {
      value,
    },
  });
  return (
    <>
      <form
        onSubmit={(e) => {
          e.stopPropagation();
          handleSubmit(({ value }) => onSubmit(value))(e);
        }}
      >
        <FormGroup>
          <Label>
            <LabelText>{kind === 'alt' ? 'Alt text' : 'Caption'}</LabelText>
            <Input
              {...register('value', {
                maxLength: {
                  value: captionMaxLength,
                  message: `Too long, please limit the ${kind === 'alt' ? 'alt text' : 'caption'} to 160 characters`,
                },
              })}
            />
          </Label>
          <FormDescription>
            {kind === 'alt' ? 'Alternative text for screen readers' : 'Some descriptive text'}
          </FormDescription>
          {errors.value && <FormError>{errors.value.message}</FormError>}
        </FormGroup>
        <FormGroup>
          <div>
            <Button type="submit" size="md">
              <span>Save</span>
            </Button>
          </div>
        </FormGroup>
      </form>
    </>
  );
};

const CaptionAltEditor = ({
  value,
  onChange,
  kind,
}: {
  value: string;
  onChange: (caption: string) => void;
  kind: 'alt' | 'caption';
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const onSubmit = (caption: string) => {
    onChange(caption);
    setIsModalOpen(false);
  };
  return (
    <>
      <InlineButton onClick={() => setIsModalOpen(true)} className={themeClasses({ flex: 'none' })}>
        {`${value ? 'Edit ' : 'Add '}${kind === 'alt' ? 'alt text' : 'caption'}`}
      </InlineButton>
      <GenericReactModal isOpen={isModalOpen} onRequestClose={() => setIsModalOpen(false)}>
        <div style={{ maxWidth: '95vw', width: 320 }}>
          <h4 className={themeClasses({ marginY: 0 })}>Edit {kind === 'alt' ? 'alt text' : 'caption'}</h4>
          <CaptionForm value={value} onSubmit={onSubmit} kind={kind} />
        </div>
      </GenericReactModal>
    </>
  );
};

export default CaptionAltEditor;
