import { httpsCallable } from 'firebase/functions';
import React, { useState } from 'react';
import useErrorReporter from 'web/hooks/useErrorReporter';
import { Button } from './elements';
import useFunctions from './FirebaseContext/useFunctions';
import Spinner from './Spinner';

const PayPalConnectButton = ({
  text,
  variant,
  returnTo,
}: {
  text?: string;
  variant: 'primary' | 'secondary';
  returnTo: string;
}) => {
  const errorReporter = useErrorReporter();
  const functions = useFunctions();
  const [loading, setLoading] = useState(false);

  const getActionUrl = async () => {
    setLoading(true);
    try {
      const returnUrl = `${window.location.origin}${returnTo}`;
      const res = await httpsCallable<{ returnUrl: string }, { actionUrl: string }>(
        functions,
        'paypalCreatePartnerReferral',
      )({ returnUrl });
      if (!res.data.actionUrl) {
        throw new Error(`Callable didn't return an actionUrl`);
      }
      window.location.href = res.data.actionUrl;
    } catch (err) {
      errorReporter.report(err);
      setLoading(false);
    }
  };

  return (
    <Button onClick={getActionUrl} md variant={variant} disabled={loading}>
      {loading && <Spinner />}
      <span>{text || 'Connect'}</span>
    </Button>
  );
};

export default PayPalConnectButton;
