import { Crop } from 'react-image-crop';

export interface Size {
  width: number;
  height: number;
}

const makeLqip = async (source: Blob | ImageBitmap) => {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  const image = await createImageBitmap(source);

  const ratio = image.width / image.height;

  canvas.width = 20;
  canvas.height = 20 / ratio;

  ctx.drawImage(image, 0, 0, image.width, image.height, 0, 0, canvas.width, canvas.height);
  const dataUrl = canvas.toDataURL('image/webp', 0.2);
  return dataUrl;
};

const canvasToBlob = (canvas: HTMLCanvasElement, type: string) => {
  return new Promise<Blob>((resolve, reject) => {
    const onBlob = (blob: Blob) => {
      if (!blob) {
        reject(new Error('Canvas is empty'));
        return;
      }
      const finalBlob = new Blob([blob], { type: type });
      resolve(finalBlob);
    };
    if (canvas.toBlob && typeof canvas.toBlob === 'function') {
      canvas.toBlob(onBlob, type);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
    } else if (canvas.msToBlob && typeof canvas.msToBlob === 'function') {
      // Edge (original) and IE11 implement toBlob with a vendor prefix
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-assignment
      const blob = canvas.msToBlob(type);
      onBlob(blob);
    } else {
      reject(new Error('Cannot crop the image. Cropping is not supported.'));
    }
  });
};

const fitToSize = async (source: Blob, size: Size) => {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  const image = await createImageBitmap(source);

  const scale = Math.min(size.width / image.width, size.height / image.height);

  if (scale >= 1) {
    return source;
  }

  canvas.width = image.width * scale;
  canvas.height = image.height * scale;

  ctx.drawImage(image, 0, 0, image.width, image.height, 0, 0, canvas.width, canvas.height);

  const result = await canvasToBlob(canvas, source.type);

  if (result.size > source.size) {
    return source;
  }

  return result;
};

const cropImage = async (crop: Crop, size: Size, image: HTMLImageElement, type: string) => {
  const canvas = document.createElement('canvas');
  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  canvas.width = size.width;
  canvas.height = size.height;
  const ctx = canvas.getContext('2d');

  // TODO: this is not supported in Firefox, so the resized images will be of a noticeably low quality
  ctx.imageSmoothingEnabled = true;
  ctx.imageSmoothingQuality = 'high';

  ctx.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    size.width,
    size.height,
  );

  const blob = await canvasToBlob(canvas, type);
  const dataUrl = canvas.toDataURL(type);
  return [blob, dataUrl] as const;
};

const makeClientCrop = async (crop: Crop, size: Size, image: HTMLImageElement, type: string) => {
  if (image && crop && crop.width && crop.height) {
    return cropImage(crop, size, image, type);
  } else if (!image) {
    throw new Error('Image element is not ready');
  } else {
    throw new Error(`Crop is not ready: ${crop.width}x${crop.height}`);
  }
};

const getKeyExt = (blob: Blob) => {
  const { type } = blob;
  const ext = type.replace('image/', '');
  if (!ext) {
    throw new Error('Cannot get the file extension for an upload');
  }
  return ext;
};

const acceptedFileTypes = ['image/png', 'image/jpeg'];
const acceptedFileTypesString = acceptedFileTypes.map((type) => type.replace('image/', '')).join(', ');

const assertFileValid = (blob: Blob, maxMbSize = 20) => {
  const maxSize = maxMbSize * 1024 * 1024;
  if (blob.size > maxSize) {
    throw new Error(`too big, has to be less than ${maxMbSize}MB`);
  }
  if (acceptedFileTypes.indexOf(blob.type) === -1) {
    throw new Error(`invalid type, allowed ${acceptedFileTypesString}`);
  }
};

const getKey = (uid: string, customPrefix: string, rndId: string, blob: Blob) => {
  const keyPrefix = `uploads/users/${uid}/images/${customPrefix}`;
  const ext = getKeyExt(blob);
  return `${keyPrefix}${rndId}.${ext}`;
};

export { makeClientCrop, getKeyExt, assertFileValid, acceptedFileTypes, getKey, makeLqip, fitToSize };
