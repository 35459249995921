import { faImage } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LexicalEditor } from 'lexical';
import React, { useState } from 'react';
import GenericReactModal from 'web/components/GenericReactModal';
import sc from 'web/components/styled';
import InsertImageDialog from './InsertImageDialog';
import { toolbarItemButton, toolbarItemIcon } from './toolbar.css';

const DialogContainer = sc.div(undefined, { width: 300, maxWidth: '95vw' });

const InsertImageToolbar = ({ editor }: { editor: LexicalEditor }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <button
        className={toolbarItemButton}
        aria-label="Insert image"
        title="Insert image"
        type="button"
        onClick={() => setIsOpen(true)}
      >
        <FontAwesomeIcon className={toolbarItemIcon} icon={faImage} fixedWidth />
      </button>
      <GenericReactModal isOpen={isOpen} onRequestClose={() => setIsOpen(false)}>
        <DialogContainer>
          <InsertImageDialog activeEditor={editor} onClose={() => setIsOpen(false)} />
        </DialogContainer>
      </GenericReactModal>
    </>
  );
};

export default InsertImageToolbar;
