import { RangeSelection } from 'lexical';
import { $isAtNodeEnd } from '@lexical/selection';

export const getSelectedNode = (selection: RangeSelection) => {
  const anchor = selection.anchor;
  const focus = selection.focus;
  const anchorNode = selection.anchor.getNode();
  const focusNode = selection.focus.getNode();
  if (anchorNode === focusNode) {
    return anchorNode;
  }
  const isBackward = selection.isBackward();
  if (isBackward) {
    return $isAtNodeEnd(focus) ? anchorNode : focusNode;
  } else {
    return $isAtNodeEnd(anchor) ? focusNode : anchorNode;
  }
};

export const LowPriority = 1;
const supportedBlockTypesArray = ['paragraph', 'quote', 'h2', 'h3', 'ul', 'ol'] as const;
export const supportedBlockTypesSet = new Set(supportedBlockTypesArray);
export type SupportedBlockType = typeof supportedBlockTypesArray[number];
