import { nanoid } from 'nanoid';
import { useCallback, useContext } from 'react';
import UserContext from 'web/components/UserContext';
import settings from 'web/utils/settings';

const makeStripeOAuthLink = ({
  redirectUri,
  clientId,
  state,
  email,
  firstName,
  lastName,
  pageUrl,
  standard,
}: {
  redirectUri: string;
  clientId: string;
  state: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  pageUrl?: string;
  standard?: boolean;
}) =>
  `https://connect.stripe.com${standard ? '' : '/express'}/oauth/authorize` +
  `?redirect_uri=${encodeURIComponent(redirectUri)}&client_id=${clientId}` +
  `&state=${state}` +
  (standard ? '&response_type=code&scope=read_write' : '') +
  (email ? `&stripe_user[email]=${encodeURIComponent(email)}` : '') +
  (firstName ? `&stripe_user[first_name]=${encodeURIComponent(firstName)}` : '') +
  (lastName ? `&stripe_user[last_name]=${encodeURIComponent(lastName)}` : '') +
  (pageUrl ? `&stripe_user[url]=${encodeURIComponent(pageUrl)}` : '');

const useStripeOAuthButton = (redirect: string) => {
  const { userData } = useContext(UserContext);
  const standard = userData.stripeAccountType === 'standard';

  const redirectToStripe = useCallback(() => {
    const state = nanoid();
    window.localStorage.setItem('introwise-stripe-oauth-state', state);
    const clientId = settings.stripe.clientId;
    const redirectUri = `${window.origin}${redirect}`;
    window.location.href = makeStripeOAuthLink({
      clientId,
      redirectUri,
      state,
      email: userData.email,
      firstName: userData.firstName,
      lastName: userData.lastName,
      pageUrl: userData.bookingPageSlug ? `${window.origin}/${userData.bookingPageSlug}` : undefined,
      standard,
    });
  }, [redirect, standard, userData.bookingPageSlug, userData.email, userData.firstName, userData.lastName]);

  return redirectToStripe;
};

export default useStripeOAuthButton;
