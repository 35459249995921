import { AutoLinkNode, LinkNode } from '@lexical/link';
import { ListItemNode, ListNode } from '@lexical/list';
import { TRANSFORMERS } from '@lexical/markdown';
import { AutoFocusPlugin } from '@lexical/react/LexicalAutoFocusPlugin';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { MarkdownShortcutPlugin } from '@lexical/react/LexicalMarkdownShortcutPlugin';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { QuoteNode } from '@lexical/rich-text';
import { EditorThemeClasses, LexicalEditor } from 'lexical';
import React from 'react';
import useErrorReporter from 'web/hooks/useErrorReporter';
import { CloudAppNode } from './nodes/CloudAppNode';
import { HeadingNode } from './nodes/HeadingNode';
import { VimeoNode } from './nodes/VimeoNode';
import { YouTubeNode } from './nodes/YouTubeNode';
import OnInitPlugin from './plugins/OnInitPlugin';
import AutoLinkPlugin from './plugins/AutoLinkPlugin';
import CloudAppPlugin from './plugins/CloudAppPlugin';
import LinkNormalizationPlugin from './plugins/LinkNormalizationPlugin';
import ListMaxIndentLevelPlugin from './plugins/ListMaxIndentLevelPlugin';
import ToolbarPlugin from './plugins/ToolbarPlugin/ToolbarPlugin';
import VimeoPlugin from './plugins/VimeoPlugin';
import YouTubePlugin from './plugins/YouTubePlugin';
import {
  contentEditablePadding,
  editorContainer,
  editorContentEditable,
  editorEmbedBlockBase,
  editorEmbedBlockFocus,
  editorInner,
  editorLabel,
  editorLink,
  editorNestedList,
  editorPlaceholder,
  editorTextUnderline,
} from './richEditor.css';
import SetEditablePlugin from './plugins/SetEditablePlugin';
import { SendsparkNode } from './nodes/SendsparkNode';
import SendsparkPlugin from './plugins/SendsparkPlugin';
import { ImageNode } from './nodes/ImageNode';
import ImagePlugin from './plugins/ImagePlugin';

function Placeholder({ children }: { children: React.ReactNode }) {
  return <p className={editorPlaceholder}>{children}</p>;
}

const theme: EditorThemeClasses = {
  link: editorLink,
  list: {
    nested: {
      listitem: editorNestedList,
    },
  },
  text: {
    underline: editorTextUnderline,
  },
  embedBlock: {
    base: editorEmbedBlockBase,
    focus: editorEmbedBlockFocus,
  },
};

const markdownTransformers = TRANSFORMERS.filter((transformer) => transformer.type === 'text-format');

const RichTextEditor = ({
  placeholder,
  rows,
  initialState,
  onInit,
  label,
  additionalPlugins,
  disabled,
}: {
  placeholder?: string;
  rows?: number;
  initialState?: () => void;
  onInit?: (editor: LexicalEditor) => void;
  label?: string;
  additionalPlugins?: React.ReactElement[];
  disabled?: boolean;
}) => {
  const errorReporter = useErrorReporter();
  const editorConfig = {
    namespace: 'RichTextEditor',
    theme,
    onError: (error: Error) => errorReporter.report(error),
    editorState: initialState,
    editable: !disabled,
    nodes: [
      HeadingNode,
      ListNode,
      ListItemNode,
      QuoteNode,
      AutoLinkNode,
      LinkNode,
      YouTubeNode,
      CloudAppNode,
      VimeoNode,
      SendsparkNode,
      ImageNode,
    ],
  };

  return (
    <LexicalComposer initialConfig={editorConfig}>
      <div className={editorContainer}>
        {label && <label className={editorLabel}>{label}</label>}
        <ToolbarPlugin withUndo />
        <div className={editorInner}>
          <RichTextPlugin
            contentEditable={
              <ContentEditable
                {...(label ? { ariaLabelledBy: label } : {})}
                className={editorContentEditable}
                style={{
                  minHeight: rows ? 21.6 * rows + contentEditablePadding[0] + contentEditablePadding[2] : undefined,
                }}
              />
            }
            placeholder={placeholder ? <Placeholder>{placeholder}</Placeholder> : undefined}
            ErrorBoundary={LexicalErrorBoundary}
          />
          <HistoryPlugin />
          <AutoFocusPlugin />
          <ListPlugin />
          <LinkPlugin />
          <AutoLinkPlugin />
          <LinkNormalizationPlugin />
          <ListMaxIndentLevelPlugin maxDepth={3} />
          <MarkdownShortcutPlugin transformers={markdownTransformers} />
          <YouTubePlugin />
          <CloudAppPlugin />
          <VimeoPlugin />
          <SendsparkPlugin />
          <ImagePlugin />
          <OnInitPlugin onInit={onInit} />
          <SetEditablePlugin editable={!disabled} />
          {additionalPlugins}
        </div>
      </div>
    </LexicalComposer>
  );
};

export default RichTextEditor;
