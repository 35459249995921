import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import type { LexicalCommand } from 'lexical';
import {
  $createParagraphNode,
  $getSelection,
  $isRangeSelection,
  COMMAND_PRIORITY_EDITOR,
  createCommand,
} from 'lexical';
import React, { useEffect } from 'react';
import { OembedDataVideo } from 'web/utils/oembed';
import { $createYouTubeNode, YouTubeNode } from '../nodes/YouTubeNode';

export type YouTubeCommandPayload = { videoId: string; url: string; oembed: OembedDataVideo; caption: string };

export const INSERT_YOUTUBE_COMMAND: LexicalCommand<YouTubeCommandPayload> = createCommand();

const YouTubePlugin = () => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    if (!editor.hasNodes([YouTubeNode])) {
      throw new Error('YouTubePlugin: YouTubeNode not registered on editor');
    }

    return editor.registerCommand<YouTubeCommandPayload>(
      INSERT_YOUTUBE_COMMAND,
      (payload) => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
          const focusNode = selection.focus.getNode();

          if (focusNode !== null) {
            const youTubeNode = $createYouTubeNode(payload.videoId, payload.url, payload.oembed, payload.caption);
            selection.focus.getNode().getTopLevelElementOrThrow().insertAfter(youTubeNode);
            const paragraphNode = $createParagraphNode();
            youTubeNode.insertAfter(paragraphNode);
            paragraphNode.select();
          }
        }

        return true;
      },
      COMMAND_PRIORITY_EDITOR,
    );
  }, [editor]);

  return <></>;
};

export default YouTubePlugin;
