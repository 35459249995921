import { faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { To } from 'history';
import React, { useContext } from 'react';
import BookingPageContext from 'web/components/BookingPageContext';
import { LinkButton } from 'web/components/elements';
import useErrorHandler from 'web/hooks/useErrorHandler';
import themeClasses from 'web/styles/themeClasses.css';
import { formatCurrencyAmount } from 'web/utils/currency';
import { ServicesLoading, ServicesList, ServiceItemLink, ServicesButtonsGroup } from './ServicesCommon';

const Services = ({
  serviceTo,
  createTo,
  reorderTo,
}: {
  serviceTo: (serviceId: string) => To;
  createTo: To;
  reorderTo: To;
}) => {
  const [page, loading, error] = useContext(BookingPageContext);
  useErrorHandler(error);

  if (loading) {
    return <ServicesLoading>Loading...</ServicesLoading>;
  }

  if (error || !page) {
    return <p>Failed to load services</p>;
  }

  const servicesSorted = page.services
    ? Object.keys(page.services)
        .map((key) => page.services[key])
        .sort((a, b) => (!!a.hidden === !!b.hidden ? a.order - b.order : a.hidden ? 1 : -1))
    : [];

  const hasManyNonHiddenServices = servicesSorted.filter((s) => !s.hidden).length > 1;

  return (
    <>
      <ServicesList inline>
        {servicesSorted.map((service) => (
          <li key={service.id}>
            <ServiceItemLink to={serviceTo(service.id)}>
              {service.hidden && (
                <FontAwesomeIcon
                  icon={faEyeSlash}
                  fixedWidth
                  className={themeClasses({ marginRight: 2 })}
                  style={{ fontSize: '0.75em', verticalAlign: 'baseline' }}
                  title="Hidden service"
                />
              )}
              {service.title} &mdash; {service.price ? formatCurrencyAmount(service.price, page.currency) : 'Free'}
            </ServiceItemLink>
          </li>
        ))}
      </ServicesList>
      <ServicesButtonsGroup>
        <LinkButton md secondary to={createTo}>
          Add new service
        </LinkButton>
        {hasManyNonHiddenServices && (
          <LinkButton md secondary to={reorderTo}>
            Reorder
          </LinkButton>
        )}
      </ServicesButtonsGroup>
    </>
  );
};

export default Services;
