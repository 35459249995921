import React from 'react';
import { useDropzone } from 'react-dropzone';
import styled, { css } from 'styled-components';
import { Size } from 'web/components/form-fields/PictureUpload/helpers';
import { Button } from 'web/components/elements';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import themeConstants from 'web/styles/themeConstants';
import themeVars from 'web/styles/themeVars.css';

const aspectRatioMixin = css<{ size?: Size }>`
  width: 100%;
  max-width: ${({ size }) => (size.width ? `${size.width}px` : '100%')};
  @supports (aspect-ratio: 4) {
    aspect-ratio: calc(${({ size }) => size.width} / ${({ size }) => size.height});
  }
  @supports not (aspect-ratio: 4) {
    height: ${({ size }) => (size.height ? `${size.height}px` : '100%')};
  }
`;

const UploadDropzone = styled.div<{ size?: Size }>`
  ${aspectRatioMixin}
  position: relative;
`;

const UploadPrompt = styled.div<{ circle?: boolean }>`
  height: 100%;
  width: 100%;
  padding: 10px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: ${({ circle }) => (circle ? 'center' : 'flex-start')};
  text-align: center;

  background: #fff;
  border-radius: ${({ circle }) => (circle ? '50%' : themeConstants.borderRadius.sm)};
  border: ${themeVars.color.accentOutline};
`;

interface Props {
  onDrop: (files: File[], rejectedFiles: File[]) => void;
  onRemove: () => void;
  size?: Size;
  previewUrl?: string;
  accept?: string;
  circle?: boolean;
}

const DropZoneContainer = styled.div<{ size?: Size }>`
  ${aspectRatioMixin}
  position: relative;
  cursor: pointer;
`;

const UploadPreview = styled.div<{ size?: Size; circle?: boolean }>`
  ${aspectRatioMixin}
  border-radius: ${({ circle }) => (circle ? '50%' : themeConstants.borderRadius.sm)};
  overflow: hidden;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
`;

const UploadContainer = styled.div`
  background: rgba(255, 255, 255, 0.25);
`;

const RemoveButton = styled(Button)<{ $circle?: boolean }>`
  ${({ $circle }) =>
    $circle &&
    css`
      border-radius: 50%;
      width: 38px;
      height: 38px;
    `};
  position: absolute;
  top: 10px;
  right: 10px;
  background: #fff;
`;

const DropZone = ({ onDrop, size, previewUrl, onRemove, accept, circle }: Props) => {
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { getRootProps, getInputProps } = useDropzone({ onDrop, accept, multiple: false });

  return (
    <DropZoneContainer size={size}>
      {previewUrl && <UploadPreview size={size} circle={circle} style={{ backgroundImage: `url(${previewUrl})` }} />}
      <UploadContainer>
        <UploadDropzone size={size} {...getRootProps()}>
          <input {...getInputProps()} />
          {!previewUrl && (
            <UploadPrompt circle={circle}>
              Drop something here or click to choose a file
              {size && (
                <small>
                  Recommended
                  <br />
                  {size.width} x {size.height} pixels
                </small>
              )}
            </UploadPrompt>
          )}
        </UploadDropzone>
      </UploadContainer>
      {previewUrl && (
        <RemoveButton secondary sm onClick={onRemove} $circle={circle} title="Remove">
          <FontAwesomeIcon icon={faTimes} />
        </RemoveButton>
      )}
    </DropZoneContainer>
  );
};

export default DropZone;
