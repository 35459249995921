import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useMemo, useState } from 'react';
import { Controller, useForm, UseFormReturn } from 'react-hook-form';
import {
  Button,
  Checkbox,
  FormDescription,
  FormError,
  FormGroup,
  Input,
  Label,
  LabelText,
  LinkStyled,
  Select,
} from 'web/components/elements';
import GenericReactModal from 'web/components/GenericReactModal';
import themeClasses from 'web/styles/themeClasses.css';

const minNoticeOptions = {
  0: '0 minutes',
  5: '5 minutes',
  10: '10 minutes',
  15: '15 minutes',
  30: '30 minutes',
  45: '45 minutes',
  60: '1 hour',
  90: '1.5 hours',
  120: '2 hours',
  180: '3 hours',
  360: '6 hours',
  720: '12 hours',
  1440: '24 hours',
  2880: '2 days',
  4320: '3 days',
  10080: '1 week',
};

const bufferOptions = {
  0: '0 minutes',
  5: '5 minutes',
  10: '10 minutes',
  15: '15 minutes',
  30: '30 minutes',
  45: '45 minutes',
  60: '1 hour',
  90: '1.5 hours',
  120: '2 hours',
  180: '3 hours',
  240: '4 hours',
};

type SchedulingRulesFormValues = {
  schedulingRules: {
    checkedFrequency: boolean;
    checkedMaxNotice: boolean;
    checkedAlignment: boolean;
    minNotice: number;
    maxNotice: number;
    bufferBefore: number;
    bufferAfter: number;
    frequency: {
      daily: number;
      weekly: number;
    };
    alignment: number[];
  };
};

const minsInDay = 60 * 24;

const defaultMaxNotice = 90;

const makeDefaultValues = (
  initialValues?: Pick<introwise.User['settings'], 'schedulingRules'>,
): SchedulingRulesFormValues => ({
  schedulingRules: {
    checkedMaxNotice: !!initialValues?.schedulingRules?.maxNotice,
    checkedFrequency: !!initialValues?.schedulingRules?.frequency,
    checkedAlignment: !!initialValues?.schedulingRules?.alignment,
    minNotice: initialValues?.schedulingRules?.minNotice || 0,
    maxNotice: initialValues?.schedulingRules?.maxNotice
      ? initialValues?.schedulingRules?.maxNotice / minsInDay
      : defaultMaxNotice,
    bufferBefore: initialValues?.schedulingRules?.bufferBefore || 0,
    bufferAfter: initialValues?.schedulingRules?.bufferAfter || 0,
    frequency: initialValues?.schedulingRules?.frequency || {
      daily: undefined,
      weekly: undefined,
    },
    alignment: initialValues?.schedulingRules?.alignment || [],
  },
});

const toSubmitValues = (formValues: SchedulingRulesFormValues) => ({
  minNotice: formValues.schedulingRules.minNotice,
  maxNotice: formValues.schedulingRules.checkedMaxNotice ? formValues.schedulingRules.maxNotice * minsInDay : 0,
  bufferBefore: formValues.schedulingRules.bufferBefore,
  bufferAfter: formValues.schedulingRules.bufferAfter,
  frequency: formValues.schedulingRules.checkedFrequency ? formValues.schedulingRules.frequency : null,
  alignment: formValues.schedulingRules.checkedAlignment ? formValues.schedulingRules.alignment : null,
});

const CustomAlignment = ({ onSubmit }: { onSubmit: (values: { minutes: number }) => void }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues: { minutes: 0 } });
  return (
    <form
      onSubmit={(e) => {
        e.stopPropagation();
        handleSubmit(onSubmit)(e);
      }}
    >
      <FormGroup>
        <Label>
          <LabelText>Minutes past the hour</LabelText>
          <Input
            type="number"
            placeholder="00"
            {...register('minutes', {
              required: true,
              min: {
                value: 0,
                message: 'Must be between 0 and 59',
              },
              max: {
                value: 59,
                message: 'Must be between 0 and 59',
              },
              valueAsNumber: true,
            })}
          />
        </Label>
        {errors.minutes && <FormError>{errors.minutes.message}</FormError>}
      </FormGroup>
      <FormGroup className={themeClasses({ display: 'flex', justifyContent: 'flex-end' })}>
        <Button type="submit" variant="primary" size="md">
          Add
        </Button>
      </FormGroup>
    </form>
  );
};

const defaultAlignmentOptions = [0, 5, 10, 15, 30, 35, 40, 45];

const Alignment = ({ value: alignment, onChange }: { value: number[]; onChange: (value: number[]) => void }) => {
  const [modalOpen, setModalOpen] = useState(false);

  const alignemntOptions = useMemo(() => {
    const set = new Set([...defaultAlignmentOptions, ...alignment]);
    return Array.from(set).sort((a, b) => a - b);
  }, [alignment]);

  return (
    <FormGroup>
      <div className={themeClasses({ display: 'flex', columnGap: 5, rowGap: 4 })}>
        {alignemntOptions.map((value) => {
          const checked = alignment.includes(value);
          return (
            <Button
              key={value}
              onClick={() => {
                const newValue = !checked ? [...alignment, value] : alignment.filter((v) => v !== value);
                onChange(newValue);
              }}
              variant={checked ? 'primary' : 'secondary'}
              size="xs"
            >
              : {value.toString().padStart(2, '0')}
            </Button>
          );
        })}
        <Button onClick={() => setModalOpen(true)} variant="secondary" size="xs">
          <FontAwesomeIcon icon={faPlus} />
        </Button>
        <GenericReactModal isOpen={modalOpen} onRequestClose={() => setModalOpen(false)}>
          <div style={{ width: 200 }}>
            <h4 className={themeClasses({ marginTop: 0 })}>Custom alignment</h4>
            <CustomAlignment
              onSubmit={({ minutes }) => {
                onChange([...alignment.filter((v) => v !== minutes), minutes]);
                setModalOpen(false);
              }}
            />
          </div>
        </GenericReactModal>
      </div>
      <FormDescription>Sessions will be scheduled to start only at the selected minutes past the hour</FormDescription>
    </FormGroup>
  );
};

const SchedulingRulesFormCommon = ({
  form,
  global = true,
}: {
  form: UseFormReturn<SchedulingRulesFormValues>;
  global?: boolean;
}) => {
  const { register, watch, setValue, getValues, formState, control } = form;

  const checkedMaxNotice = watch('schedulingRules.checkedMaxNotice');
  const checkedFrequency = watch('schedulingRules.checkedFrequency');
  const checkedAlignment = watch('schedulingRules.checkedAlignment');
  const { errors } = formState;

  return (
    <>
      <FormGroup>
        <Label>
          <LabelText>Buffer before</LabelText>
          <Select {...register('schedulingRules.bufferBefore', { valueAsNumber: true })}>
            {Object.entries(bufferOptions).map(([value, label]) => (
              <option value={value} key={value}>
                {label}
              </option>
            ))}
          </Select>
        </Label>
        {errors.schedulingRules?.bufferBefore && <FormError>{errors.schedulingRules?.bufferBefore.message}</FormError>}
      </FormGroup>
      <FormGroup>
        <Label>
          <LabelText>Buffer after</LabelText>
          <Select {...register('schedulingRules.bufferAfter', { valueAsNumber: true })}>
            {Object.entries(bufferOptions).map(([value, label]) => (
              <option value={value} key={value}>
                {label}
              </option>
            ))}
          </Select>
        </Label>
        {errors.schedulingRules?.bufferAfter && <FormError>{errors.schedulingRules?.bufferAfter.message}</FormError>}
      </FormGroup>
      <FormGroup>
        <Label>
          <LabelText>Minimum notice</LabelText>
          <Select {...register('schedulingRules.minNotice', { valueAsNumber: true })}>
            {Object.entries(minNoticeOptions).map(([value, label]) => (
              <option value={value} key={value}>
                {label}
              </option>
            ))}
          </Select>
        </Label>
        {errors.schedulingRules?.minNotice && <FormError>{errors.schedulingRules?.minNotice.message}</FormError>}
      </FormGroup>
      <FormGroup>
        <Checkbox
          {...register('schedulingRules.checkedMaxNotice')}
          onChange={(e) => setValue('schedulingRules.checkedMaxNotice', e.target.checked)}
        >
          Limit how far in advance clients can book with you
        </Checkbox>
      </FormGroup>
      {checkedMaxNotice && (
        <FormGroup>
          <Label>
            <LabelText>Maximum days in the future</LabelText>
            <Input
              type="number"
              {...register('schedulingRules.maxNotice', {
                min: { value: 1, message: 'Must be greater than 0 days' },
                max: { value: 36500, message: 'Cannot be greater than 36500 days' },
                validate: (value) => {
                  const minNotice = getValues('schedulingRules.minNotice');
                  if (minNotice >= value * minsInDay) {
                    return 'Maximum advance booking time must be greater than your minimum notice time';
                  }
                },
                valueAsNumber: true,
              })}
            />
          </Label>
          {errors.schedulingRules?.maxNotice && <FormError>{errors.schedulingRules?.maxNotice.message}</FormError>}
        </FormGroup>
      )}
      <FormGroup>
        <Checkbox
          {...register('schedulingRules.checkedFrequency')}
          onChange={(e) => setValue('schedulingRules.checkedFrequency', e.target.checked)}
        >
          Limit how many sessions you allow {global ? 'in total' : 'for this service'} per day/week
        </Checkbox>
      </FormGroup>
      {checkedFrequency && (
        <FormGroup>
          <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, minmax(0, 1fr))', columnGap: 20 }}>
            <div>
              <Label>
                <LabelText>Per day</LabelText>
                <Input
                  type="number"
                  min={0}
                  max={288}
                  placeholder="Unlimited"
                  {...register('schedulingRules.frequency.daily', {
                    min: { value: 1, message: 'Must be greater than 0' },
                    max: { value: 288, message: 'Cannot be greater than 288' },
                    valueAsNumber: true,
                  })}
                />
              </Label>
              {errors.schedulingRules?.frequency?.daily && (
                <FormError>{errors.schedulingRules?.frequency.daily.message}</FormError>
              )}
            </div>
            <div>
              <Label>
                <LabelText>Per week</LabelText>
                <Input
                  placeholder="Unlimited"
                  type="number"
                  min={0}
                  max={2016}
                  {...register('schedulingRules.frequency.weekly', {
                    min: { value: 1, message: 'Must be greater than 0' },
                    max: { value: 2016, message: 'Cannot be greater than 2016' },
                    valueAsNumber: true,
                  })}
                />
              </Label>
              {errors.schedulingRules?.frequency?.weekly && (
                <FormError>{errors.schedulingRules?.frequency.weekly.message}</FormError>
              )}
            </div>
          </div>
          <FormDescription>
            {global ? (
              'If you have multiple services, these frequency limits apply to the total number of sessions you allow per day/week'
            ) : (
              <>
                These frequency limits apply to this service only. The{' '}
                <LinkStyled to="/dashboard/scheduling/services/rules" target="_blank">
                  global limits
                </LinkStyled>{' '}
                still apply to all your services combined.
              </>
            )}
          </FormDescription>
        </FormGroup>
      )}
      <>
        <FormGroup>
          <Checkbox
            {...register('schedulingRules.checkedAlignment')}
            onChange={(e) => setValue('schedulingRules.checkedAlignment', e.target.checked)}
          >
            Align session start times to specific minutes past the hour
          </Checkbox>
        </FormGroup>
        {checkedAlignment && (
          <Controller
            control={control}
            name="schedulingRules.alignment"
            rules={{
              required: 'At least one alignment time is required',
              validate: (value) => {
                if (value.length === 0) {
                  return 'At least one alignment time is required';
                }
              },
            }}
            shouldUnregister
            defaultValue={[]}
            render={({ field: { onChange, value } }) => <Alignment onChange={onChange} value={value} />}
          />
        )}
      </>
    </>
  );
};

export { makeDefaultValues, toSubmitValues, SchedulingRulesFormValues };
export default SchedulingRulesFormCommon;
