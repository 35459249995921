import React, { useMemo, useRef, useState } from 'react';
import ReactCrop, { Crop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import styled from 'styled-components';
import { Button } from 'web/components/elements';
import { makeClientCrop, Size } from 'web/components/form-fields/PictureUpload/helpers';
import GenericReactModal from 'web/components/GenericReactModal';

const ModalContainer = styled.div`
  text-align: center;
`;

const ReactCropContainer = styled.div`
  max-width: 600px;
  max-height: 600px;
  margin-top: 20px;

  .ReactCrop__image {
    max-height: 500px;
  }
`;

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onCropDone: (blob: Blob, dataUrl: string) => void;
  onError: (err: Error) => void;
  blob: Blob;
  size: Size;
  circle?: boolean;
}

const CropModal = ({ isOpen, onClose, onCropDone, onError, blob, size, circle }: Props) => {
  const imgRef = useRef<HTMLImageElement>(null);
  const [crop, setCrop] = useState<Crop>({ unit: '%', width: 80, aspect: size.width / size.height, x: 10, y: 10 });
  const imgSrc = useMemo(() => blob && URL.createObjectURL(blob), [blob]);

  const buttonDisabled = !crop || crop.width === 0 || crop.height === 0;

  const onDone = async () => {
    let cropResult;
    try {
      cropResult = await makeClientCrop(crop, size, imgRef.current, blob.type);
    } catch (err) {
      onError(err);
      return;
    }
    onCropDone(...cropResult);
  };

  return (
    <GenericReactModal isOpen={isOpen} onRequestClose={onClose} shouldCloseOnOverlayClick={false}>
      <ModalContainer>
        <ReactCropContainer>
          <ReactCrop
            src={imgSrc}
            onImageLoaded={(imgElem) => {
              imgRef.current = imgElem;
            }}
            onImageError={() => onError(new Error('Failed to load the image to crop'))}
            crop={crop}
            onChange={setCrop}
            keepSelection
            circularCrop={circle}
          />
        </ReactCropContainer>
        <Button primary onClick={onDone} disabled={buttonDisabled}>
          Done
        </Button>
      </ModalContainer>
    </GenericReactModal>
  );
};

export default CropModal;
