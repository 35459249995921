import { LexicalEditor } from 'lexical';
import React, { useEffect, useMemo, useState } from 'react';
import { Button, FormDescription, FormError, FormGroup, Input, Label, LabelText } from 'web/components/elements';
import Spinner from 'web/components/Spinner';
import useErrorStateHandler from 'web/hooks/useErrorStateHandler';
import themeClasses from 'web/styles/themeClasses.css';
import { fetchOembed } from 'web/utils/oembed';
import { INSERT_VIMEO_COMMAND, VimeoCommandPayload } from '../VimeoPlugin';

const regex =
  /(http|https)?:\/\/(www\.|player\.)?vimeo\.com\/(?:channels\/(?:\w+\/)?|groups\/([^/]*)\/videos\/|video\/|)(\d+)(?:|\/\?)/m;
const getVideoId = (vimeoUrl: string) => {
  const match = vimeoUrl.match(regex);
  return match && match.length >= 4 ? match[4] : null;
};

const InsertVimeoDialog = ({ activeEditor, onClose }: { activeEditor: LexicalEditor; onClose: () => void }) => {
  const [text, setText] = useState('');
  const [error, setError] = useErrorStateHandler();
  const [loading, setLoading] = useState(false);
  const [payload, setPayload] = useState<VimeoCommandPayload | null>(null);

  const onClick = async () => {
    const videoId = getVideoId(text);
    if (videoId) {
      setLoading(true);
      try {
        const oembed = await fetchOembed(text, 'vimeo');
        setPayload({
          videoId,
          url: text,
          oembed,
          caption: '',
        });
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    }
  };

  const dispatchedRef = React.useRef(false);

  useEffect(() => {
    if (payload && !dispatchedRef.current) {
      activeEditor.dispatchCommand(INSERT_VIMEO_COMMAND, payload);
      dispatchedRef.current = true;
      onClose();
    }
  }, [payload, activeEditor, onClose]);

  const isDisabled = useMemo(() => text === '' || !getVideoId(text), [text]);

  return (
    <>
      <h4 className={themeClasses({ marginY: 0 })}>Insert Vimeo video</h4>
      <FormGroup>
        <Label>
          <LabelText>Vimeo URL</LabelText>
          <Input placeholder="vimeo.com/jNQXAC9IVRw" onChange={(event) => setText(event.target.value)} value={text} />
        </Label>
        <FormDescription>Video URL with the ID</FormDescription>
      </FormGroup>
      <FormGroup>
        <div className="ToolbarPlugin__dialogActions">
          <Button disabled={isDisabled || loading} onClick={onClick} size="md">
            {loading && <Spinner />}
            <span>Insert</span>
          </Button>
        </div>
      </FormGroup>
      {error && (
        <FormGroup>
          <FormError>Cannot embed this video. Please try again later.</FormError>
        </FormGroup>
      )}
    </>
  );
};

export default InsertVimeoDialog;
