import { LexicalEditor } from 'lexical';
import React, { useEffect, useMemo, useState } from 'react';
import { Button, FormDescription, FormError, FormGroup, Input, Label, LabelText } from 'web/components/elements';
import Spinner from 'web/components/Spinner';
import useErrorStateHandler from 'web/hooks/useErrorStateHandler';
import themeClasses from 'web/styles/themeClasses.css';
import { fetchOembed } from 'web/utils/oembed';
import { INSERT_YOUTUBE_COMMAND, YouTubeCommandPayload } from '../YouTubePlugin';

const regex = /(youtu.*be.*)\/(watch\?v=|embed\/|v|shorts|)(.*?((?=[&#?])|$))/m;
const getVideoId = (youtubeUrl: string) => {
  const match = youtubeUrl.match(regex);
  return match && match.length >= 3 ? match[3] : null;
};

const InsertYouTubeDialog = ({ activeEditor, onClose }: { activeEditor: LexicalEditor; onClose: () => void }) => {
  const [text, setText] = useState('');
  const [error, setError] = useErrorStateHandler();
  const [loading, setLoading] = useState(false);
  const [payload, setPayload] = useState<YouTubeCommandPayload | null>(null);

  const onClick = async () => {
    const videoId = getVideoId(text);
    if (videoId) {
      setLoading(true);
      try {
        const oembed = await fetchOembed(text, 'youtube');
        setPayload({
          videoId,
          url: text,
          oembed,
          caption: '',
        });
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    }
  };

  const dispatchedRef = React.useRef(false);

  useEffect(() => {
    if (payload && !dispatchedRef.current) {
      activeEditor.dispatchCommand(INSERT_YOUTUBE_COMMAND, payload);
      dispatchedRef.current = true;
      onClose();
    }
  }, [payload, activeEditor, onClose]);

  const isDisabled = useMemo(() => text === '' || !getVideoId(text), [text]);

  return (
    <>
      <h4 className={themeClasses({ marginY: 0 })}>Insert YouTube video</h4>
      <FormGroup>
        <Label>
          <LabelText>YouTube URL</LabelText>
          <Input
            placeholder="youtube.com/watch?v=jNQXAC9IVRw"
            onChange={(event) => setText(event.target.value)}
            value={text}
          />
        </Label>
        <FormDescription>Video URL with the ID</FormDescription>
      </FormGroup>
      <FormGroup>
        <div className="ToolbarPlugin__dialogActions">
          <Button disabled={isDisabled || loading} onClick={onClick} size="md">
            {loading && <Spinner />}
            <span>Insert</span>
          </Button>
        </div>
      </FormGroup>
      {error && (
        <FormGroup>
          <FormError>Cannot embed this video. Please try again later.</FormError>
        </FormGroup>
      )}
    </>
  );
};

export default InsertYouTubeDialog;
