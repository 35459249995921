import { collection, doc } from 'firebase/firestore';
import React, { useContext } from 'react';
import useFirestoreDocumentData from 'web/hooks/useFirestoreDocumentData';
import { firestorePageConverter } from 'web/utils/convert';
import useFirestore from 'web/components/FirebaseContext/useFirestore';
import UserContext from './UserContext';

export const BookingPageContext = React.createContext<readonly [introwise.Page, boolean, Error]>([null, true, null]);

const BookingPageProvider = ({ children }: { children: React.ReactNode }) => {
  const firestore = useFirestore();
  const { userData } = useContext(UserContext);
  const result = useFirestoreDocumentData(
    userData?.bookingPageId
      ? doc(collection(firestore, 'pages'), userData?.bookingPageId).withConverter(firestorePageConverter)
      : null,
  );
  return <BookingPageContext.Provider value={result}>{children}</BookingPageContext.Provider>;
};

export { BookingPageProvider };
export default BookingPageContext;
