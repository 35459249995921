import { faFlask, faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import pick from 'lodash/pick';
import React, { useMemo } from 'react';
import { RegisterOptions, useForm, UseFormReturn } from 'react-hook-form';
import {
  Button,
  Checkbox,
  FormDescription,
  FormError,
  FormFootnote,
  FormGroup,
  InlineButton,
  Input,
  Label,
  LabelText,
  LinkStyled,
  LinkUnstyled,
  Select,
  TextArea,
} from 'web/components/elements';
import Flare from 'web/components/Flare';
import FormValueLength from 'web/components/form-fields/FormValueLength';
import PaymentAccountField from 'web/components/form-fields/PaymentAccountField';
import PriceInput from 'web/components/form-fields/PriceInput';
import WorkflowsField from 'web/components/form-fields/WorkflowsField';
import SchedulingRulesFormCommon, {
  makeDefaultValues,
  SchedulingRulesFormValues,
  toSubmitValues,
} from 'web/components/SchedulingRulesForm';
import Spinner from 'web/components/Spinner';
import themeClasses from 'web/styles/themeClasses.css';
import {
  convertFromDecimal,
  convertToDecimal,
  exchangeRateToUsd,
  formatCurrencyDecimal,
  isZeroDecimal,
  minChargeAmount,
} from 'web/utils/currency';
import LocationField from '../form-fields/LocationField';

const maxTitleLength = 60;

const titleValidationRules: RegisterOptions = {
  maxLength: {
    value: maxTitleLength,
    message: `Too long, please limit the title to ${maxTitleLength} characters`,
  },
  required: 'Required',
  setValueAs: (v) => v.trim(),
};

const maxDescriptionLength = 750;

const descriptionValidationRules: RegisterOptions = {
  maxLength: {
    value: maxDescriptionLength,
    message: `Too long, please limit the description to ${maxDescriptionLength} characters`,
  },
  setValueAs: (v) => v.trim(),
};

const maxPriceUsdDefault = 1000;
const increasedPriceLimitMultiplier = 5;

const makePriceValidationRules = (currency: introwise.Currency, increasedPriceLimit?: boolean): RegisterOptions => {
  const minPriceValue = convertToDecimal(minChargeAmount[currency], currency);
  const maxPriceValue =
    maxPriceUsdDefault * exchangeRateToUsd[currency] * (increasedPriceLimit ? increasedPriceLimitMultiplier : 1);
  return {
    required: 'Required',
    validate: (strValue: string) => {
      const value = parseFloat(strValue);
      if (isNaN(value)) {
        return 'Must be a number';
      }
      if (value > maxPriceValue) {
        return `That's too much! The service price is limited to ${formatCurrencyDecimal(
          maxPriceValue,
          currency,
        )}. Contact us if you need this limit increased.`;
      }

      if (value < minPriceValue && value !== 0) {
        return `Must be at least ${formatCurrencyDecimal(minPriceValue, currency)}. Use ${formatCurrencyDecimal(
          0,
          currency,
        )} for a free service.`;
      }

      if (isZeroDecimal(currency) && !Number.isInteger(value)) {
        return `Must be a whole number, e.g. ${value.toFixed(0)}`;
      }
    },
  };
};

export type FormValues = Pick<
  introwise.Service,
  | 'title'
  | 'description'
  | 'duration'
  | 'price'
  | 'hidden'
  | 'availabilityId'
  | 'workflowsIds'
  | 'locationId'
  | 'paymentAccountId'
  | 'schedulingRules'
  | 'collectPhoneNumber'
>;

type FormInternalValues = Omit<FormValues, 'duration' | 'price' | 'schedulingRules'> & {
  duration: string;
  price: string;
  durationAmount?: string;
  durationUnit?: 'hour' | 'minute';
  overrideSchedulingRules?: boolean;
} & SchedulingRulesFormValues;

const defaultDuration = '30';
const defaultPrice = '';

const isCustomDuration = (duration: number) => ![10, 15, 30, 45, 60, 90, 120].includes(duration);

const sanitizeDefaultValues = (val: introwise.Service): Omit<FormValues, 'duration' | 'price'> =>
  pick(val, ['title', 'description']);

const toFormValues = (
  service: introwise.Service,
  currency: introwise.Currency,
  defaultSchedulingRules: introwise.SchedulingRules,
): FormInternalValues =>
  service
    ? {
        ...sanitizeDefaultValues(service),
        price: convertToDecimal(service.price, currency).toString(),
        ...(isCustomDuration(service.duration)
          ? {
              duration: 'custom',
              ...(service.duration % 60 === 0
                ? {
                    durationAmount: (service.duration / 60).toString(),
                    durationUnit: 'hour',
                  }
                : {
                    durationAmount: service.duration.toString(),
                    durationUnit: 'minute',
                  }),
            }
          : {
              duration: service.duration.toString(),
            }),
        hidden: !!service.hidden,
        availabilityId: service.availabilityId || 'availability',
        overrideSchedulingRules: !!service.schedulingRules,
        ...makeDefaultValues({ schedulingRules: service.schedulingRules || defaultSchedulingRules }),
        collectPhoneNumber: !!service.collectPhoneNumber,
      }
    : {
        title: '',
        description: '',
        duration: defaultDuration,
        price: defaultPrice,
        hidden: false,
        availabilityId: 'availability',
        overrideSchedulingRules: false,
        ...makeDefaultValues({ schedulingRules: defaultSchedulingRules }),
        collectPhoneNumber: false,
      };

const ServiceForm = ({
  currency,
  initialValues,
  defaultSchedulingRules,
  onSubmit,
  onClone,
  onDelete,
  submitting,
  onChange,
  increasedPriceLimit,
  availabilities,
  withWorkflows,
  withLocation,
  withAvailability,
  schedulingRulesEnabled,
  phoneNumberCollectionEnabled,
}: {
  currency: introwise.Currency;
  availabilities?: introwise.Availability[];
  defaultSchedulingRules?: introwise.SchedulingRules;
  initialValues?: introwise.Service;
  onSubmit: (values: FormValues) => void;
  onClone?: () => void;
  onDelete?: () => void;
  submitting: boolean;
  onChange?: (event: React.FormEvent<HTMLFormElement>) => void;
  increasedPriceLimit?: boolean;
  withWorkflows?: boolean;
  withLocation?: boolean;
  withAvailability?: boolean;
  schedulingRulesEnabled?: boolean;
  phoneNumberCollectionEnabled?: boolean;
}) => {
  const priceValidationRules = useMemo(
    () => makePriceValidationRules(currency, increasedPriceLimit),
    [currency, increasedPriceLimit],
  );

  const defaultValues = useMemo(
    () => toFormValues(initialValues, currency, defaultSchedulingRules),
    [initialValues, currency, defaultSchedulingRules],
  );

  const form = useForm<FormInternalValues>({
    defaultValues,
    shouldUseNativeValidation: false,
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
    setValue,
    getValues,
  } = form;

  const convertAndSubmit = (values: FormInternalValues) => {
    const duration =
      values.duration === 'custom'
        ? (values.durationUnit === 'hour' ? 60 : 1) * Number(values.durationAmount)
        : Number(values.duration);
    onSubmit({
      ...values,
      duration,
      price: convertFromDecimal(parseFloat(values.price), currency),
      workflowsIds: values.workflowsIds || [],
      schedulingRules: values.overrideSchedulingRules ? toSubmitValues(values) : null,
      ...(phoneNumberCollectionEnabled && { collectPhoneNumber: values.collectPhoneNumber }),
    });
  };

  const duration = watch('duration');
  const durationUnit = watch('durationUnit');

  const overrideSchedulingRules = watch('overrideSchedulingRules');

  return (
    <form onSubmit={handleSubmit(convertAndSubmit)} onChange={onChange} noValidate={true}>
      <fieldset disabled={submitting} style={{ padding: 0 }}>
        <FormGroup>
          <Label>
            <LabelText>Title</LabelText>
            <Input
              {...register('title', titleValidationRules)}
              placeholder="Personal session"
              hasError={!!errors.title}
            />
          </Label>
          <FormFootnote>
            <FormValueLength control={control} name="title" maxLength={maxTitleLength} />
          </FormFootnote>
          <FormError>{errors.title ? errors.title.message : <>&nbsp;</>}</FormError>
          <div style={{ marginBottom: '-0.75em' }} />
        </FormGroup>
        <FormGroup>
          <Label>
            <TextArea
              {...register('description', descriptionValidationRules)}
              placeholder="Session type description"
              hasError={!!errors.description}
              rows={6}
            />
            <LabelText>Description (optional)</LabelText>
          </Label>
          <FormFootnote>
            <FormValueLength control={control} name="description" maxLength={maxDescriptionLength} />
          </FormFootnote>
          <FormError>{errors.description ? errors.description.message : <>&nbsp;</>}</FormError>
          <div style={{ marginBottom: '-0.75em' }} />
        </FormGroup>
        <FormGroup>
          <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, minmax(0, 1fr))', columnGap: 20 }}>
            {duration !== 'custom' && (
              <div>
                <Label>
                  <LabelText>Duration</LabelText>
                  <Select
                    {...register('duration', {
                      required: true,
                      onChange: (e) => {
                        if (e.target.value === 'custom' && duration !== 'custom') {
                          const durationNum = Number(duration);
                          if (Number.isNaN(durationNum)) {
                            setValue('durationAmount', '');
                            setValue('durationUnit', 'minute');
                          } else if (durationNum % 60 === 0) {
                            setValue('durationAmount', (durationNum / 60).toString());
                            setValue('durationUnit', 'hour');
                          } else {
                            setValue('durationAmount', duration);
                            setValue('durationUnit', 'minute');
                          }
                        }
                      },
                    })}
                    hasError={!!errors.duration}
                  >
                    <option value="10">10 mins</option>
                    <option value="15">15 mins</option>
                    <option value="30">30 mins</option>
                    <option value="45">45 mins</option>
                    <option value="60">1 hour</option>
                    <option value="90">1.5 hours</option>
                    <option value="120">2 hours</option>
                    <option value="custom">Custom&hellip;</option>
                  </Select>
                </Label>
                {errors.duration && <FormError>{errors.duration.message}</FormError>}
              </div>
            )}
            {duration === 'custom' && (
              <div>
                <div className={themeClasses({ display: 'grid', gridTemplateColumns: 2, gap: 4 })}>
                  <Label>
                    <LabelText>Duration</LabelText>
                    <Input
                      {...register('durationAmount', {
                        shouldUnregister: true,
                        required: true,
                        validate: {
                          min: (value) => {
                            const num = Number(value);
                            return getValues('durationUnit') === 'hour' && num < 1
                              ? 'Must be at least 1 hour'
                              : getValues('durationUnit') === 'minute' && num < 10
                              ? 'Must be at least 10 minutes'
                              : true;
                          },
                          max: (value) => {
                            const num = Number(value);
                            return getValues('durationUnit') === 'hour' && num > 12
                              ? 'Must be at most 12 hours'
                              : getValues('durationUnit') === 'minute' && num > 720
                              ? 'Must be at most 720 minutes'
                              : true;
                          },
                          int: (value) => {
                            const num = Number(value);
                            return !Number.isInteger(num) ? 'Must be an integer' : true;
                          },
                        },
                      })}
                      hasError={!!errors.durationAmount}
                      type="number"
                      min={durationUnit === 'hour' ? 1 : 10}
                      max={durationUnit === 'hour' ? 12 : 720}
                      step={durationUnit === 'hour' ? 1 : 5}
                    />
                  </Label>
                  <Label>
                    <LabelText></LabelText>
                    <Select
                      {...register('durationUnit', { required: true, shouldUnregister: true })}
                      hasError={!!errors.durationUnit}
                    >
                      <option value="minute">minutes</option>
                      <option value="hour">hours</option>
                    </Select>
                  </Label>
                </div>
                {errors.durationAmount && <FormError>{errors.durationAmount.message}</FormError>}
              </div>
            )}
            <div>
              <Label>
                <LabelText>Price</LabelText>
                <PriceInput
                  {...register('price', priceValidationRules)}
                  hasError={!!errors.price}
                  currency={currency}
                />
              </Label>
              {errors.price && <FormError>{errors.price.message}</FormError>}
            </div>
          </div>
        </FormGroup>
        <FormGroup style={{ ...(!withAvailability && { display: 'none' }) }}>
          <Label>
            <LabelText>Availability</LabelText>
            <Select {...register('availabilityId', { required: true })} hasError={!!errors.availabilityId}>
              {availabilities.map((availability) => (
                <option key={availability.id} value={availability.id}>
                  {availability.name}
                </option>
              ))}
            </Select>
          </Label>
          {errors.availabilityId && <FormError>{errors.availabilityId.message}</FormError>}
          <FormDescription>
            {availabilities.length === 1 ? (
              <>
                This is your default availability. You can edit it and add additional availabilities in the scheduling
                settings
              </>
            ) : (
              <>
                You can edit and add additional availabilities in the{' '}
                <LinkStyled to="/dashboard/scheduling/services" target="_blank">
                  scheduling settings
                </LinkStyled>
              </>
            )}
          </FormDescription>
        </FormGroup>
        <FormGroup style={{ ...(!withLocation && { display: 'none' }) }}>
          <Label>
            <LabelText>Location</LabelText>
            <LocationField
              initialValue={initialValues ? initialValues.locationId : null}
              control={control}
              name="locationId"
              disabled={submitting}
            />
          </Label>
          {errors.locationId && <FormError>{errors.locationId.message}</FormError>}
          <FormDescription>
            Where the session will be held. You can edit and add additional locations in the{' '}
            <LinkStyled to="/dashboard/calls/locations" target="_blank">
              calls settings
            </LinkStyled>
          </FormDescription>
        </FormGroup>
        <FormGroup>
          <Label>
            <LabelText>Payment processing</LabelText>
            <PaymentAccountField
              initialValue={initialValues ? initialValues.paymentAccountId : null}
              control={control}
              name="paymentAccountId"
              disabled={submitting}
            />
          </Label>
          {errors.paymentAccountId && <FormError>{errors.paymentAccountId.message}</FormError>}
          <FormDescription>
            How the payments should be processed. You can add payment accounts in the{' '}
            <LinkStyled to="/dashboard/payments/processing/accounts" target="_blank">
              payments settings
            </LinkStyled>
          </FormDescription>
        </FormGroup>
        <FormGroup style={{ ...(!withWorkflows && { display: 'none' }) }}>
          <Label>
            <LabelText>Workflows</LabelText>
            <WorkflowsField
              initialValue={initialValues ? initialValues.workflowsIds : null}
              control={control}
              name="workflowsIds"
              workflowType="session"
              disabled={submitting}
            />
          </Label>
          <FormDescription>Use workflows to automate repeating tasks, like reminders and follow-ups</FormDescription>
        </FormGroup>
        <FormGroup>
          <Checkbox {...register('hidden')}>
            Hide on the booking page and only show to clients with a direct link
          </Checkbox>
        </FormGroup>
        {phoneNumberCollectionEnabled && (
          <FormGroup>
            <Checkbox {...register('collectPhoneNumber')}>
              Collect client&apos;s phone number{' '}
              <Flare variant="warning">
                <FontAwesomeIcon icon={faFlask} fixedWidth /> Experimental
              </Flare>
            </Checkbox>
          </FormGroup>
        )}
        <FormGroup>
          <Checkbox {...register('overrideSchedulingRules')} disabled={!schedulingRulesEnabled}>
            Override default scheduling rules for this service
            {!schedulingRulesEnabled && (
              <LinkUnstyled to="/dashboard/account/billing" target="_blank" className={themeClasses({ marginLeft: 2 })}>
                <Flare variant="warning">
                  <FontAwesomeIcon icon={faStar} fixedWidth /> Growth
                </Flare>
              </LinkUnstyled>
            )}
          </Checkbox>
        </FormGroup>
        {overrideSchedulingRules && (
          <SchedulingRulesFormCommon
            form={form as unknown as UseFormReturn<SchedulingRulesFormValues>}
            global={false}
          />
        )}
      </fieldset>
      <FormGroup className={themeClasses({ display: 'flex', justifyContent: 'space-between' })}>
        <Button primary type="submit" disabled={submitting}>
          {submitting && <Spinner />}
          <span>Save</span>
        </Button>
        {(onDelete || onClone) && (
          <div className={themeClasses({ display: 'flex', gap: 4 })}>
            {onClone && (
              <InlineButton onClick={onClone} disabled={submitting}>
                Clone
              </InlineButton>
            )}
            {onDelete && (
              <InlineButton onClick={onDelete} disabled={submitting}>
                Delete
              </InlineButton>
            )}
          </div>
        )}
      </FormGroup>
    </form>
  );
};

export default ServiceForm;
