import { LexicalEditor } from 'lexical';
import { useFormContext } from 'react-hook-form';

import React, { useMemo } from 'react';
import { Controller } from 'react-hook-form';
import {
  FormDescription,
  FormError,
  FormFootnote,
  FormGroup,
  Label,
  LabelText,
  TextArea,
} from 'web/components/elements';
import FormValueLength from './FormValueLength';
import RichTextEditor from '../lexical/RichTextEditor';
import { fromPlainText, fromPortableText, toPortableText } from '../lexical/serializers';
import {
  isStringifiedPortableText,
  parsePortableText,
  portableTextToPlainText,
  stringifyPortableText,
} from '../portable-text/utils';

const descriptionLengthMax = 2000;

const initRichTextEditorState = (text: string) => {
  if (isStringifiedPortableText(text)) {
    const blocks = parsePortableText(text);
    fromPortableText(blocks);
  } else {
    fromPlainText(text);
  }
};

const initPlainTextEditorState = (text: string): string => {
  if (isStringifiedPortableText(text)) {
    const blocks = parsePortableText(text);
    return portableTextToPlainText(blocks);
  } else {
    return text;
  }
};

const DescriptionFieldPlain = ({
  initialStringifiedValue,
  fieldDescription,
  fieldPlaceholder,
}: {
  initialStringifiedValue: string;
  fieldDescription?: string;
  fieldPlaceholder?: string;
}) => {
  const initialValueMemo = useMemo(() => initPlainTextEditorState(initialStringifiedValue), [initialStringifiedValue]);
  const {
    control,
    register,
    formState: { errors },
  } = useFormContext<{ description: string }>();
  return (
    <FormGroup>
      <Label>
        <LabelText>Description (optional)</LabelText>
        <TextArea
          {...register('description', {
            maxLength: {
              value: descriptionLengthMax,
              message: `Too long, please limit the name to ${descriptionLengthMax} characters`,
            },
          })}
          placeholder={fieldPlaceholder}
          defaultValue={initialValueMemo}
          rows={12}
          hasError={!!errors.description}
        />
      </Label>
      <FormFootnote>
        <FormValueLength control={control} name="description" maxLength={descriptionLengthMax} />
      </FormFootnote>
      {fieldDescription && <FormDescription>{fieldDescription}</FormDescription>}
      {errors.description && <FormError>{errors.description.message}</FormError>}
    </FormGroup>
  );
};

const DescriptionFieldRich = ({
  initialStringifiedValue,
  fieldDescription,
  fieldPlaceholder,
  disabled,
}: {
  initialStringifiedValue: string;
  fieldDescription?: string;
  fieldPlaceholder?: string;
  disabled?: boolean;
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext<{ descriptionRich: null }>();
  return (
    <FormGroup>
      <Controller
        control={control}
        render={({ field: { onChange } }) => (
          <RichTextEditor
            initialState={() => initRichTextEditorState(initialStringifiedValue)}
            onInit={onChange}
            label="Description (optional)"
            placeholder={fieldPlaceholder}
            rows={12}
            disabled={disabled}
          />
        )}
        defaultValue={null}
        rules={{
          required: 'Description cannot be undefined',
        }}
        name="descriptionRich"
      />
      {fieldDescription && <FormDescription>{fieldDescription}</FormDescription>}
      {errors.descriptionRich && <FormError>{errors.descriptionRich.message}</FormError>}
    </FormGroup>
  );
};

const DesriptionField = ({
  richTextDescriptionEnabled,
  initialStringifiedValue,
  fieldDescription,
  fieldPlaceholder,
  disabled,
}: {
  richTextDescriptionEnabled: boolean;
  initialStringifiedValue: string;
  fieldDescription?: string;
  fieldPlaceholder?: string;
  disabled?: boolean;
}) =>
  richTextDescriptionEnabled ? (
    <DescriptionFieldRich
      initialStringifiedValue={initialStringifiedValue}
      fieldDescription={fieldDescription}
      fieldPlaceholder={fieldPlaceholder}
      disabled={disabled}
    />
  ) : (
    <DescriptionFieldPlain
      initialStringifiedValue={initialStringifiedValue}
      fieldDescription={fieldDescription}
      fieldPlaceholder={fieldPlaceholder}
    />
  );

const stringifyDescription = (descriptionPlain: string, descriptionRich: LexicalEditor | null) =>
  descriptionRich ? stringifyPortableText(toPortableText(descriptionRich.getEditorState())) : descriptionPlain;

export { stringifyDescription };
export default DesriptionField;
