const oembedEndpointsJson = {
  cloudapp: 'https://oembed.getcloudapp.com/oembed?url=',
  youtube: 'https://www.youtube.com/oembed?url=',
  vimeo: 'https://vimeo.com/api/oembed.json?url=',
  sendspark: 'https://apiv2.sendspark.com/api/v1/campaigns/oembed?url=',
  loom: 'https://www.loom.com/v1/oembed?url=',
  wistia: 'https://fast.wistia.com/oembed?url=',
};

type OembedProvider = keyof typeof oembedEndpointsJson;

type OembedDataCommon = {
  version: string;
  title?: string;
  author_name?: string;
  author_url?: string;
  provider_name?: string;
  provider_url?: string;
  cache_age?: string;
  thumbnail_url?: string;
  thumbnail_width?: number;
  thumbnail_height?: number;
};

type OembedDataPhoto = OembedDataCommon & {
  type: 'photo';
  url: string;
  width: number;
  height: number;
  html?: string;
};

type OembedDataVideo = OembedDataCommon & {
  type: 'video';
  html: string;
  width: number;
  height: number;
};

type OembedDataLink = OembedDataCommon & {
  type: 'link';
};

type OembedDataRich = OembedDataCommon & {
  type: 'rich';
  html: string;
  width: number;
  height: number;
};

type OembedData = OembedDataPhoto | OembedDataVideo | OembedDataLink | OembedDataRich;

type OembedProviderData = {
  cloudapp: OembedDataVideo;
  youtube: OembedDataVideo;
  vimeo: OembedDataVideo;
  sendspark: OembedDataVideo;
  loom: OembedDataVideo | OembedDataPhoto;
  wistia: OembedDataVideo;
};

const fetchOembed = async <T extends OembedProvider>(url: string, provider: T) => {
  const endpoint = oembedEndpointsJson[provider];
  const encodedUrl = encodeURIComponent(url);
  const res = (await (await fetch(`${endpoint}${encodedUrl}`)).json()) as OembedProviderData[T];
  return res;
};

export { fetchOembed, OembedData, OembedDataPhoto, OembedDataVideo, OembedDataLink, OembedDataRich, OembedProvider };
