import React, { useLayoutEffect, useMemo, useRef, useState } from 'react';
import { Input, InputPrefix } from 'web/components/elements';
import { formatCurrencySymbol } from 'web/utils/currency';

const currencyGap = 24;

const PriceInput = React.forwardRef<
  HTMLInputElement,
  React.InputHTMLAttributes<HTMLInputElement> & {
    hasError?: boolean;
    currency: introwise.Currency;
  }
>(({ onChange, currency, ...otherProps }, ref) => {
  const currencySymbol = useMemo(() => formatCurrencySymbol(currency), [currency]);
  const [currencyPadding, setCurrencyPadding] = useState(currencySymbol.length * 10 + currencyGap);
  const prefixRef = useRef<HTMLElement>();
  useLayoutEffect(() => {
    setCurrencyPadding(prefixRef.current.clientWidth + currencyGap);
  }, [currency]);

  return (
    <>
      <InputPrefix ref={prefixRef}>{currencySymbol}</InputPrefix>
      <Input
        ref={ref}
        type="text"
        inputMode="numeric"
        style={{ paddingLeft: currencyPadding }}
        {...otherProps}
        onChange={(event) => {
          // Trim after 2 decimal points
          const value = event.target.value;
          const numValue = parseFloat(value);
          if (!isNaN(numValue)) {
            event.target.value =
              value.indexOf('.') >= 0
                ? value.substr(0, value.indexOf('.')) + value.substr(value.indexOf('.'), 3)
                : value;
          }
          if (onChange) {
            onChange(event);
          }
        }}
      />
    </>
  );
});

PriceInput.displayName = 'PriceInput';

export default PriceInput;
