import { collection, doc, orderBy, query, where } from 'firebase/firestore';
import { useContext, useMemo } from 'react';
import { DateRange } from 'web/components/calendar/common/types';
import useFirestore from 'web/components/FirebaseContext/useFirestore';
import UserContext from 'web/components/UserContext';
import { firestoreAvailabilityPeriodConverter, firestoreAvailabilityRuleConverter } from 'web/utils/convert';
import useErrorHandler from './useErrorHandler';
import useFirestoreCollectionData from './useFirestoreCollectionData';
import useFirestoreDocumentData from './useFirestoreDocumentData';

const useAvailability = ({ dateRange, availabilityId }: { dateRange: DateRange; availabilityId: string }) => {
  const { user } = useContext(UserContext);
  const firestore = useFirestore();

  const [rules, rulesLoading, rulesError] = useFirestoreDocumentData(
    doc(firestore, 'users', user.uid, 'cronofy', availabilityId, 'rules', 'default').withConverter(
      firestoreAvailabilityRuleConverter,
    ),
  );
  const [periods, periodsLoading, periodsError] = useFirestoreCollectionData(
    query(
      collection(firestore, 'users', user.uid, 'cronofy', availabilityId, 'periods').withConverter(
        firestoreAvailabilityPeriodConverter,
      ),
      where('end', '>', dateRange.start),
      where('end', '<', new Date(dateRange.end.getTime() + 86400000)),
      orderBy('end', 'asc'),
    ),
  );
  useErrorHandler(rulesError);
  useErrorHandler(periodsError);

  const loading = rulesLoading || periodsLoading;

  return useMemo(() => ({ rules, periods, loading }), [rules, periods, loading]);
};

export default useAvailability;
