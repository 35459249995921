import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { DndProvider, DropTargetMonitor, useDrag, useDrop, XYCoord } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { InlineButton } from 'web/components/elements';
import { ServiceItemBlock, ServicesList } from './Services/ServicesCommon';

type DragItem = {
  index: number;
  id: string;
  type: string;
};
const ReorderingListDragItem = ({
  index,
  length,
  moveItem,
  disabled,
  children,
}: {
  index: number;
  length: number;
  moveItem: (fromIndex: number, toIndex: number) => void;
  disabled: boolean;
  children: React.ReactNode;
}) => {
  const ref = useRef<HTMLLIElement>(null);
  const [{ handlerId }, drop] = useDrop({
    accept: 'CARD',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor: DropTargetMonitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      moveItem(dragIndex, hoverIndex);

      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: 'CARD',
    item: () => {
      return { index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    canDrag: () => !disabled,
  });

  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  return (
    <li style={{ opacity }} ref={ref} data-handler-id={handlerId}>
      <ServiceItemBlock>
        <InlineButton disabled={isDragging || disabled || index === 0} onClick={() => moveItem(index, index - 1)}>
          <FontAwesomeIcon icon={faArrowUp} fixedWidth />
        </InlineButton>
        <InlineButton
          disabled={isDragging || disabled || index >= length - 1}
          style={{ marginLeft: 8 }}
          onClick={() => moveItem(index, index + 1)}
        >
          <FontAwesomeIcon icon={faArrowDown} fixedWidth />
        </InlineButton>
        <span style={{ marginLeft: 12, cursor: disabled ? 'no-drop' : 'move' }}>{children}</span>
      </ServiceItemBlock>
    </li>
  );
};
type OrderableItem = {
  id: string;
  order: number;
  hidden?: boolean;
};
export const ReorderingList = <T extends OrderableItem>({
  items,
  onItemsChange,
  itemRender,
  disabled,
}: {
  items: T[];
  onItemsChange: (newItems: T[]) => void;
  itemRender: (item: T) => React.ReactNode;
  disabled?: boolean;
}) => {
  const moveService = useCallback(
    (fromIndex: number, toIndex: number) => {
      if (Math.abs(fromIndex - toIndex) === 1) {
        const tmp = items[fromIndex].order;
        items[fromIndex].order = items[toIndex].order;
        items[toIndex].order = tmp;
      } else {
        const newOrder =
          toIndex === 0
            ? items[0].order - 1024
            : toIndex === items.length - 1
            ? items[items.length - 1].order + 1024
            : (items[toIndex - 1].order + items[toIndex].order) / 2.0;
        items[fromIndex].order = newOrder;
      }
      onItemsChange([...items].sort((a, b) => a.order - b.order));
    },
    [onItemsChange, items],
  );

  return (
    <DndProvider backend={HTML5Backend}>
      <ServicesList>
        {items.map((item, idx) => (
          <ReorderingListDragItem
            index={idx}
            length={items.length}
            moveItem={moveService}
            key={item.id}
            disabled={disabled}
          >
            {itemRender(item)}
          </ReorderingListDragItem>
        ))}
      </ServicesList>
    </DndProvider>
  );
};
export const useOrderableItems = <T extends OrderableItem>(initialItems: T[]) => {
  const [initItemsOrder] = useState(() =>
    initialItems
      ? initialItems.reduce((acc, item) => ({ ...acc, [item.id]: item.order }), {} as { [serviceId: string]: number })
      : {},
  );

  const [itemsSorted, setItemsSorted] = useState(() =>
    initialItems ? initialItems.filter((item) => !item.hidden).sort((a, b) => a.order - b.order) : [],
  );

  return useMemo(
    () => [itemsSorted, setItemsSorted, initItemsOrder] as const,
    [itemsSorted, setItemsSorted, initItemsOrder],
  );
};
