import React from 'react';
import styled from 'styled-components';
import themeClasses from 'web/styles/themeClasses.css';
import themeConstants from 'web/styles/themeConstants';
import themeVars from 'web/styles/themeVars.css';

const IntegrationTitle = styled.div`
  ${themeConstants.typography.h3}
`;

const IntegrationBlock = styled.div`
  display: grid;
  row-gap: 16px;
  ${themeConstants.media.sm} {
    column-gap: 20px;
    grid-template-columns: 1fr auto;
  }
`;

const IntegrationBlockTitle = styled.div`
  display: grid;
  column-gap: 20px;
  grid-template-columns: auto 1fr;
`;

const IntegrationInlineMark = styled.div`
  font-weight: bold;
  color: ${themeVars.color.halfMuted};
`;

const IntegrationIcon = ({ children }: { children: React.ReactNode }) => (
  <div
    className={themeClasses({ display: 'flex', justifyContent: 'center', alignItems: 'center' })}
    style={{ width: 60, height: 60 }}
  >
    {children}
  </div>
);

const ConnectButtonsGroup = styled.div`
  ${themeConstants.media.sm} {
    text-align: center;
  }
`;

export {
  IntegrationTitle,
  IntegrationBlock,
  IntegrationBlockTitle,
  IntegrationInlineMark,
  IntegrationIcon,
  ConnectButtonsGroup,
};
