import React, { useState } from 'react';
import { Button, Input } from 'web/components/elements';
import Spinner from 'web/components/Spinner';

const promoCodeRegExp = /^[A-Z0-9]{0,20}$/;

const PromoCodeForm = ({
  defaultValue,
  onSubmit,
  onChange,
  disabled,
  submitting,
}: {
  defaultValue: string;
  onSubmit: (code: string) => void;
  onChange: () => void;
  disabled: boolean;
  submitting: boolean;
}) => {
  const [value, setValue] = useState(defaultValue || '');
  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        onSubmit(value);
      }}
    >
      <div style={{ display: 'flex' }}>
        <Input
          name="promoCode"
          placeholder="Add promotion code"
          value={value}
          onChange={(event) => {
            const normalizedValue = event.target.value.trim().toUpperCase();
            if (promoCodeRegExp.exec(normalizedValue)) {
              setValue(normalizedValue);
              onChange();
            }
          }}
          disabled={disabled || submitting}
        />
        <Button secondary sm type="submit" style={{ flex: 'none', marginLeft: 12 }} disabled={disabled || submitting}>
          {submitting && <Spinner />}
          <span>Apply</span>
        </Button>
      </div>
    </form>
  );
};

export default PromoCodeForm;
