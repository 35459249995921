import { useElements, useStripe } from '@stripe/react-stripe-js';
import type { PaymentIntent, StripeError } from '@stripe/stripe-js';
import { useMachine } from '@xstate/react';
import { useContext, useEffect } from 'react';
import useFunctions from 'web/components/FirebaseContext/useFunctions';
import UserContext from 'web/components/UserContext';
import useErrorReporter from 'web/hooks/useErrorReporter';
import useTracking from 'web/components/TrackingContext/useTracking';
import {
  pricingPlansSubscription,
  SubscriptionPricingPlanId,
  SubscriptionPricingPriceId,
} from 'web/utils/pricingPlans';
import {
  confirmCardPayment,
  createPaymentMethod,
  createSubscription,
  retrieveSubscription,
  updateSubscriptionPayment,
  verifyPromotionCode,
} from './subscriptionActions';
import { SubcriptionContext, subscriptionCreateMachine, subscriptionUpdateMachine } from './subscriptionMachine';

const useSubscriptionMachine = (
  subscriptionMachine: typeof subscriptionCreateMachine | typeof subscriptionUpdateMachine,
  initialContext?: {
    planId?: SubscriptionPricingPlanId;
    stripeSubscriptionId?: string;
    priceId?: SubscriptionPricingPriceId;
    trialPeriodDays?: number;
  },
) => {
  const functions = useFunctions();
  const tracking = useTracking();
  const { userData } = useContext(UserContext);
  const stripe = useStripe();
  const elements = useElements();
  const errorReporter = useErrorReporter();

  const useMachineRes = useMachine(subscriptionMachine, {
    context: initialContext,
    actions: {
      retrieve: (ctx: SubcriptionContext) => retrieve(ctx.stripeSubscriptionId),
      create: (ctx: SubcriptionContext) =>
        create({
          planId: ctx.planId,
          priceId: ctx.priceId,
          stripePromotionCodeId: ctx.promoCodeResult?.id,
          trialPeriodDays: ctx.trialPeriodDays,
        }),
      update: (ctx: SubcriptionContext) => update(ctx.stripeSubscriptionId),
      confirmCard: (ctx: SubcriptionContext) => confirmCard(ctx.stripePaymentIntent),
      reportError: (ctx: SubcriptionContext) => errorReporter.report(ctx.error),
      promoCodeVerify: (ctx: SubcriptionContext) => verifyPromoCode(ctx.promoCode),
      promoCodeApply: (ctx: SubcriptionContext) => applyPromoCode(ctx.promoCodeResult, ctx.planId, ctx.priceId),
      track: (ctx: SubcriptionContext) => track(ctx.priceId, ctx.promoCode, !!ctx.trialPeriodDays),
    },
  });

  const [, send] = useMachineRes;

  useEffect(() => {
    if (elements && stripe) {
      send({ type: 'READY' });
    }
  }, [stripe, elements, send]);

  useEffect(() => {
    if (userData.stripeSubscription?.status === 'active' || userData.stripeSubscription?.status === 'trialing') {
      send('ACTIVATED');
    }
  }, [send, userData.stripeSubscription?.status]);

  const handlePaymentIntentResult = (paymentIntent: PaymentIntent | null, subscriptionId: string) => {
    // Payment intent may be null if the subcription total payment is zero
    const paymentStatus = paymentIntent?.status;
    if (paymentStatus === 'requires_payment_method') {
      // Card declined
      send({
        type: 'PAYMENT_FAILED',
        stripeSubscriptionId: subscriptionId,
        message: 'Your card was declined. Please try another one.',
      });
    } else if (paymentStatus === 'requires_action') {
      // Card authentication, 3D secure
      send({
        type: 'REQUIRES_CONFIRMATION',
        stripeSubscriptionId: subscriptionId,
        stripePaymentIntent: paymentIntent,
      });
    } else {
      // Wait for subscription activation
      send('PAYMENT_SUCCESS');
    }
  };

  const handleConfirmResult = (confirmResult: { paymentIntent?: PaymentIntent; error?: StripeError }) => {
    if (confirmResult.error) {
      send({ type: 'PAYMENT_FAILED', message: confirmResult.error.message });
    } else if (confirmResult.paymentIntent.status !== 'succeeded') {
      send({ type: 'PAYMENT_FAILED' });
    } else {
      send('PAYMENT_SUCCESS');
    }
  };

  const handlePromotionCodeError = (promotionCodeError: string) => {
    const errMessagePrefix = 'Failed to create a subscription: ';
    let message = promotionCodeError.startsWith(errMessagePrefix)
      ? promotionCodeError.substring(errMessagePrefix.length)
      : promotionCodeError;

    // Try to shorten error messages for the most common errors
    if (message.includes('promotion code has expired')) {
      message = 'Promotion code has expired';
    }
    if (message.includes('customer has prior transactions')) {
      message = 'Promotion code is valid for first-time customers only';
    }
    if (message.includes('customer has prior transactions')) {
      message = 'Promotion code cannot be used with this subscription';
    }

    send({ type: 'PROMO_CODE_FAILED', message });
  };

  const confirmCard = async (paymentIntent: PaymentIntent) => {
    try {
      const confirmResult = await confirmCardPayment(paymentIntent, stripe);
      handleConfirmResult(confirmResult);
    } catch (err) {
      send({ type: 'ERROR', message: `${err}` });
    }
  };

  const retrieve = async (stripeSubscriptionId: string) => {
    try {
      const { subscription, paymentIntent } = await retrieveSubscription(stripeSubscriptionId, functions);
      handlePaymentIntentResult(paymentIntent, subscription.id);
    } catch (err) {
      send({ type: 'ERROR', message: `${err}` });
    }
  };

  const create = async ({
    planId,
    priceId,
    stripePromotionCodeId,
    trialPeriodDays,
  }: {
    planId: SubscriptionPricingPlanId;
    priceId: SubscriptionPricingPriceId;
    stripePromotionCodeId?: string;
    trialPeriodDays?: number;
  }) => {
    try {
      const stripePaymentMethodId = await createPaymentMethod(stripe, elements);
      const { subscription, paymentIntent } = await createSubscription({
        planId,
        priceId,
        stripePaymentMethodId,
        ...(trialPeriodDays && { trialPeriodDays }),
        ...(stripePromotionCodeId && { stripePromotionCodeId }),
        functions,
      });
      handlePaymentIntentResult(paymentIntent, subscription.id);
    } catch (err) {
      if (typeof err.message === 'string' && (err.message as string).includes('promotion code')) {
        // Stripe doesn't signal expired coupons and promo codes with some
        // distinct error code, so we need to check the error message here
        handlePromotionCodeError(err.message);
      } else {
        send({ type: 'ERROR', message: `${err}` });
      }
    }
  };

  const update = async (stripeSubscriptionId: string) => {
    try {
      const stripePaymentMethodId = await createPaymentMethod(stripe, elements);
      const { subscription, paymentIntent } = await updateSubscriptionPayment(
        stripeSubscriptionId,
        stripePaymentMethodId,
        functions,
      );
      handlePaymentIntentResult(paymentIntent, subscription.id);
    } catch (err) {
      send({ type: 'ERROR', message: `${err}` });
    }
  };

  const verifyPromoCode = async (code: string) => {
    try {
      const { promotionCodeError, promotionCode } = await verifyPromotionCode(code, functions);
      if (promotionCodeError) {
        send({ type: 'PROMO_CODE_FAILED', message: promotionCodeError });
      } else {
        send({ type: 'PROMO_CODE_VERIFIED', promoCodeResult: promotionCode });
      }
    } catch (err) {
      send({ type: 'PROMO_CODE_FAILED', message: 'Something went wrong. Please try again' });
    }
  };

  const applyPromoCode = async (
    promoCodeResult: SubcriptionContext['promoCodeResult'],
    planId: SubscriptionPricingPlanId,
    priceId: SubscriptionPricingPriceId,
  ) => {
    if (
      promoCodeResult.billingRestriction &&
      pricingPlansSubscription[planId].prices[priceId].billing !== promoCodeResult.billingRestriction
    ) {
      send({
        type: 'PROMO_CODE_FAILED',
        message: `This promotion code can only be used with ${
          promoCodeResult.billingRestriction === 'annual' ? 'an' : 'a'
        } ${promoCodeResult.billingRestriction} billing.`,
      });
    } else {
      send({ type: 'PROMO_CODE_SUCCESS' });
    }
  };

  const track = async (priceId: SubscriptionPricingPriceId, promoCode?: string, trial?: boolean) => {
    tracking.trackEvent('Subscription Started', {
      price_id: priceId,
      ...(trial && { trial: true }),
      ...(promoCode && { promo_code: promoCode }),
    });
  };

  return useMachineRes;
};

export default useSubscriptionMachine;
