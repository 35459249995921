import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { LexicalEditor } from 'lexical';
import { nanoid } from 'nanoid/non-secure';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { FormError, FormGroup } from 'web/components/elements';
import useStorage from 'web/components/FirebaseContext/useStorage';
import {
  acceptedFileTypes,
  assertFileValid,
  fitToSize,
  getKey,
  makeLqip,
} from 'web/components/form-fields/PictureUpload/helpers';
import Spinner from 'web/components/Spinner';
import UserContext from 'web/components/UserContext';
import useErrorStateHandler from 'web/hooks/useErrorStateHandler';
import themeClasses from 'web/styles/themeClasses.css';
import themeVars from 'web/styles/themeVars.css';
import errorReporter from 'web/utils/error-reporter';
import { ImageCommandPayload, INSERT_IMAGE_COMMAND } from '../ImagePlugin';

const accept = acceptedFileTypes.join(', ');

const InsertImageDialog = ({ activeEditor, onClose }: { activeEditor: LexicalEditor; onClose: () => void }) => {
  const [error, setError] = useErrorStateHandler();
  const [uploading, setUploading] = useState(false);
  const [payload, setPayload] = useState<ImageCommandPayload | null>(null);
  const storage = useStorage();
  const {
    user: { uid },
  } = useContext(UserContext);

  const onDrop = async (files: File[], rejectedFiles: File[]) => {
    if (files && files.length > 0) {
      try {
        assertFileValid(files[0]);
      } catch (err) {
        errorReporter.report(new Error(`Invalid image: ${err}`));
        // TODO: make the error message more human-friendly
        setError(new Error(`Invalid image: ${err}`));
        return;
      }

      const input = files[0];

      try {
        setUploading(true);

        const blob = await fitToSize(input, { width: 1400, height: 1400 });
        const image = await createImageBitmap(blob);
        const { width, height } = image;
        const aspectRatio = width / height;
        const lqip = await makeLqip(image);
        const metadata = { lqip, dimensions: { width, height, aspectRatio } };
        const customPrefix = 'content-';
        const rndId = nanoid();
        const key = getKey(uid, customPrefix, rndId, blob);
        const imageRef = ref(storage, key);
        await uploadBytes(imageRef, blob);
        const url = await getDownloadURL(imageRef);

        setPayload({
          url,
          metadata,
          alt: '',
          caption: '',
        });
      } catch (err) {
        errorReporter.report(err);
        setError(err);
        setUploading(false);
      }
    } else if (rejectedFiles && rejectedFiles.length > 0) {
      setError(new Error(`Cannot use this image. Please choose a different one.`));
      setUploading(false);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop, accept, multiple: false, disabled: uploading });
  const dispatchedRef = useRef(false);

  useEffect(() => {
    if (payload && !dispatchedRef.current) {
      activeEditor.dispatchCommand(INSERT_IMAGE_COMMAND, payload);
      dispatchedRef.current = true;
      onClose();
    }
  }, [payload, activeEditor, onClose]);

  return (
    <>
      <h4 className={themeClasses({ marginY: 0 })}>Insert image</h4>
      <FormGroup>
        <div {...getRootProps()}>
          <input {...getInputProps()} />
          <div
            className={themeClasses({
              paddingX: 8,
              paddingY: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
              borderRadius: 'sm',
              cursor: 'pointer',
            })}
            style={{
              border: `1px dashed ${themeVars.color.accentOutline}`,
            }}
          >
            {uploading ? (
              <div>
                Uploading... <Spinner />
              </div>
            ) : (
              <>
                <div>Drop an image here or click to choose a file</div>
                <div className={themeClasses({ marginTop: 2 })}>
                  <small>Recommended image width ~720 pixels</small>
                </div>
              </>
            )}
          </div>
        </div>
      </FormGroup>
      {error && (
        <FormGroup>
          <FormError>{`${error}`}</FormError>
        </FormGroup>
      )}
    </>
  );
};

export default InsertImageDialog;
