import React from 'react';
import { useContext, useMemo } from 'react';
import StyledReactSelect from './StyledReactSelect';
import UserContext from '../UserContext';
import { Control, Controller, FieldPath, FieldPathValue } from 'react-hook-form';
import useFirestoreCollectionData from 'web/hooks/useFirestoreCollectionData';
import { firestoreLocationConverter } from 'web/utils/convert';
import useFirestore from '../FirebaseContext/useFirestore';
import { collection } from 'firebase/firestore';
import useErrorHandler from 'web/hooks/useErrorHandler';

const LocationField = <TFieldValues,>({
  control,
  name,
  initialValue,
  disabled,
}: {
  control: Control<TFieldValues>;
  name: FieldPath<TFieldValues>;
  initialValue?: FieldPathValue<TFieldValues, FieldPath<TFieldValues>> | null;
  disabled?: boolean;
}) => {
  const { user, userData } = useContext(UserContext);
  const firestore = useFirestore();
  const [locations, loading, error] = useFirestoreCollectionData(
    collection(firestore, 'users', user.uid, 'locations').withConverter(firestoreLocationConverter),
  );
  useErrorHandler(error);

  const defaultLocation = (userData.settings?.conferencing?.provider || 'daily') as FieldPathValue<
    TFieldValues,
    FieldPath<TFieldValues>
  >;

  const hasZoom = useMemo(
    () =>
      !!userData.connectedAccounts &&
      Object.values(userData.connectedAccounts).some(
        (account) => account.provider === 'zoom' && account.type === 'conferencing',
      ),
    [userData.connectedAccounts],
  );

  const options = useMemo(
    () => [
      { value: 'daily', label: 'Introwise Call' },
      ...(hasZoom ? [{ value: 'zoom', label: 'Zoom' }] : []),
      ...(locations || []).map((location) => ({ value: location.id, label: location.name })),
    ],
    [hasZoom, locations],
  );

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={initialValue || defaultLocation}
      render={({ field: { onChange, onBlur, value } }) => (
        <StyledReactSelect
          options={options}
          onChange={(option: { value: string }) => onChange(option.value)}
          onBlur={onBlur}
          value={options.find((o) => o.value === value)}
          isDisabled={disabled}
          isLoading={loading}
        />
      )}
    />
  );
};

export default LocationField;
