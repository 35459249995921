import {
  collection,
  deleteField,
  doc,
  getDocsFromServer,
  query,
  serverTimestamp,
  where,
  writeBatch,
} from 'firebase/firestore';
import React, { useContext, useState } from 'react';
import BookingPageContext from 'web/components/BookingPageContext';
import { FormError } from 'web/components/elements';
import useFirestore from 'web/components/FirebaseContext/useFirestore';
import Spinner from 'web/components/Spinner';
import UserContext from 'web/components/UserContext';
import useErrorHandler from 'web/hooks/useErrorHandler';
import useErrorStateHandler from 'web/hooks/useErrorStateHandler';
import useFeatureIncludedCheck from 'web/hooks/useFeatureIncludedCheck';
import useFeatureOverrideCheck from 'web/hooks/useFeatureOverrideCheck';
import { firestorePackageConverter } from 'web/utils/convert';
import useTracking from '../TrackingContext/useTracking';
import ServiceForm, { FormValues } from './ServiceForm';

const ServiceEdit = ({
  onSuccess,
  onClone,
  serviceId,
  withWorkflows,
  withLocation,
  withAvailability,
}: {
  onSuccess: () => void;
  onClone?: (service: introwise.Service) => void;
  serviceId: string;
  withWorkflows?: boolean;
  withLocation?: boolean;
  withAvailability?: boolean;
}) => {
  const { userData } = useContext(UserContext);
  const firestore = useFirestore();
  const tracking = useTracking();
  const [page, pageLoading, pageError] = useContext(BookingPageContext);
  useErrorHandler(pageError);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useErrorStateHandler();
  const increasedPriceLimit = useFeatureOverrideCheck('increasedPriceLimit');
  const schedulingRulesEnabled = useFeatureIncludedCheck('multipleSchedulingRules');
  const phoneNumberCollectionEnabled = useFeatureOverrideCheck('phoneNumberCollection');

  const availabilities = userData.availabilities
    ? Object.values(userData.availabilities).sort((a, b) => a.order - b.order)
    : undefined;

  const { bookingPageId } = userData;

  const service = page?.services[serviceId];

  const edit = async (values: FormValues) => {
    const newService: introwise.FirestoreWriteData<introwise.Service> = {
      ...values,
      createdAt: serverTimestamp(),
      order: service.order,
    };

    const update: introwise.FirestoreUpdateData<introwise.Page> = {
      [`services.${serviceId}`]: {
        ...newService,
        id: serviceId,
      },
    };

    const pageRef = doc(firestore, 'pages', bookingPageId);
    const serviceRef = doc(pageRef, 'services', serviceId);
    const batch = writeBatch(firestore);
    batch.update(pageRef, update);
    batch.update(serviceRef, newService);

    setSubmitting(true);
    try {
      await batch.commit();
      tracking.trackEvent('Service Updated');
      onSuccess();
    } catch (err) {
      setError(err);
      setSubmitting(false);
    }
  };

  const remove = async () => {
    const update: introwise.FirestoreUpdateData<introwise.Page> = {
      [`services.${serviceId}`]: deleteField(),
    };

    const pageRef = doc(firestore, 'pages', bookingPageId);
    const serviceRef = doc(pageRef, 'services', serviceId);
    const batch = writeBatch(firestore);
    batch.update(pageRef, update);
    batch.delete(serviceRef);

    setSubmitting(true);
    try {
      const packagesQuery = query(
        collection(pageRef, 'packages'),
        where(`personalSessions.${serviceId}`, '!=', null),
      ).withConverter(firestorePackageConverter);
      const existingPackages = await getDocsFromServer(packagesQuery);
      if (!existingPackages.empty) {
        const res = window.confirm(
          `This service is included in the following packages:\n  ${existingPackages.docs
            .map((packDoc) => packDoc.data().title)
            .join(
              ', ',
            )}\nClients who already bought a package will loose access to this service. Do you want to continue?`,
        );
        if (!res) {
          setSubmitting(false);
          return;
        }
      }
      batch.commit();
      tracking.trackEvent('Service Removed');
      onSuccess();
    } catch (err) {
      setError(err);
      setSubmitting(false);
    }
  };

  const clone = onClone && (() => onClone(service));

  if (pageError) {
    return (
      <>
        <p>Failed to load the booking page. Please refresh the page and try again.</p>
      </>
    );
  }

  return (
    <>
      {!page && pageLoading && <Spinner />}
      {!page && !pageLoading && <p>Something went wrong. Please try again later.</p>}
      {page && (
        <>
          <ServiceForm
            currency={page.currency}
            initialValues={service}
            defaultSchedulingRules={userData.settings?.schedulingRules}
            schedulingRulesEnabled={schedulingRulesEnabled}
            onSubmit={edit}
            onDelete={remove}
            onClone={clone}
            submitting={submitting}
            increasedPriceLimit={increasedPriceLimit}
            availabilities={availabilities}
            withWorkflows={withWorkflows}
            withLocation={withLocation}
            withAvailability={withAvailability}
            phoneNumberCollectionEnabled={phoneNumberCollectionEnabled}
          />
          {error && <FormError>Failed to save the changes. Please try again later.</FormError>}
        </>
      )}
    </>
  );
};

export default ServiceEdit;
