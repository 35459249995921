import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import type { LexicalCommand } from 'lexical';
import {
  $createParagraphNode,
  $getSelection,
  $isRangeSelection,
  COMMAND_PRIORITY_EDITOR,
  createCommand,
} from 'lexical';
import React, { useEffect } from 'react';
import { $createImageNode, ImageMetadata, ImageNode } from '../nodes/ImageNode';

export type ImageCommandPayload = { url: string; metadata: ImageMetadata; alt: string; caption: string };

export const INSERT_IMAGE_COMMAND: LexicalCommand<ImageCommandPayload> = createCommand();

const ImagePlugin = () => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    if (!editor.hasNodes([ImageNode])) {
      throw new Error('ImagePlugin: ImageNode not registered on editor');
    }

    return editor.registerCommand<ImageCommandPayload>(
      INSERT_IMAGE_COMMAND,
      (payload) => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
          const focusNode = selection.focus.getNode();

          if (focusNode !== null) {
            const imageNode = $createImageNode(payload.url, payload.metadata, payload.alt, payload.caption);
            selection.focus.getNode().getTopLevelElementOrThrow().insertAfter(imageNode);
            const paragraphNode = $createParagraphNode();
            imageNode.insertAfter(paragraphNode);
            paragraphNode.select();
          }
        }

        return true;
      },
      COMMAND_PRIORITY_EDITOR,
    );
  }, [editor]);

  return <></>;
};

export default ImagePlugin;
