import React, { useState } from 'react';
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form';
import Spinner from 'web/components//Spinner';
import {
  Button,
  FormDescription,
  FormError,
  FormFootnote,
  FormGroup,
  InlineButton,
  Input,
  Label,
  LabelText,
} from 'web/components/elements';
import DesriptionField, { stringifyDescription } from 'web/components/form-fields/DescriptionField';
import FormValueLength from 'web/components/form-fields/FormValueLength';
import PictureUpload from 'web/components/form-fields/PictureUpload';
import BookingPagePreviewModal from './BookingPagePreviewModal';

const photoValidationRules: RegisterOptions = {
  required: 'Required',
};

const maxHeadlineLength = 80;

const headlineValidationRules: RegisterOptions = {
  maxLength: {
    value: maxHeadlineLength,
    message: `Too long, please limit the headline to ${maxHeadlineLength} characters`,
  },
  setValueAs: (v) => v.trim(),
};

const backgroundSize = {
  width: 1200,
  height: 330,
};

type ExpertCommonFormValues = Pick<introwise.Page, 'slug' | 'headline' | 'description' | 'cover'> & {
  descriptionRich: null;
};

const BookingPageFormCommon = ({
  page,
  children,
  onSubmit,
  submitting,
  buttonRigth,
}: {
  page: introwise.Page;
  children?: React.ReactNode;
  onSubmit: (values: ExpertCommonFormValues) => void;
  submitting: boolean;
  buttonRigth?: boolean;
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    formState,
    getValues,
  } = useFormContext<ExpertCommonFormValues>();

  const [previewData, setPreviewData] = useState<introwise.Page>(null);

  const onPreviewOpen = () => {
    const { description: descriptionPlain, descriptionRich, ...values } = getValues();
    const description = stringifyDescription(descriptionPlain, descriptionRich);
    setPreviewData({ ...page, ...values, description });
  };
  const onPreviewClose = () => setPreviewData(null);

  const { isSubmitting } = formState;

  const busy = submitting || isSubmitting;

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <fieldset disabled={busy}>
          <FormGroup>
            <Controller
              name="cover"
              rules={photoValidationRules}
              control={control}
              render={({ field: { value, onChange } }) => (
                <PictureUpload
                  value={{ url: value }}
                  onChange={({ url }) => onChange(url)}
                  customPrefix="bp-"
                  size={backgroundSize}
                />
              )}
            />
            <FormDescription>Personalize the cover image to make your page stand out</FormDescription>
            {errors.cover && <FormError>{errors.cover.message}</FormError>}
          </FormGroup>
          {children}
          <FormGroup>
            <Label>
              <Input
                {...register('headline', headlineValidationRules)}
                placeholder="Experienced coach and educator"
                hasError={!!errors.headline}
              />

              <LabelText>Headline</LabelText>
            </Label>
            <FormFootnote>
              <FormValueLength name="headline" control={control} maxLength={maxHeadlineLength} />
            </FormFootnote>
            <FormDescription>A short sentence about you</FormDescription>
            {errors.headline && <FormError>{errors.headline.message}</FormError>}
          </FormGroup>
          <DesriptionField
            initialStringifiedValue={page?.description || ''}
            richTextDescriptionEnabled={true}
            fieldDescription="Give as much information about yourself as you want"
            fieldPlaceholder={`A little bit about me...`}
            disabled={busy}
          />
        </fieldset>
        <FormGroup
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: buttonRigth ? 'row-reverse' : 'row',
          }}
        >
          <Button primary type="submit" disabled={busy}>
            {busy && <Spinner />}
            <span>Save</span>
          </Button>
          <InlineButton onClick={onPreviewOpen}>Preview</InlineButton>
        </FormGroup>
        {/* TODO: add here 'Check your inputs' message */}
      </form>
      <BookingPagePreviewModal previewData={previewData} onClose={onPreviewClose} />
    </>
  );
};

export default BookingPageFormCommon;
