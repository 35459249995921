import type { EventChangeArg, DateSelectArg, EventClickArg } from '@fullcalendar/react';
import { DateTime } from 'luxon';
import React, { useState } from 'react';
import { DateRange, EventType, ExtendedEventPropType } from 'web/components/calendar/common/types';
import SelectedEventModal from 'web/components/calendar/ExpertAvailabilityCalendar/SelectedEventModal';
import useAvailabilityCalendar from 'web/hooks/useAvailabilityCalendar';
import AvailabilityCalendar from '../common/AvailabilityCalendar';
import timeSlotContent from '../common/timeSlotContent';
import availabilityEventContent from './availabilityEventContent';

const ExpertAvailabilityCalendar = ({
  initialTimezone,
  availabilityId,
}: {
  initialTimezone: string;
  availabilityId?: string;
}) => {
  const [selectedEvent, setSelectedEvent] = useState<{ event: EventType; onCreate: boolean }>();
  const [dateRange, setDateRange] = useState<DateRange>(() => {
    const start = DateTime.now().startOf('week').toJSDate();
    const end = DateTime.now().endOf('week').toJSDate();
    return { start, end };
  });
  const { events, loading, deleteEvent, updateEvent, submitting } = useAvailabilityCalendar({
    dateRange,
    timeZone: initialTimezone,
    availabilityId,
  });

  const onEventSelect = ({ event, jsEvent }: EventClickArg) => {
    jsEvent.preventDefault();
    setSelectedEvent({
      event: events.find((t) => t.id === event.id),
      onCreate: false, // flag for conditionaly change modal window
    });
  };

  const onDateSelect = ({ start, end, jsEvent }: DateSelectArg) => {
    jsEvent.preventDefault();

    // create new event with id: undefined
    setSelectedEvent(() => {
      const recurring = true;
      const ev = {
        id: undefined as string,
        start,
        end,
        title: start.toLocaleTimeString('en'),
        extendedProps: {
          mobileTitle: start.toLocaleTimeString('en'),
          recurring,
        },
      };
      return {
        event: ev,
        onCreate: true,
      };
    });
  };

  const onEventsChanged = ({ event }: EventChangeArg) => {
    const newEvent: EventType = {
      id: event.id,
      start: event.start,
      end: event.end,
      title: event.start.toLocaleTimeString('en'),
      extendedProps: event.extendedProps as ExtendedEventPropType,
    };
    updateEvent(newEvent);
  };

  const onDateRangeChange = (newDateRange: DateRange) => {
    setDateRange((oldDateRange) =>
      oldDateRange.start.getTime() !== newDateRange.start.getTime() &&
      oldDateRange.end.getTime() !== newDateRange.end.getTime()
        ? newDateRange
        : oldDateRange,
    );
  };

  const onModalClose = () => {
    setSelectedEvent(null);
  };

  const onModalDelete = () => {
    deleteEvent(selectedEvent.event);
    onModalClose();
  };

  const onModalSave = (start: Date, end: Date, recurring: boolean) => {
    const { id: selectedEventId, extendedProps } = selectedEvent.event;
    const isRecurrenceChange = extendedProps.recurring !== recurring;
    const id = isRecurrenceChange ? undefined : selectedEventId;
    const newEvent: EventType = {
      id,
      start,
      end,
      title: start.toLocaleTimeString('en'),
      extendedProps: {
        ...extendedProps,
        recurring,
      },
    };
    if (isRecurrenceChange) {
      deleteEvent(selectedEvent.event);
    }
    updateEvent(newEvent);
    onModalClose();
  };

  return (
    <div>
      {/* TODO: debug and re-enable back for prod env */}
      <AvailabilityCalendar
        events={events}
        loading={loading}
        saving={submitting}
        dateRange={dateRange}
        onDateRangeChange={onDateRangeChange}
        onEventSelect={onEventSelect}
        onEventsChanged={onEventsChanged}
        onDateSelect={onDateSelect}
        eventContent={availabilityEventContent}
        slotLabelContent={timeSlotContent}
      />
      {selectedEvent && (
        <SelectedEventModal
          event={selectedEvent.event}
          eventOnCreate={selectedEvent.onCreate}
          onClose={onModalClose}
          isOpen={!!selectedEvent}
          onDelete={onModalDelete}
          onSave={onModalSave}
        />
      )}
    </div>
  );
};

export default ExpertAvailabilityCalendar;
