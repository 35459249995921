import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import themeConstants from 'web/styles/themeConstants';
import themeVars from 'web/styles/themeVars.css';

const marginX = 8;
const marginY = 6;

const buttonsListMixin = css<{ inline?: boolean }>`
  margin: -${marginY}px -${marginX}px;

  > * {
    ${({ inline }) => inline && `display: inline-block;`}
    margin: ${marginY}px ${marginX}px;
  }
`;

const ServicesList = styled.ul<{ inline?: boolean }>`
  padding: 0;
  list-style: none;

  ${buttonsListMixin}
`;

const ServicesButtonsGroup = styled.div`
  ${buttonsListMixin}
  margin-top: 16px;
`;

const serviceItemMixin = css`
  border-radius: ${themeConstants.borderRadius.sm};
  background-color: ${themeVars.backgrounds.sessionSlot};

  padding: 9px 16px;
  display: inline-block;
`;

const ServiceItemLink = styled(Link)`
  ${serviceItemMixin};
  ${themeConstants.mixins.nonTextLink}
`;

const ServiceItemBlock = styled.div`
  ${serviceItemMixin};
`;

const ServicesLoading = styled.div`
  ${themeConstants.fake.loading}
  ${themeConstants.fake.animated}
  padding: 9px 16px;
`;

export { ServiceItemLink, ServiceItemBlock, ServicesList, ServicesLoading, ServicesButtonsGroup };
