import { getDownloadURL, ref, uploadBytesResumable, UploadTaskSnapshot } from 'firebase/storage';
import { nanoid } from 'nanoid';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import 'react-image-crop/dist/ReactCrop.css';
import { FormError } from 'web/components/elements';
import useStorage from 'web/components/FirebaseContext/useStorage';
import Spinner from 'web/components/Spinner';
import UserContext from 'web/components/UserContext';
import useErrorReporter from 'web/hooks/useErrorReporter';
import CropModal from './CropModal';
import DropZone from './DropZone';
import { acceptedFileTypes, assertFileValid, getKey, Size } from './helpers';

const PictureUpload = ({
  value,
  onChange,
  customPrefix,
  size,
  circle,
}: {
  value: { url: string; ref?: string };
  onChange: (value: { url: string; ref: string }) => void;
  customPrefix: string;
  size: Size;
  circle?: boolean;
}) => {
  const [inProgressImage, setInProgressImage] = useState<string>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const closeModal = () => setIsOpen(false);

  const {
    user: { uid },
  } = useContext(UserContext);
  const storage = useStorage();
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0.0);
  const errorReporter = useErrorReporter();
  const [error, setError] = useState<string>();
  const rndId = useMemo(nanoid, []);
  const [file, setFile] = useState<File>();

  const onCrop = useCallback(
    async (blob: Blob, dataUrl: string) => {
      closeModal();
      setError(null);
      setUploading(true);
      setInProgressImage(dataUrl);

      const key = getKey(uid, customPrefix, rndId, blob);
      const storageRef = ref(storage, key);
      const uploadTask = uploadBytesResumable(storageRef, blob, {
        cacheControl: 'max-age=604800, public',
        customMetadata: { uid },
      });

      const uploadProgress = (snapshot: UploadTaskSnapshot) => {
        const currentProgress = Math.floor((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
        setProgress(currentProgress);
      };

      const uploadError = (err: Error & { code: string }) => {
        setUploading(false);
        setInProgressImage(null);
        switch (err.code) {
          case 'storage/unauthorized':
            errorReporter.report(new Error('Failed to upload: Unauthorized'));
            setError('Unauthorized');
            return;
          case 'storage/unauthenticated':
            errorReporter.report(new Error('Failed to upload: Unauthenticated'));
            setError('Please log in first');
            return;
          default:
            errorReporter.report(new Error(`Failed to upload: ${err}`));
            setError('Something went wrong. Please try again later.');
        }
      };

      const uploadComplete = () => {
        void getDownloadURL(storageRef).then((downloadUrl: string) => {
          setUploading(false);
          setInProgressImage(null);
          onChange({ url: downloadUrl, ref: storageRef.toString() });
        });
      };

      uploadTask.on('state_changed', uploadProgress, uploadError, uploadComplete);
    },
    [uid, customPrefix, rndId, storage, errorReporter, onChange],
  );

  const onSelectFile = (files: File[], rejectedFiles: File[]) => {
    if (files && files.length > 0) {
      try {
        assertFileValid(files[0]);
      } catch (err) {
        errorReporter.report(new Error(`Invalid file: ${err}`));
        // TODO: make the error message more human-friendly
        setError(`Invalid file: ${err}`);
        return;
      }

      setFile(files[0]);
      setIsOpen(true);
    } else if (rejectedFiles && rejectedFiles.length > 0) {
      setError(`Cannot use this image. Please choose a different one.`);
    }
  };

  const removePicture = () => {
    // Reset state to default
    setFile(undefined);
    setUploading(false);
    setProgress(0.0);
    setError(null);
    setIsOpen(false);
    onChange({ url: null, ref: undefined });
  };

  const previewUrl = inProgressImage || value.url;

  return (
    <>
      <DropZone
        size={size}
        previewUrl={previewUrl}
        onDrop={onSelectFile}
        onRemove={removePicture}
        accept={acceptedFileTypes.join(', ')}
        circle={circle}
      />
      {uploading && (
        <div style={{ width: 0, height: 0, whiteSpace: 'nowrap' }}>
          <small>
            <Spinner />
            {uploading && <> {progress}%</>}
          </small>
        </div>
      )}
      {error && <FormError>{error}</FormError>}
      <CropModal
        isOpen={isOpen}
        onClose={closeModal}
        onCropDone={onCrop}
        onError={(err) => {
          errorReporter.report(new Error(`Crop failed: ${err}`));
          setError(`Cannot crop the image. Please choose a different one.`);
        }}
        blob={file}
        size={size}
        circle={circle}
      />
    </>
  );
};

export default PictureUpload;
