import { BlockWithAlignableContents } from '@lexical/react/LexicalBlockWithAlignableContents';
import { DecoratorBlockNode, SerializedDecoratorBlockNode } from '@lexical/react/LexicalDecoratorBlockNode';
import { EditorConfig, ElementFormatType, LexicalEditor, LexicalNode, NodeKey } from 'lexical';
import React from 'react';
import { InlineButton } from 'web/components/elements';
import { FullWidthFigure, FullWidthImage } from 'web/components/elements/FullWidthFigure';
import themeClasses from 'web/styles/themeClasses.css';
import CaptionAltEditor from './CaptionAltEditor';

export type ImageMetadata = {
  dimensions: {
    aspectRatio: number;
    height: number;
    width: number;
  };
  lqip: string;
};

function ImageComponent({
  className,
  nodeKey,
  url,
  format,
  alt,
  metadata,
  caption,
  onRemove,
  onCaptionChange,
  onAltChange,
}: {
  className: {
    base: string;
    focus: string;
  };
  nodeKey: NodeKey;
  format: ElementFormatType | null;
  url: string;
  alt: string;
  metadata?: ImageMetadata;
  caption: string;
  onRemove: () => void;
  onCaptionChange: (caption: string) => void;
  onAltChange: (alt: string) => void;
}) {
  return (
    <BlockWithAlignableContents format={format} nodeKey={nodeKey} className={className}>
      <FullWidthFigure>
        <FullWidthImage src={url} alt={alt} width={metadata?.dimensions.width} height={metadata?.dimensions.height} />
        <div className={themeClasses({ display: 'flex', justifyContent: 'flex-end', gap: 4, paddingTop: 1 })}>
          {caption && (
            <>
              <figcaption
                className={themeClasses({
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  flex: 'auto',
                })}
              >
                {caption}
              </figcaption>
            </>
          )}
          <CaptionAltEditor value={caption} onChange={onCaptionChange} kind={'caption'} />
          <CaptionAltEditor value={caption} onChange={onAltChange} kind={'alt'} />
          <InlineButton onClick={onRemove}>Remove</InlineButton>
        </div>
      </FullWidthFigure>
    </BlockWithAlignableContents>
  );
}

type SerializedImageNode = SerializedDecoratorBlockNode & {
  type: 'image';
  url: string;
  metadata: ImageMetadata;
  alt: string;
  caption: string;
};

export class ImageNode extends DecoratorBlockNode {
  __url: string;
  __metadata: ImageMetadata;
  __alt: string;
  __caption: string;

  getUrl(): string {
    const self = this.getLatest();
    return self.__url;
  }

  setUrl(url: string): void {
    const self = this.getWritable();
    self.__url = url;
  }

  getMetadata(): ImageMetadata {
    const self = this.getLatest();
    return self.__metadata;
  }

  setMetadata(metadata: ImageMetadata): void {
    const self = this.getWritable();
    self.__metadata = metadata;
  }

  getAlt(): string {
    const self = this.getLatest();
    return self.__alt;
  }

  setAlt(id: string): void {
    const self = this.getWritable();
    self.__alt = id;
  }

  getCaption(): string {
    const self = this.getLatest();
    return self.__caption;
  }

  setCaption(caption: string): void {
    const self = this.getWritable();
    self.__caption = caption;
  }

  static getType(): 'image' {
    return 'image';
  }

  static clone(node: ImageNode): ImageNode {
    return new ImageNode(node.__url, node.__metadata, node.__alt, node.__caption, node.__format, node.__key);
  }

  constructor(
    url: string,
    metadata: ImageMetadata,
    alt: string,
    caption: string,
    format?: ElementFormatType | null,
    key?: NodeKey,
  ) {
    super(format, key);
    this.__url = url;
    this.__metadata = metadata;
    this.__alt = alt;
    this.__caption = caption;
  }

  updateDOM(): false {
    return false;
  }

  decorate(editor: LexicalEditor, config: EditorConfig): JSX.Element {
    const className = {
      base: config.theme.embedBlock?.base || '',
      focus: config.theme.embedBlock?.focus || '',
    };
    return (
      <ImageComponent
        nodeKey={this.getKey()}
        format={this.__format}
        url={this.__url}
        alt={this.__alt}
        metadata={this.__oembed}
        className={className}
        caption={this.__caption}
        onRemove={() => editor.update(() => this.remove())}
        onCaptionChange={(caption) => editor.update(() => this.setCaption(caption))}
        onAltChange={(alt) => editor.update(() => this.setAlt(alt))}
      />
    );
  }

  isTopLevel(): true {
    return true;
  }

  isIsolated(): true {
    return true;
  }

  isInline(): false {
    return false;
  }

  exportJSON(): SerializedImageNode {
    return {
      ...super.exportJSON(),
      type: ImageNode.getType(),
      url: this.__url,
      alt: this.__alt,
      metadata: this.__metadata,
      caption: this.__caption,
    };
  }

  static importJSON(json: SerializedImageNode): ImageNode {
    const node = $createImageNode(json.url, json.metadata, json.alt, json.caption);
    node.setFormat(json.format);
    return node;
  }
}

export function $createImageNode(url: string, metadata: ImageMetadata, alt: string, caption: string): ImageNode {
  return new ImageNode(url, metadata, alt, caption);
}

export function $isImageNode(node: ImageNode | LexicalNode | null | undefined): node is ImageNode {
  return node instanceof ImageNode;
}
