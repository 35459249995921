import React from 'react';
import { BookingPageDisplayPreview } from 'web/App/BookingPage/BookingPageDisplay';
import ErrorBoundary from '../ErrorBoundary';
import GenericReactModal from '../GenericReactModal';

const BookingPagePreviewModal = ({ previewData, onClose }: { previewData: introwise.Page; onClose: () => void }) => (
  <GenericReactModal isOpen={!!previewData} onRequestClose={onClose}>
    <div style={{ width: '98vw', maxWidth: '860px', height: '90vh', display: 'grid' }}>
      <div>
        <h3 style={{ marginTop: 0 }}>Booking page preview</h3>
        <p>This is your booking page after the changes</p>
      </div>
      <div
        style={{
          position: 'relative',
          width: 'calc(100% / 0.65)',
          height: 'calc(100% / 0.65)',
          transform: 'scale(0.65)',
          transformOrigin: 'left top',
          overflowY: 'auto',
        }}
      >
        <div
          style={{ pointerEvents: 'none' }}
          onKeyDown={(e) => e.key !== 'Tab' && e.preventDefault()}
          onKeyPress={(e) => e.preventDefault()}
          onKeyUp={(e) => e.preventDefault()}
          onContextMenu={(e) => e.preventDefault()}
        >
          {previewData && (
            // We add an internal error boundary here, so an error in the preview won't crash the whole page
            <ErrorBoundary>
              <BookingPageDisplayPreview page={previewData} />
            </ErrorBoundary>
          )}
        </div>
        <div
          style={{
            position: 'absolute',
            inset: 0,
            zIndex: 400,
          }}
        />
      </div>
    </div>
  </GenericReactModal>
);

export default BookingPagePreviewModal;
