import { $createLinkNode, $isLinkNode } from '@lexical/link';
import { $createListItemNode, $createListNode, $isListItemNode, $isListNode, ListType } from '@lexical/list';
import { $isHeadingNode, $createHeadingNode, $createQuoteNode, $isQuoteNode } from '@lexical/rich-text';
import {
  isPortableTextBlock,
  isPortableTextListItemBlock,
  isPortableTextSpan,
  isPortableTextToolkitList,
  nestLists,
} from '@portabletext/toolkit';
import {
  ArbitraryTypedObject,
  PortableTextBlock,
  PortableTextLink,
  PortableTextMarkDefinition,
  PortableTextSpan,
  TypedObject,
} from '@portabletext/types';
import {
  $createLineBreakNode,
  $createParagraphNode,
  $createTextNode,
  $getRoot,
  $isDecoratorNode,
  $isElementNode,
  $isLineBreakNode,
  $isParagraphNode,
  $isRootNode,
  $isTextNode,
  EditorState,
  LexicalNode,
  TextNode,
} from 'lexical';
import { isPortableTextCloudAppNode, PortableTextCloudAppNode } from '../portable-text/CloudAppSerializer';
import { isPortableTextImageNode, PortableTextImageNode } from '../portable-text/ImageSerializer';
import { isPortableTextSendsparkNode, PortableTextSendsparkNode } from '../portable-text/SendsparkSerializer';
import { isPortableTextVimeoNode, PortableTextVimeoNode } from '../portable-text/VimeoSerializer';
import { isPortableTextYouTubeNode, PortableTextYouTubeNode } from '../portable-text/YouTubeSerializer';
import { $createCloudAppNode, $isCloudAppNode } from './nodes/CloudAppNode';
import { $createImageNode, $isImageNode } from './nodes/ImageNode';
import { $createSendsparkNode, $isSendsparkNode } from './nodes/SendsparkNode';
import { $createVimeoNode, $isVimeoNode } from './nodes/VimeoNode';
import { $createYouTubeNode, $isYouTubeNode } from './nodes/YouTubeNode';

const nodeFormatToMarks = (node: TextNode) => {
  const marks: string[] = [];
  if (node.hasFormat('bold')) {
    marks.push('strong');
  }
  if (node.hasFormat('italic')) {
    marks.push('em');
  }
  if (node.hasFormat('underline')) {
    marks.push('underline');
  }
  if (node.hasFormat('strikethrough')) {
    marks.push('strike-through');
  }
  return marks;
};

const setNodeFormatFromMarks = (node: TextNode, marks: string[]) => {
  marks.forEach((mark) => {
    switch (mark) {
      case 'strong':
        node.hasFormat('bold') || node.toggleFormat('bold');
        break;
      case 'em':
        node.hasFormat('italic') || node.toggleFormat('italic');
        break;
      case 'underline':
        node.hasFormat('underline') || node.toggleFormat('underline');
        break;
      case 'strike-through':
        node.hasFormat('strikethrough') || node.toggleFormat('strikethrough');
        break;
    }
  });
};

const convertLexicalNodeToPortableText = (node: LexicalNode) => {
  let ptNode:
    | PortableTextSpan
    | PortableTextBlock
    | PortableTextCloudAppNode
    | PortableTextYouTubeNode
    | (TypedObject & ArbitraryTypedObject);
  if ($isElementNode(node)) {
    const block: PortableTextBlock | ArbitraryTypedObject = {
      _type: 'block',
      _key: node.getKey(),
      children: [],
    };
    if ($isRootNode(node)) {
      // Do nothing
    } else if ($isParagraphNode(node)) {
      // Do nothing
    } else if ($isHeadingNode(node)) {
      block.style = node.getTag();
    } else if ($isQuoteNode(node)) {
      block.style = 'blockquote';
    } else if ($isLinkNode(node)) {
      block._type = '@link';
      (block as PortableTextToolkitLink).href = node.getURL();
    } else if ($isListNode(node)) {
      block._type = '@list';
    } else if ($isListItemNode(node)) {
      block.listItem = node.getParent().getListType();
      block.level = node.getIndent() + 1;
    } else {
      throw new Error(`Unsupported element node type: ${node.getType()}`);
    }

    block.children = node.getChildren().map(convertLexicalNodeToPortableText);
    ptNode = block;
  } else if ($isTextNode(node) || $isLineBreakNode(node)) {
    const span: PortableTextSpan = {
      _type: 'span',
      _key: node.getKey(),
      text: $isTextNode(node) ? node.getTextContent() : '\n',
      ...($isTextNode(node) ? ((marks) => (marks.length ? { marks } : undefined))(nodeFormatToMarks(node)) : undefined),
    };
    ptNode = span;
  } else if ($isDecoratorNode(node)) {
    if ($isYouTubeNode(node)) {
      const block: PortableTextYouTubeNode = {
        _type: 'youtube',
        _key: node.getKey(),
        id: node.getVideoId(),
        videoId: node.getVideoId(),
        url: node.getUrl(),
        oembed: node.getOembed(),
        caption: node.getCaption(),
      };
      ptNode = block;
    } else if ($isCloudAppNode(node)) {
      const block: PortableTextCloudAppNode = {
        _type: 'cloudapp',
        _key: node.getKey(),
        dropId: node.getDropId(),
        url: node.getUrl(),
        oembed: node.getOembed(),
        caption: node.getCaption(),
      };
      ptNode = block;
    } else if ($isVimeoNode(node)) {
      const block: PortableTextVimeoNode = {
        _type: 'vimeo',
        _key: node.getKey(),
        videoId: node.getVideoId(),
        url: node.getUrl(),
        oembed: node.getOembed(),
        caption: node.getCaption(),
      };
      ptNode = block;
    } else if ($isSendsparkNode(node)) {
      const block: PortableTextSendsparkNode = {
        _type: 'sendspark',
        _key: node.getKey(),
        shareId: node.getShareId(),
        url: node.getUrl(),
        oembed: node.getOembed(),
        caption: node.getCaption(),
      };
      ptNode = block;
    } else if ($isImageNode(node)) {
      const block: PortableTextImageNode = {
        _type: 'image',
        _key: node.getKey(),
        url: node.getUrl(),
        metadata: node.getMetadata(),
        alt: node.getAlt(),
        caption: node.getCaption(),
      };
      ptNode = block;
    } else {
      throw new Error(`Unsupported decorator node type: ${node.getType()}`);
    }
  } else {
    throw new Error(`Unsupported node type: ${node.getType()}`);
  }
  return ptNode;
};

/**
 * Convert nested list nodes to a flat list of blocks at the top level
 */
const hoistLists = (blocks: (PortableTextBlock | PortableTextSpan | (TypedObject & ArbitraryTypedObject))[]) => {
  const root: PortableTextBlock[] = [];

  const hoistListsImpl = (block: PortableTextBlock | (TypedObject & ArbitraryTypedObject), currentRoot: unknown[]) => {
    let nextRoot: unknown[] = currentRoot;
    if (!block.children) {
      currentRoot.push(block);
    } else {
      if (block.listItem) {
        // Hoist list item blocks to the top level
        nextRoot = root;
      }
      if (
        block._type === '@list' ||
        (block.listItem && block.children.length === 1 && block.children[0]._type === '@list')
      ) {
        // List items that are only used for nesting do not produce a portable text block
      } else {
        const newBlock: PortableTextBlock = {
          ...block,
          children: [],
        };
        currentRoot.push(newBlock);
        nextRoot = newBlock.children;
      }
      for (const child of block.children) {
        hoistListsImpl(child, nextRoot);
      }
    }
  };
  for (const block of blocks) {
    hoistListsImpl(block as PortableTextBlock, root);
  }
  return root;
};

type PortableTextToolkitLink = {
  _type: '@link';
  _key: string;
  href: string;
  children: PortableTextSpan[];
};

const isPortableTextToolkitLink = (node: { _type?: string }): node is PortableTextToolkitLink =>
  '_type' in node && node._type === '@link';

/**
 * Convert link nodes to mark definitions on the parent blocks
 */
const hoistLinks = (blocks: PortableTextBlock[]) => {
  const hoistLinksImpl = (block: PortableTextBlock | (TypedObject & ArbitraryTypedObject)) => {
    if (!block.children) {
      return;
    } else {
      for (let i = 0; i < block.children.length; i++) {
        const child = block.children[i];
        if (isPortableTextToolkitLink(child)) {
          const markDef: PortableTextMarkDefinition & PortableTextLink = {
            _key: child._key,
            _type: 'link',
            href: child.href,
          };
          block.markDefs = (block.markDefs || []).concat(markDef);
          const grandChildrenWithMarks = child.children.map((grandChild) =>
            isPortableTextSpan(grandChild)
              ? { ...grandChild, marks: (grandChild.marks || []).concat(markDef._key) }
              : grandChild,
          );
          block.children = block.children
            .slice(0, i)
            .concat(grandChildrenWithMarks)
            .concat(block.children.slice(i + 1));
        }
      }
      for (const child of block.children) {
        hoistLinksImpl(child);
      }
    }
  };
  for (const block of blocks) {
    hoistLinksImpl(block as PortableTextBlock);
  }
  return blocks;
};

const toPortableText = (editorState: EditorState) => {
  let ptBlocks: (PortableTextBlock | PortableTextSpan | (TypedObject & ArbitraryTypedObject))[] = [];
  editorState.read(() => {
    ptBlocks = (convertLexicalNodeToPortableText($getRoot()) as PortableTextBlock).children;
  });
  const ptBlocksListsHoisted = hoistLists(ptBlocks);
  const ptBlocksListsAndLinksHoisted = hoistLinks(ptBlocksListsHoisted);
  return ptBlocksListsAndLinksHoisted;
};

const convertPortableTextToLexicalNode = (
  block: PortableTextSpan | PortableTextBlock | (TypedObject & ArbitraryTypedObject),
) => {
  let node: LexicalNode;
  if ('markDefs' in block && block.markDefs?.length) {
    throw new Error(`Unsupported markDefs`);
  }
  if (isPortableTextToolkitLink(block)) {
    node = $createLinkNode(block.href);
  } else if (isPortableTextYouTubeNode(block)) {
    node = $createYouTubeNode(block.videoId, block.url, block.oembed, block.caption);
  } else if (isPortableTextCloudAppNode(block)) {
    node = $createCloudAppNode(block.dropId, block.url, block.oembed, block.caption);
  } else if (isPortableTextVimeoNode(block)) {
    node = $createVimeoNode(block.videoId, block.url, block.oembed, block.caption);
  } else if (isPortableTextSendsparkNode(block)) {
    node = $createSendsparkNode(block.shareId, block.url, block.oembed, block.caption);
  } else if (isPortableTextImageNode(block)) {
    node = $createImageNode(block.url, block.metadata, block.alt, block.caption);
  } else if (isPortableTextToolkitList(block)) {
    node = $createListNode(block.listItem as ListType);
  } else if (isPortableTextListItemBlock(block)) {
    node = $createListItemNode();
  } else if (block._type === 'block') {
    if (block.style) {
      if (['h2', 'h3'].includes(block.style)) {
        node = $createHeadingNode(block.style);
      } else if (['h1'].includes(block.style)) {
        node = $createHeadingNode('h2');
      } else if (['h4', 'h5', 'h6'].includes(block.style)) {
        node = $createHeadingNode('h3');
      } else if (block.style === 'blockquote') {
        node = $createQuoteNode();
      } else {
        throw new Error(`Unsupported block style: ${block.style}`);
      }
    } else {
      node = $createParagraphNode();
    }
  } else if (isPortableTextSpan(block)) {
    if (block.text === '\n') {
      node = $createLineBreakNode();
    } else {
      node = $createTextNode(block.text);
      if (block.marks?.length) {
        setNodeFormatFromMarks(node as TextNode, block.marks);
      }
    }
  } else if (isPortableTextBlock(block)) {
    throw new Error(`Unsupported block type: ${block._type}`);
  } else {
    throw new Error(`Invalid block: ${JSON.stringify(block)}`);
  }
  if ('children' in block) {
    node.append(...block.children.map(convertPortableTextToLexicalNode));
  }
  return node;
};

const isPortableTextLinkMarkDef = (
  markDef: PortableTextMarkDefinition,
): markDef is PortableTextLink & PortableTextMarkDefinition => markDef._type === 'link';

/**
 * Convert portable text links from marks to nodes
 */
const nestLinks = (blocks: PortableTextBlock[]) => {
  const nestLinksImpl = (block: PortableTextBlock): PortableTextBlock => {
    const newBlock = { ...block };
    if (block.children?.length) {
      if (block.markDefs?.length) {
        for (const markDef of block.markDefs) {
          if (isPortableTextLinkMarkDef(markDef)) {
            let linkNum = 0;
            let linkNode: PortableTextToolkitLink;

            const newChildren: typeof newBlock.children = [];
            for (const child of newBlock.children) {
              if (isPortableTextSpan(child) && child.marks?.includes(markDef._key)) {
                // As long as child has the same link mark, keep adding to the link node
                if (!linkNode) {
                  linkNode = {
                    _type: '@link',
                    _key: `${markDef._key}${linkNum || ''}`,
                    href: markDef.href,
                    children: [],
                  };
                  newChildren.push(linkNode);
                  linkNum++;
                }
                // Remove the link mark from the child and add it to the link node
                linkNode.children.push({ ...child, marks: child.marks.filter((mark) => mark !== markDef._key) });
              } else {
                newChildren.push({ ...child });
                // If the child doesn't have the same link mark, close the link node
                linkNode = undefined;
              }
            }
            newBlock.children = newChildren;

            // Remove link mark definition from block
            newBlock.markDefs = newBlock.markDefs.filter((md) => md._key !== markDef._key);
          }
        }
      }
      newBlock.children = newBlock.children.map(nestLinksImpl);
    }
    return newBlock;
  };
  const nested: PortableTextBlock[] = blocks.map((block) => nestLinksImpl(block));
  return nested;
};

const fromPortableText = (blocks: PortableTextBlock[]) => {
  if (blocks.length) {
    const nestedLists = nestLists(blocks, 'direct');
    const nestedListsAndLinks = nestLinks(nestedLists);
    nestedListsAndLinks.forEach((block) => {
      const node = convertPortableTextToLexicalNode(block);
      $getRoot().append(node);
    });
  } else {
    $getRoot().append($createParagraphNode().append($createTextNode('')));
  }
};

const toPlainText = (editorState: EditorState) => editorState.read(() => $getRoot().getTextContent());

const fromPlainText = (text: string) => {
  const paragraph = $createParagraphNode();
  const lines = text.split('\n');
  lines.forEach((line, index) => {
    const text = $createTextNode(line);
    paragraph.append(text);
    if (index !== lines.length - 1) {
      paragraph.append($createLineBreakNode());
    }
  });
  $getRoot().append(paragraph);
};

export { toPortableText, fromPortableText, toPlainText, fromPlainText };
