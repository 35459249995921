import { CardElement, useElements } from '@stripe/react-stripe-js';
import { StripeCardElement, StripeCardElementChangeEvent } from '@stripe/stripe-js';
import React, { useEffect } from 'react';
import useMountedRef from 'web/hooks/useMountedRef';
import cardElementInput, { cardElementInputVariants } from './cardElementInput.css';

const CardElementInput = ({
  onChange,
  onReady,
  disabled,
  hasErrors,
}: {
  onChange: (event: StripeCardElementChangeEvent) => void;
  onReady?: (element: StripeCardElement) => void;
  disabled?: boolean;
  hasErrors?: boolean;
}) => {
  const elements = useElements();
  const mountedRef = useMountedRef();
  useEffect(() => {
    if (elements && mountedRef.current) {
      const cardElement = elements.getElement(CardElement);
      if (cardElement) {
        cardElement.update({ disabled });
      }
    }
  }, [disabled, elements, mountedRef]);
  return (
    <label>
      <CardElement
        onChange={onChange}
        onReady={onReady}
        className={hasErrors ? cardElementInputVariants['invalid'] : ''}
        options={{
          classes: {
            base: cardElementInput,
            focus: cardElementInputVariants['focus'],
            invalid: cardElementInputVariants['invalid'],
            webkitAutofill: cardElementInputVariants['autofill'],
          },
        }}
      />
    </label>
  );
};

export default CardElementInput;
