import { collection, query, where } from 'firebase/firestore';
import React, { useContext, useMemo } from 'react';
import { Control, Controller, FieldPath, FieldPathValue } from 'react-hook-form';
import useErrorHandler from 'web/hooks/useErrorHandler';
import useFirestoreCollectionData from 'web/hooks/useFirestoreCollectionData';
import { firestoreWorkflowConverter } from 'web/utils/convert';
import Fake from '../elements/Fake';
import useFirestore from '../FirebaseContext/useFirestore';
import StyledReactSelect from './StyledReactSelect';
import UserContext from '../UserContext';

type WorkflowOption = {
  value: string;
  label: string;
};

const workflowsMaxCount = 10;

const WorkflowsField = <TFieldValues,>({
  control,
  name,
  initialValue,
  workflowType,
  disabled,
}: {
  control: Control<TFieldValues>;
  name: FieldPath<TFieldValues>;
  initialValue?: FieldPathValue<TFieldValues, FieldPath<TFieldValues>> | null;
  workflowType: introwise.Workflow['type'];
  disabled?: boolean;
}) => {
  const { userData } = useContext(UserContext);
  const { bookingPageId } = userData;
  const firestore = useFirestore();
  const [workflows, loading, error] = useFirestoreCollectionData(
    query(
      collection(firestore, 'pages', bookingPageId, 'workflows').withConverter(firestoreWorkflowConverter),
      where('type', '==', workflowType),
      where('deleted', '==', false),
    ),
  );
  useErrorHandler(error);

  const workflowsOptions = useMemo(
    () =>
      workflows?.map(
        (w) =>
          ({
            value: w.id,
            label: w.name,
          } as WorkflowOption),
      ),
    [workflows],
  );

  const workflowsOptionsById = useMemo(
    () =>
      workflowsOptions?.reduce((acc, w) => {
        acc[w.value] = w;
        return acc;
      }, {} as Record<string, WorkflowOption>),
    [workflowsOptions],
  );

  const defaultWorkflowsIds = useMemo(
    () => workflows?.filter((w) => w.default).map((w) => w.id) as FieldPathValue<TFieldValues, FieldPath<TFieldValues>>,
    [workflows],
  );

  return (
    <>
      {loading && <Fake height={16} animated />}
      {error && <p>Failed to load workflows</p>}
      {!loading && !error && (
        <Controller
          name={name}
          control={control}
          defaultValue={
            initialValue === null
              ? defaultWorkflowsIds
              : initialValue === undefined
              ? ([] as FieldPathValue<TFieldValues, FieldPath<TFieldValues>>)
              : initialValue
          }
          rules={{
            validate: (value) => {
              if ((value as WorkflowOption[]).length > workflowsMaxCount) {
                return `Please limit the number of workflows to ${workflowsMaxCount}`;
              }
            },
          }}
          render={({ field: { onChange, onBlur, value } }) => (
            <StyledReactSelect
              onChange={(value: { value: string }[]) => {
                onChange(value.map((option) => option.value));
              }}
              onBlur={onBlur}
              value={(value as string[]).map((id) => workflowsOptionsById[id])}
              options={workflowsOptions}
              noOptionsMessage={() => 'No workflows available'}
              isMulti
              isClearable={false}
              styles={{
                multiValue: (base) => ({ ...base, fontSize: 'unset' }),
              }}
              isDisabled={disabled}
            />
          )}
        />
      )}
    </>
  );
};

export default WorkflowsField;
