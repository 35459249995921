import { CardElement, useElements } from '@stripe/react-stripe-js';
import React, { useMemo, useState } from 'react';
import {
  Button,
  FormDescription,
  FormError,
  FormGroup,
  InlineButton,
  RadioButton,
  RadioButtonGroup,
} from 'web/components/elements';
import CardElementInput from 'web/components/form-fields/CardElementInput';
import Spinner from 'web/components/Spinner';
import themeClasses from 'web/styles/themeClasses.css';
import {
  pricingPlansSubscription,
  SubscriptionPricingPlanId,
  SubscriptionPricingPriceId,
} from 'web/utils/pricingPlans';
import PlanChooser from './PlanChooser';
import PromoCodeForm from './PromoCodeForm';
import PromoCodeInfo from './PromoCodeInfo';
import { subscriptionCreateMachine } from './subscriptionMachine';
import useSubscriptionMachine from './useSubscriptionMachine';

const formatPriceAmount = (amount: number) => `$${Math.round((amount / 100 + Number.EPSILON) * 100) / 100}`;
const formatTrialDate = (date: Date) =>
  date.toLocaleDateString('en', { day: 'numeric', month: 'long', year: 'numeric' });

const annualPriceId: { [planId in SubscriptionPricingPlanId]: SubscriptionPricingPriceId } = {
  growth_1_5: 'annual_usd_14400',
  subscription: 'annual_usd_37200',
};

export const monthlyPriceId: { [planId in SubscriptionPricingPlanId]: SubscriptionPricingPriceId } = {
  growth_1_5: 'monthly_usd_1500',
  subscription: 'monthly_usd_3900',
};

const SubscriptionCreateForm = ({
  planId,
  activatedView,
  alreadyActivatedView,
}: {
  planId: SubscriptionPricingPlanId;
  activatedView: React.ReactNode;
  alreadyActivatedView: React.ReactNode;
}) => {
  const planContext = useMemo(() => {
    const newPriceId = monthlyPriceId[planId];
    const newPrice = pricingPlansSubscription[planId].prices[newPriceId];
    const newTrialDays = newPrice?.trialPeriodDays || undefined;
    return { planId, priceId: newPriceId, trialPeriodDays: newTrialDays };
  }, [planId]);
  const [state, send] = useSubscriptionMachine(subscriptionCreateMachine, planContext);
  const elements = useElements();
  const [showPromoCode, setShowPromoCode] = useState(false);

  const error = state.context.error;
  const cardComplete = state.context.cardComplete;

  const ready = !state.matches('initializing');
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const busy = ['creating', 'updating', 'confirming', 'activating'].some(state.matches);
  const buttonDisabled = !ready || busy || !cardComplete;

  if (elements?.getElement(CardElement)) {
    // eslint-disable-next-line @typescript-eslint/unbound-method
    const cardDisabled = ['creating', 'updating', 'confirming'].some(state.matches);
    const cardElement = elements.getElement(CardElement);
    cardElement.update({ disabled: cardDisabled });
  }
  const onCardChange = (event: { complete: boolean }) => send({ type: 'CARD_CHANGED', complete: event.complete });
  const onSubscribeClick = () => send('CREATE_OR_UPDATE');

  const paymentMethodNeedsUpdate = state.matches('readyUpdate');

  const promoCode = state.context.promoCode;
  const promoCodeError = state.context.promoCodeError;
  const promoCodeResult = state.context.promoCodeResult;
  const promoCodeSubmitting =
    // TODO: typescript complains about xstate matching function here
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    state.matches({ readyCreate: 'promoCodeApplying' }) || state.matches({ readyCreate: 'promoCodeVerifying' });
  const promoCodeApplied = !!promoCodeResult;
  const promoCodeSelectionDisabled = busy || paymentMethodNeedsUpdate;
  const onPromoCodeSubmit = (promoCode: string) => send({ type: 'PROMO_CODE_APPLY', code: promoCode });
  const onPromoCodeChange = () => send({ type: 'PROMO_CODE_CHANGED' });
  const onPromoCodeRemove = () => send({ type: 'PROMO_CODE_REMOVE' });

  const priceId = state.context.priceId;
  const onPriceIdSelect = (priceId: SubscriptionPricingPriceId) => {
    const newPrice = pricingPlansSubscription[planId].prices[priceId];
    const newTrialDays = newPrice?.trialPeriodDays || undefined;
    send({ type: 'PRICE_ID_CHANGED', priceId, trialPeriodDays: newTrialDays });
  };
  const priceSelectionDisabled = busy || promoCodeSubmitting || paymentMethodNeedsUpdate;

  const pricingPlan = pricingPlansSubscription[planId];
  const pricingPrice = pricingPlan?.prices[priceId];
  const getAnnualPriceDiff = () => {
    return pricingPlan.prices[monthlyPriceId[planId]].amount * 12 - pricingPlan.prices[annualPriceId[planId]].amount;
  };

  const trialPeriodDays = state.context.trialPeriodDays;
  const trialDateEnd = trialPeriodDays ? new Date(Date.now() + trialPeriodDays * 24 * 60 * 60 * 1000) : undefined;

  const onPlanIdSelect = (planId: SubscriptionPricingPlanId) => {
    const newPriceId = monthlyPriceId[planId];
    const newPrice = pricingPlansSubscription[planId].prices[newPriceId];
    const newTrialDays = newPrice?.trialPeriodDays || undefined;
    send({ type: 'PLAN_ID_CHANGED', planId, priceId: newPriceId, trialPeriodDays: newTrialDays });
  };

  const getPriceWithDiscount = () => {
    const priceWithDiscount = promoCodeResult
      ? Math.max(
          0,
          pricingPrice.amount - (promoCodeResult.amountOff || (promoCodeResult.percentOff * pricingPrice.amount) / 100),
        )
      : pricingPrice.amount;
    return priceWithDiscount;
  };

  const active = state.matches('active');
  const alreadyActive = state.matches('alreadyActive');

  return (
    <>
      {active && <>{activatedView}</>}
      {alreadyActive && <>{alreadyActivatedView}</>}
      {!active && !alreadyActive && (
        <>
          {!planId && <PlanChooser onPlanIdSelect={onPlanIdSelect} />}
          {planId && (
            <div>
              <p className={themeClasses({ marginY: 0 })}>
                You&apos;re about to switch your plan to <b>{pricingPlan.name}</b> with{' '}
                {pricingPrice.billing === 'annual' ? 'an annual' : 'a monthly'} price{' '}
                <b>{formatPriceAmount(pricingPrice.amount)}</b>.
              </p>
              <FormGroup>
                <h4 className={themeClasses({ marginTop: 0 })}>Billing</h4>
                <RadioButtonGroup>
                  <RadioButton
                    sm
                    value={monthlyPriceId[planId]}
                    onSelect={() => onPriceIdSelect(monthlyPriceId[planId])}
                    defaultChecked={priceId === monthlyPriceId[planId]}
                    disabled={priceSelectionDisabled}
                  >
                    Monthly
                  </RadioButton>
                  <RadioButton
                    sm
                    value={annualPriceId[planId]}
                    onSelect={() => onPriceIdSelect(annualPriceId[planId])}
                    defaultChecked={priceId === annualPriceId[planId]}
                    disabled={priceSelectionDisabled}
                  >
                    Annual (save 15%)
                  </RadioButton>
                </RadioButtonGroup>
                {priceId === annualPriceId[planId] && (
                  <FormDescription style={{ marginTop: 8, padding: 0 }}>
                    Awesome! You&apos;re saving {formatPriceAmount(getAnnualPriceDiff())} with an annual billing.
                  </FormDescription>
                )}
              </FormGroup>
              <FormGroup>
                <h4 className={themeClasses({ marginTop: 0 })}>Card information</h4>
                <CardElementInput onChange={onCardChange} />
              </FormGroup>
              <FormGroup>
                {showPromoCode ? (
                  <>
                    <h4>Promotion code</h4>
                    {promoCodeApplied ? (
                      <PromoCodeInfo
                        promoCodeResult={promoCodeResult}
                        subscriptionPrice={pricingPrice.amount}
                        onRemove={onPromoCodeRemove}
                        disabled={promoCodeSelectionDisabled}
                      />
                    ) : (
                      <>
                        <PromoCodeForm
                          defaultValue={promoCode}
                          onSubmit={onPromoCodeSubmit}
                          onChange={onPromoCodeChange}
                          disabled={promoCodeSelectionDisabled}
                          submitting={promoCodeSubmitting}
                        />
                        {promoCodeError && <FormError>{promoCodeError}</FormError>}
                      </>
                    )}
                  </>
                ) : (
                  <InlineButton onClick={() => setShowPromoCode(true)} disabled={promoCodeSelectionDisabled}>
                    Add promotion code
                  </InlineButton>
                )}
              </FormGroup>
              {trialPeriodDays ? (
                <>
                  <FormGroup>
                    Total due today: <b>{formatPriceAmount(0)}</b>
                    <br />
                    <small>
                      First bill on {formatTrialDate(trialDateEnd)}: <b>{formatPriceAmount(getPriceWithDiscount())}</b>
                    </small>
                  </FormGroup>
                </>
              ) : (
                <FormGroup>
                  Total due today: <b>{formatPriceAmount(getPriceWithDiscount())}</b>
                </FormGroup>
              )}
              <FormGroup>
                <Button primary onClick={onSubscribeClick} disabled={buttonDisabled} style={{ width: '100%' }}>
                  {busy && <Spinner />}
                  <span>{trialPeriodDays ? 'Start free trial' : 'Subscribe'}</span>
                </Button>
                <p>
                  <small>
                    {trialPeriodDays && (
                      <>
                        A recurring {pricingPrice.billing} charge of {formatPriceAmount(getPriceWithDiscount())} will
                        automatically apply and start on {formatTrialDate(trialDateEnd)}.
                      </>
                    )}{' '}
                    You may cancel at any time in your payment settings. Clicking &quot;Subscribe&quot; means that you
                    agree to the Introwise{' '}
                    <a href="/terms-of-service" target="_blank" rel="noopener noreferrer">
                      Terms of Service
                    </a>{' '}
                    and authorize Introwise to charge your card in accordance with that agreement.
                  </small>
                </p>
              </FormGroup>
              {error && (
                <FormGroup>
                  <FormError>{`${error}`}</FormError>
                </FormGroup>
              )}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default SubscriptionCreateForm;
