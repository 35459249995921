import range from 'lodash/range';
import React from 'react';
import ColumnContainer from 'web/components/ColumnContainer';
import { Button } from 'web/components/elements';
import Flare from 'web/components/Flare';
import themeClasses from 'web/styles/themeClasses.css';
import {
  growthPricingPlanId,
  paidFeaturesNames,
  pricingPlansSubscription,
  subscriptionPricingPlanId,
  SubscriptionPricingPlanId,
} from 'web/utils/pricingPlans';
import WithHeaderContentColumn from '../WithHeaderContentColumn';
import { monthlyPriceId } from './SubscriptionCreateForm';

const PlanChooser = ({ onPlanIdSelect }: { onPlanIdSelect: (planId: SubscriptionPricingPlanId) => void }) => {
  const growthPlan = pricingPlansSubscription[growthPricingPlanId];
  const proPlan = pricingPlansSubscription[subscriptionPricingPlanId];

  const growthPlanFeatures = Object.keys(pricingPlansSubscription.growth.features);
  const proPlanFeatures = Object.keys(pricingPlansSubscription.subscription.features);
  const proPlanUniqueFeatures = proPlanFeatures.filter((feature) => !growthPlanFeatures.includes(feature));

  return (
    <ColumnContainer equal>
      <WithHeaderContentColumn header="Choose your new plan" whiteBackground>
        <h2 style={{ marginTop: 0 }}>
          Growth
          {growthPlan.prices[monthlyPriceId['growth_1_5']].extendedTrial && (
            <Flare
              variant="success"
              style={{ fontSize: '1rem', verticalAlign: 'middle' }}
              className={themeClasses({ marginX: 2 })}
            >
              Extended trial
            </Flare>
          )}
        </h2>
        <div>
          Just <b>${growthPlan.prices[monthlyPriceId['growth_1_5']].amount / 100}</b> USD/month
        </div>
        <div>
          <b>{growthPlan.applicationFeePercentage * 100}%</b> commission
        </div>

        <p>
          All <b>Free</b> features plus:
        </p>
        <ul>
          {growthPlanFeatures.map((feature) => (
            <li key={feature}>{paidFeaturesNames[feature as keyof introwise.PaidFeaturesFlags]}</li>
          ))}
          {range(0, Math.max(proPlanUniqueFeatures.length - growthPlanFeatures.length, 0)).map((idx) => (
            <li key={idx} style={{ listStyle: 'none' }}>
              &nbsp;
            </li>
          ))}
        </ul>
        <Button primary style={{ width: '100%' }} onClick={() => onPlanIdSelect('growth_1_5')}>
          Choose Growth
        </Button>
      </WithHeaderContentColumn>

      <WithHeaderContentColumn header="" whiteBackground>
        <h2 style={{ marginTop: 0 }}>Pro</h2>
        <div>
          Everything included for <b>${proPlan.prices[monthlyPriceId['subscription']].amount / 100}</b> USD/month
        </div>
        <div>
          <b>{proPlan.applicationFeePercentage * 100}%</b> commission
        </div>
        <p>
          All <b>Growth</b> features plus:
        </p>
        <ul>
          {proPlanUniqueFeatures.map((feature) => (
            <li key={feature}>{paidFeaturesNames[feature as keyof introwise.PaidFeaturesFlags]}</li>
          ))}
          {range(0, Math.max(growthPlanFeatures.length - proPlanUniqueFeatures.length, 0)).map((idx) => (
            <li key={idx} style={{ listStyle: 'none' }}>
              &nbsp;
            </li>
          ))}
        </ul>
        <Button primary style={{ width: '100%' }} onClick={() => onPlanIdSelect('subscription')}>
          Choose Pro
        </Button>
      </WithHeaderContentColumn>
    </ColumnContainer>
  );
};

export default PlanChooser;
