import React from 'react';
import styled from 'styled-components';
import type { EventContentArg, EventApi } from '@fullcalendar/react';
import { formatSessionStartEndTime } from 'web/utils/dateFormat';
import themeConstants from 'web/styles/themeConstants';
import themeVars from 'web/styles/themeVars.css';

const Container = styled.div<{ recurring: boolean; title: string; along?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
  transition: ${themeConstants.transition.button};
  box-shadow: none;
  position: relative;

  color: ${themeVars.button.color.primary};
  background: ${({ recurring }) =>
    recurring ? themeVars.calendar.recurringSlotBackground : themeVars.calendar.onetimeSlotBackground};
  border: 2px solid
    ${({ recurring }) =>
      recurring ? themeVars.calendar.recurringSlotBackground : themeVars.calendar.onetimeSlotBackground};

  &:focus {
    background: ${({ recurring }) =>
      recurring ? themeVars.calendar.recurringSlotBackground : themeVars.calendar.onetimeSlotBackground};
    border: 2px solid #ffffff;
    color: ${themeVars.button.color.primary};
  }

  &:hover {
    background: ${themeVars.button.background.primaryHover};
    border: 2px solid ${themeVars.button.borderColor.primaryHover};

    :before {
      content: ${({ title }) => `'${title}'`};
      ${themeConstants.typography.regular};
      width: 100px;
      font-size: 12px;
      text-align: center;
      position: absolute;
      background-color: #777;
      color: #fff;
      border-radius: 2px;
      top: -56px;
      left: ${({ along }) => (along ? '-38px' : '-8px')};
      right: ${({ along }) => (along ? '-38px' : '-8px')};
      padding: 4px 6px;
    }
    :after {
      content: '';
      position: absolute;
      top: -10px;
      left: calc(50% - 10px);
      width: 0;
      height: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-top: 8px solid #777;
    }
  }

  &:active,
  &:active:focus,
  &:active:hover {
    background: ${({ recurring }) =>
      recurring ? themeVars.calendar.recurringSlotBackground : themeVars.calendar.onetimeSlotBackground};
    border: 2px solid
      ${({ recurring }) =>
        recurring ? themeVars.calendar.recurringSlotBackground : themeVars.calendar.onetimeSlotBackground};
    color: ${themeVars.button.color.primaryActive};
  }
`;

const generateTitle = (event: EventApi) => {
  const time = formatSessionStartEndTime(event.start, event.end);
  const info = event.extendedProps.recurring ? 'recurr.' : 'one time';
  return `${time} ${info}`;
};

const availabilityEventContent = (eventInfo: EventContentArg) => (
  <Container
    title={generateTitle(eventInfo.event)}
    recurring={eventInfo.event.extendedProps.recurring}
    along={eventInfo.event.extendedProps.along}
  />
);

export default availabilityEventContent;
