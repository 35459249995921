import { addDoc, collection, deleteDoc, doc, updateDoc } from 'firebase/firestore';
import { useCallback, useContext, useMemo, useState } from 'react';
import useFirestore from 'web/components/FirebaseContext/useFirestore';
import useTracking from 'web/components/TrackingContext/useTracking';
import UserContext from 'web/components/UserContext';
import useErrorReporter from './useErrorReporter';

const useAvailabilityMutations = (availabilityId: string) => {
  const { user } = useContext(UserContext);
  const firestore = useFirestore();
  const tracking = useTracking();
  const [submitting, setSubmitting] = useState(false);
  const errorReporter = useErrorReporter();

  const rulesRef = doc(firestore, 'users', user.uid, 'cronofy', availabilityId, 'rules', 'default');
  const periodsRef = collection(firestore, 'users', user.uid, 'cronofy', availabilityId, 'periods');

  const saveRules = useCallback(
    async (rules: Omit<introwise.AvailabilityRules, 'id'>) => {
      setSubmitting(true);
      try {
        const updateData: introwise.FirestoreUpdateData<introwise.AvailabilityRules> = {
          timeZone: rules.timeZone,
          weeklyPeriods: rules.weeklyPeriods,
        };
        await updateDoc(rulesRef, updateData);
        tracking.trackEvent('Availability Time Updated', { recurring: true });
      } catch (error) {
        errorReporter.report(error);
      }
      setSubmitting(false);
    },
    [errorReporter, rulesRef, tracking],
  );

  const savePeriod = useCallback(
    async (periodId: string | undefined, period: Omit<introwise.AvailabilityPeriod, 'id'>) => {
      setSubmitting(true);
      try {
        const updateData: introwise.FirestoreUpdateData<introwise.AvailabilityPeriod> = {
          start: period.start,
          end: period.end,
        };
        if (periodId) {
          await updateDoc(doc(periodsRef, periodId), updateData);
        } else {
          await addDoc(periodsRef, updateData);
        }
        tracking.trackEvent('Availability Time Updated', { recurring: false });
      } catch (err) {
        errorReporter.report(err);
      }
      setSubmitting(false);
    },
    [errorReporter, periodsRef, tracking],
  );

  const deletePeriod = useCallback(
    async (periodId: string) => {
      setSubmitting(true);
      try {
        await deleteDoc(doc(periodsRef, periodId));
        tracking.trackEvent('Availability Time Updated', { recurring: false, deleted: true });
      } catch (err) {
        errorReporter.report(err);
      }
      setSubmitting(false);
    },
    [errorReporter, periodsRef, tracking],
  );

  return useMemo(
    () => ({ saveRules, savePeriod, deletePeriod, submitting }),
    [saveRules, savePeriod, deletePeriod, submitting],
  );
};

export default useAvailabilityMutations;
