import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import type { LexicalCommand } from 'lexical';
import {
  $createParagraphNode,
  $getSelection,
  $isRangeSelection,
  COMMAND_PRIORITY_EDITOR,
  createCommand,
} from 'lexical';
import React, { useEffect } from 'react';
import { OembedDataVideo } from 'web/utils/oembed';
import { $createCloudAppNode, CloudAppNode } from '../nodes/CloudAppNode';

export type CloudAppCommandPayload = { dropId: string; url: string; oembed: OembedDataVideo; caption: string };

export const INSERT_CLOUDAPP_COMMAND: LexicalCommand<CloudAppCommandPayload> = createCommand();

const CloudAppPlugin = () => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    if (!editor.hasNodes([CloudAppNode])) {
      throw new Error('CloudAppPlugin: CloudAppNode not registered on editor');
    }

    return editor.registerCommand<CloudAppCommandPayload>(
      INSERT_CLOUDAPP_COMMAND,
      (payload) => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
          const focusNode = selection.focus.getNode();

          if (focusNode !== null) {
            const cloudAppNode = $createCloudAppNode(payload.dropId, payload.url, payload.oembed, payload.caption);
            selection.focus.getNode().getTopLevelElementOrThrow().insertAfter(cloudAppNode);
            const paragraphNode = $createParagraphNode();
            cloudAppNode.insertAfter(paragraphNode);
            paragraphNode.select();
          }
        }

        return true;
      },
      COMMAND_PRIORITY_EDITOR,
    );
  }, [editor]);

  return <></>;
};

export default CloudAppPlugin;
