import {
  faAlignCenter,
  faAlignJustify,
  faAlignLeft,
  faAlignRight,
  faBold,
  faItalic,
  faLink,
  faRedoAlt,
  faStrikethrough,
  faUnderline,
  faUndoAlt,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { $isLinkNode, TOGGLE_LINK_COMMAND } from '@lexical/link';
import { $isListNode, ListNode } from '@lexical/list';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $isHeadingNode } from '@lexical/rich-text';
import { $isParentElementRTL } from '@lexical/selection';
import { $getNearestNodeOfType, mergeRegister } from '@lexical/utils';
import {
  $getSelection,
  $isRangeSelection,
  CAN_REDO_COMMAND,
  CAN_UNDO_COMMAND,
  FORMAT_ELEMENT_COMMAND,
  FORMAT_TEXT_COMMAND,
  REDO_COMMAND,
  SELECTION_CHANGE_COMMAND,
  UNDO_COMMAND,
} from 'lexical';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import themeClasses from 'web/styles/themeClasses.css';
import BlockOptionsToolbar from './BlockOptionsToolbar';
import FloatingLinkEditor from './FloatingLinkEditor';
import InsertImageToolbar from './InsertImageToolbar';
import InsertVideoToolbar from './InsertVideoToolbar';
import { toolbar, toolbarDivider, toolbarItemButton, toolbarItemButtonRecipe, toolbarItemFormat } from './toolbar.css';
import { getSelectedNode, LowPriority, SupportedBlockType } from './utils';

function Divider() {
  return <div className={toolbarDivider} />;
}

const ToolbarPlugin = ({
  withAlignment,
  withUndo,
  withAdditionalFormats,
}: {
  withAlignment?: boolean;
  withUndo?: boolean;
  withAdditionalFormats?: boolean;
}) => {
  const [editor] = useLexicalComposerContext();
  const toolbarRef = useRef(null);
  const [canUndo, setCanUndo] = useState(false);
  const [canRedo, setCanRedo] = useState(false);
  const [blockType, setBlockType] = useState<SupportedBlockType>('paragraph');
  const [, setIsRTL] = useState(false);
  const [isLink, setIsLink] = useState(false);
  const [isLinkEditMode, setIsLinkEditMode] = useState(false);
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [isUnderline, setIsUnderline] = useState(false);
  const [isStrikethrough, setIsStrikethrough] = useState(false);

  const updateToolbar = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode();
      const element = anchorNode.getKey() === 'root' ? anchorNode : anchorNode.getTopLevelElementOrThrow();
      const elementKey = element.getKey();
      const elementDOM = editor.getElementByKey(elementKey);
      if (elementDOM !== null) {
        if ($isListNode(element)) {
          const parentList = $getNearestNodeOfType(anchorNode, ListNode);
          const type = parentList ? (parentList as ListNode).getTag() : element.getTag();
          setBlockType(type);
        } else {
          const type = $isHeadingNode(element) ? element.getTag() : element.getType();
          setBlockType(type as SupportedBlockType);
        }
      }
      // Update text format
      setIsBold(selection.hasFormat('bold'));
      setIsItalic(selection.hasFormat('italic'));
      setIsUnderline(selection.hasFormat('underline'));
      setIsStrikethrough(selection.hasFormat('strikethrough'));
      setIsRTL($isParentElementRTL(selection));

      // Update links
      const node = getSelectedNode(selection);
      const parent = node.getParent();
      if ($isLinkNode(parent) || $isLinkNode(node)) {
        setIsLink(true);
      } else {
        setIsLink(false);
        setIsLinkEditMode(false);
      }
    }
  }, [editor]);

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateToolbar();
        });
      }),
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        () => {
          updateToolbar();
          return false;
        },
        LowPriority,
      ),
      editor.registerCommand(
        CAN_UNDO_COMMAND,
        (payload: boolean) => {
          setCanUndo(payload);
          return false;
        },
        LowPriority,
      ),
      editor.registerCommand(
        CAN_REDO_COMMAND,
        (payload: boolean) => {
          setCanRedo(payload);
          return false;
        },
        LowPriority,
      ),
    );
  }, [editor, updateToolbar]);

  const insertLink = useCallback(() => {
    if (!isLink) {
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, '');
      setIsLinkEditMode(true);
    } else {
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
    }
  }, [editor, isLink]);

  return (
    <div className={toolbar} ref={toolbarRef}>
      <>
        <button
          onClick={() => {
            editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold');
          }}
          className={toolbarItemButtonRecipe({ spaced: true, active: isBold })}
          aria-label="Format Bold"
          type="button"
        >
          <FontAwesomeIcon className={toolbarItemFormat} icon={faBold} fixedWidth />
        </button>
        {withAdditionalFormats && (
          <button
            onClick={() => {
              editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'italic');
            }}
            className={toolbarItemButtonRecipe({ spaced: true, active: isItalic })}
            aria-label="Format Italics"
            type="button"
          >
            <FontAwesomeIcon className={toolbarItemFormat} icon={faItalic} fixedWidth />
          </button>
        )}
        <button
          onClick={() => {
            editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'underline');
          }}
          className={toolbarItemButtonRecipe({ spaced: true, active: isUnderline })}
          aria-label="Format Underline"
          type="button"
        >
          <FontAwesomeIcon className={toolbarItemFormat} icon={faUnderline} fixedWidth />
        </button>
        {withAdditionalFormats && (
          <button
            onClick={() => {
              editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'strikethrough');
            }}
            className={toolbarItemButtonRecipe({ spaced: true, active: isStrikethrough })}
            aria-label="Format Strikethrough"
            type="button"
          >
            <FontAwesomeIcon className={toolbarItemFormat} icon={faStrikethrough} fixedWidth />
          </button>
        )}
        <Divider />
        <BlockOptionsToolbar editor={editor} blockType={blockType} />
        <Divider />
        {withAlignment && (
          <>
            <button
              onClick={() => {
                editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'left');
              }}
              className={toolbarItemButtonRecipe({ spaced: true })}
              aria-label="Left Align"
              type="button"
            >
              <FontAwesomeIcon className={toolbarItemFormat} icon={faAlignLeft} fixedWidth />
            </button>
            <button
              onClick={() => {
                editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'center');
              }}
              className={toolbarItemButtonRecipe({ spaced: true })}
              aria-label="Center Align"
              type="button"
            >
              <FontAwesomeIcon className={toolbarItemFormat} icon={faAlignCenter} fixedWidth />
            </button>
            <button
              onClick={() => {
                editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'right');
              }}
              className={toolbarItemButtonRecipe({ spaced: true })}
              aria-label="Right Align"
              type="button"
            >
              <FontAwesomeIcon className={toolbarItemFormat} icon={faAlignRight} fixedWidth />
            </button>
            <button
              onClick={() => {
                editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'justify');
              }}
              className={toolbarItemButtonRecipe({})}
              aria-label="Justify Align"
              type="button"
            >
              <FontAwesomeIcon className={toolbarItemFormat} icon={faAlignJustify} fixedWidth />
            </button>
            <Divider />
          </>
        )}
        <button
          onClick={insertLink}
          className={toolbarItemButtonRecipe({ spaced: true, active: isLink })}
          aria-label="Insert Link"
          type="button"
        >
          <FontAwesomeIcon className={toolbarItemFormat} icon={faLink} fixedWidth />
        </button>
        {isLink &&
          createPortal(<FloatingLinkEditor editor={editor} isInitialEditMode={isLinkEditMode} />, document.body)}
        <InsertImageToolbar editor={editor} />
        <InsertVideoToolbar editor={editor} />
        {withUndo && (
          <>
            <div className={themeClasses({ flex: 'auto' })} />
            <button
              disabled={!canUndo}
              onClick={() => {
                editor.dispatchCommand(UNDO_COMMAND, undefined);
              }}
              className={toolbarItemButtonRecipe({ spaced: true })}
              aria-label="Undo"
              type="button"
            >
              <FontAwesomeIcon icon={faUndoAlt} fixedWidth />
            </button>
            <button
              disabled={!canRedo}
              onClick={() => {
                editor.dispatchCommand(REDO_COMMAND, undefined);
              }}
              className={toolbarItemButton}
              aria-label="Redo"
              type="button"
            >
              <FontAwesomeIcon icon={faRedoAlt} fixedWidth />
            </button>
          </>
        )}
      </>
    </div>
  );
};

export default ToolbarPlugin;
