type TimeValue = {
  hours: number;
  minutes: number;
};

const padNumber = (num: number) => `${num < 10 ? '0' : ''}${num.toFixed(0)}`;

const formatTimeValue = ({ hours, minutes }: TimeValue) => `${padNumber(hours)}:${padNumber(minutes)}`;
const formatTimeLabel = ({ hours, minutes }: TimeValue) => {
  const labelDate = new Date();
  labelDate.setHours(hours, minutes);
  return labelDate.toLocaleTimeString('en', { hour: 'numeric', minute: 'numeric' });
};

const generateDataListOptions = (min: TimeValue, max: TimeValue) => {
  const minStep = min.hours * 4 + min.minutes / 15 + (min.minutes % 15 !== 0 ? 1 : 0);
  const maxStep = max.hours * 4 + (max.hours < 24 ? max.minutes / 15 : 0);
  const options: { value: string; label: string }[] = [];
  for (let curStep = minStep; curStep <= maxStep; curStep += 1) {
    const hours = Math.floor(curStep / 4);
    const minutes = (curStep % 4) * 15;
    options.push({
      value: formatTimeValue({
        hours,
        minutes,
      }),
      label: formatTimeLabel({
        hours,
        minutes,
      }),
    });
  }
  return options;
};

const parseTime = (strValue: string) => {
  const match = strValue.match(/([0-9]{1,2})[:,. -]?([0-9]{2})/);
  let res;
  if (match) {
    const hrsStr = match[1];
    const minsStr = match[2];
    const hours = Math.min(Number(hrsStr), 24);
    const minsAligned = Math.round(Number(minsStr) / 15) * 15;
    const minutes = minsAligned < 60 ? minsAligned : 45;
    res = { hours, minutes };
  } else {
    res = null;
  }

  return res;
};

export { formatTimeValue, formatTimeLabel, generateDataListOptions, parseTime, TimeValue };
