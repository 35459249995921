import React, { useEffect, useMemo, useState } from 'react';
import { DateRange, EventType } from 'web/components/calendar/common/types';
import { Button, Checkbox, FormGroup } from 'web/components/elements';
import TimeSelect from 'web/components/form-fields/time/TimeSelect';
import GenericReactModal from 'web/components/GenericReactModal';
import sc from 'web/components/styled';
import themeClasses from 'web/styles/themeClasses.css';

const Container = sc.div(themeClasses({ display: 'flex', flexDirection: 'column' }), { width: 320, maxWidth: '95vw' });

const StyledTimeSelect = sc(TimeSelect)(themeClasses({ flex: 'auto' }));

const TimeSelectContainer = sc.div(themeClasses({ display: 'flex', alignItems: 'center' }));

const ButtonGroup = sc.div(themeClasses({ display: 'flex', justifyContent: 'flex-end', marginTop: 5, width: '100%' }));

const StyledButton = sc(Button)(themeClasses({ marginLeft: 5 }));

type Props = {
  event: EventType;
  eventOnCreate: boolean;
  onClose: () => void;
  onDelete: () => void;
  onSave: (start: Date, end: Date, recurring: boolean) => void;
  isOpen: boolean;
};

const Title = sc.h3(themeClasses({ marginTop: 2 }));

const Message = sc.small(themeClasses({ marginTop: 2 }));

const SelectedEventModal = ({ event, eventOnCreate, isOpen, onClose, onDelete, onSave }: Props) => {
  const [recurring, setRecurring] = useState(event.extendedProps.recurring);
  const [dateRange, setDateRange] = useState<DateRange>(event);

  const onStartChange = ({ hours, minutes }: { hours: number; minutes: number }) => {
    setDateRange((prev) => {
      const start = new Date(prev.start);
      start.setHours(hours, hours === 23 ? Math.min(minutes, 30) : minutes);
      const end = new Date(Math.max(prev.end.getTime(), start.getTime() + 30 * 60 * 1000));
      return {
        start,
        end,
      };
    });
  };

  const onEndChange = ({ hours, minutes }: { hours: number; minutes: number }) => {
    setDateRange((prev) => {
      const minStartTime = new Date(prev.start).setHours(0, 0);
      const minEndTime = new Date(prev.start).setHours(0, 30);

      const correctedEndTime = new Date(prev.start).setHours(hours, hours === 24 ? 0 : minutes);
      const end = new Date(Math.max(correctedEndTime, minEndTime));

      let start = prev.start;
      if (end <= start) {
        const suggestedStartTime = end.getTime() - 30 * 60 * 1000;
        start = new Date(Math.max(suggestedStartTime, minStartTime));
      }

      return {
        start,
        end,
      };
    });
  };

  const toggleRecurring = () => setRecurring((t) => !t);
  const inPast = dateRange.start.getTime() < Date.now();

  useEffect(() => {
    if (inPast) setRecurring(true);
  }, [inPast, setRecurring]);

  const { start, end } = dateRange;

  const startValue = useMemo(
    () => ({
      hours: start.getHours(),
      minutes: start.getMinutes(),
    }),
    [start],
  );

  const endMin = useMemo(() => {
    const endMinDate = new Date(new Date(start).getTime() + 30 * 60 * 1000);
    return {
      hours: endMinDate.getHours(),
      minutes: endMinDate.getMinutes(),
    };
  }, [start]);

  const endValue = useMemo(
    () => ({
      hours: start.getDay() !== end.getDay() ? 24 : end.getHours(),
      minutes: end.getMinutes(),
    }),
    [start, end],
  );

  return (
    <GenericReactModal isOpen={isOpen} onRequestClose={onClose}>
      <Container>
        <Title>
          {recurring
            ? 'Every ' + start.toLocaleDateString('en', { weekday: 'long' })
            : `${start.toLocaleDateString('en', { weekday: 'long' })}, ${start.toLocaleDateString('en', {
                day: 'numeric',
                month: 'long',
              })}`}
        </Title>

        <TimeSelectContainer>
          <StyledTimeSelect
            min={{ hours: 0, minutes: 0 }}
            max={{ hours: 23, minutes: 30 }}
            value={startValue}
            onChange={onStartChange}
          />
          <div style={{ margin: '0 8px' }}>&mdash;</div>
          <StyledTimeSelect
            min={endMin}
            max={{
              hours: 24,
              minutes: 0,
            }}
            value={endValue}
            onChange={onEndChange}
          />
        </TimeSelectContainer>
        <FormGroup>
          <Checkbox checked={recurring} onChange={toggleRecurring} disabled={inPast}>
            Recurring
          </Checkbox>
        </FormGroup>
        {inPast && <Message>To create a non-recurring period select a future date</Message>}
        <ButtonGroup>
          {!eventOnCreate && (
            <StyledButton secondary md onClick={onDelete}>
              Delete
            </StyledButton>
          )}
          <StyledButton primary md onClick={() => onSave(start, end, recurring)}>
            {eventOnCreate ? 'Add' : 'Save'}
          </StyledButton>
        </ButtonGroup>
      </Container>
    </GenericReactModal>
  );
};

export default SelectedEventModal;
