import { HeadingNode, $createHeadingNode } from '@lexical/rich-text';
import { DOMConversionMap } from 'lexical';

const importDOM = HeadingNode.importDOM();

Object.defineProperty(HeadingNode, 'importDOM', {
  value: () => {
    const conversionFn = (tagType: 'h2' | 'h3') => () => ({
      conversion: () => ({ node: $createHeadingNode(tagType) }),
      priority: 0,
    });
    return {
      h1: conversionFn('h2'),
      h2: importDOM.h2,
      h3: importDOM.h3,
      h4: conversionFn('h3'),
      h5: conversionFn('h3'),
      h6: conversionFn('h3'),
    } as DOMConversionMap<HTMLElement>;
  },
});

export { HeadingNode };
