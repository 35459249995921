import { useLayoutEffect, useRef } from 'react';

const useMountedRef = () => {
  const mountedRef = useRef(true);
  // Using layout effect here as certain libs (e.g. Stripe) hook their lifecycle to the same events
  // and we should treat the component as unmounted to be compatible with those libs
  useLayoutEffect(() => {
    mountedRef.current = true;
    return () => {
      mountedRef.current = false;
    };
  }, []);
  return mountedRef;
};

export default useMountedRef;
