import { BlockWithAlignableContents } from '@lexical/react/LexicalBlockWithAlignableContents';
import { DecoratorBlockNode, SerializedDecoratorBlockNode } from '@lexical/react/LexicalDecoratorBlockNode';
import { assignInlineVars } from '@vanilla-extract/dynamic';
import { EditorConfig, ElementFormatType, LexicalEditor, LexicalNode, NodeKey } from 'lexical';
import React from 'react';
import { InlineButton } from 'web/components/elements';
import { FullWidthFigure, FullWidthIframeWrapper, aspectRatio } from 'web/components/elements/FullWidthFigure';
import themeClasses from 'web/styles/themeClasses.css';
import { OembedDataVideo } from 'web/utils/oembed';
import CaptionAltEditor from './CaptionAltEditor';

function VimeoComponent({
  className,
  format,
  nodeKey,
  videoId,
  oembed,
  caption,
  onRemove,
  onCaptionChange,
}: {
  className: {
    base: string;
    focus: string;
  };
  format: ElementFormatType | null;
  nodeKey: NodeKey;
  videoId: string;
  oembed: OembedDataVideo;
  caption: string;
  onRemove: () => void;
  onCaptionChange: (caption: string) => void;
}) {
  return (
    <BlockWithAlignableContents format={format} nodeKey={nodeKey} className={className}>
      <FullWidthFigure>
        {oembed.type === 'video' && (
          <FullWidthIframeWrapper
            dangerouslySetInnerHTML={{
              __html: oembed.html,
            }}
            style={assignInlineVars({
              [aspectRatio]: (oembed.width / oembed.height).toString(),
            })}
          />
        )}
        {!oembed && (
          <FullWidthIframeWrapper
            style={assignInlineVars({
              [aspectRatio]: (560 / 315).toString(),
            })}
          >
            <iframe
              src={`https://player.vimeo.com/video/${videoId}`}
              width="560"
              height="315"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen={true}
              title="Vimeo video"
            />
          </FullWidthIframeWrapper>
        )}
        <div className={themeClasses({ display: 'flex', justifyContent: 'flex-end', gap: 4, paddingTop: 1 })}>
          {caption && (
            <>
              <figcaption
                className={themeClasses({
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  flex: 'auto',
                })}
              >
                {caption}
              </figcaption>
            </>
          )}
          <CaptionAltEditor value={caption} onChange={onCaptionChange} kind="caption" />
          <InlineButton onClick={onRemove}>Remove</InlineButton>
        </div>
      </FullWidthFigure>
    </BlockWithAlignableContents>
  );
}

type SerializedVimeoNode = SerializedDecoratorBlockNode & {
  type: 'vimeo';
  videoId: string;
  url: string;
  oembed: OembedDataVideo;
  caption: string;
};

export class VimeoNode extends DecoratorBlockNode {
  __videoId: string;
  __url: string;
  __oembed: OembedDataVideo;
  __caption: string;

  getVideoId(): string {
    const self = this.getLatest();
    return self.__videoId;
  }

  setVideoId(id: string): void {
    const self = this.getWritable();
    self.__videoId = id;
  }

  getUrl(): string {
    const self = this.getLatest();
    return self.__url;
  }

  setUrl(url: string): void {
    const self = this.getWritable();
    self.__url = url;
  }

  getOembed(): OembedDataVideo {
    const self = this.getLatest();
    return self.__oembed;
  }

  setOembed(oembed: OembedDataVideo): void {
    const self = this.getWritable();
    self.__oembed = oembed;
  }

  getCaption(): string {
    const self = this.getLatest();
    return self.__caption;
  }

  setCaption(caption: string): void {
    const self = this.getWritable();
    self.__caption = caption;
  }

  static getType(): 'vimeo' {
    return 'vimeo';
  }

  static clone(node: VimeoNode): VimeoNode {
    return new VimeoNode(node.__videoId, node.__url, node.__oembed, node.__caption, node.__format, node.__key);
  }

  constructor(
    videoId: string,
    url: string,
    oembed: OembedDataVideo,
    caption: string,
    format?: ElementFormatType | null,
    key?: NodeKey,
  ) {
    super(format, key);
    this.__videoId = videoId;
    this.__url = url;
    this.__oembed = oembed;
    this.__caption = caption;
  }

  updateDOM(): false {
    return false;
  }

  decorate(editor: LexicalEditor, config: EditorConfig): JSX.Element {
    const className = {
      base: config.theme.embedBlock?.base || '',
      focus: config.theme.embedBlock?.focus || '',
    };
    return (
      <VimeoComponent
        format={this.__format}
        nodeKey={this.getKey()}
        videoId={this.__videoId}
        oembed={this.__oembed}
        className={className}
        caption={this.__caption}
        onRemove={() => editor.update(() => this.remove())}
        onCaptionChange={(caption) => editor.update(() => this.setCaption(caption))}
      />
    );
  }

  isTopLevel(): true {
    return true;
  }

  isIsolated(): true {
    return true;
  }

  isInline(): false {
    return false;
  }

  exportJSON(): SerializedVimeoNode {
    return {
      ...super.exportJSON(),
      type: 'vimeo',
      videoId: this.__videoId,
      url: this.__url,
      oembed: this.__oembed,
      caption: this.__caption,
    };
  }

  static importJSON(serializedNode: SerializedVimeoNode): VimeoNode {
    const node = $createVimeoNode(
      serializedNode.videoId,
      serializedNode.url,
      serializedNode.oembed,
      serializedNode.caption,
    );
    node.setFormat(serializedNode.format);
    return node;
  }
}

export function $createVimeoNode(videoId: string, url: string, oembed: OembedDataVideo, caption: string): VimeoNode {
  return new VimeoNode(videoId, url, oembed, caption);
}

export function $isVimeoNode(node: VimeoNode | LexicalNode | null | undefined): node is VimeoNode {
  return node instanceof VimeoNode;
}
