import type { DateSpanApi, EventApi } from '@fullcalendar/react';
import React, { useContext, useRef } from 'react';
import TimeZoneContext from 'web/components/timezone/TimeZoneContext';
import FullCalendarLoadable, { FullCalendar } from './FullCalendarLoadable';
import { CalendarContainer, CalendarLoading } from './styles';
import { CalendarProps, ViewType } from './types';
import dayHeaderContent from './dayHeaderContent';
import Toolbar from '../toolbar/Toolbar';

const AvailabilityCalendar = ({
  dateRange,
  onDateRangeChange,
  events,
  onEventsChanged,
  onDateSelect,
  onEventSelect,
  loading,
  saving,
  eventContent,
  slotLabelContent,
  allowMultiDaySelect,
}: CalendarProps) => {
  // TODO: wrap parent component with TimeZoneProvider
  const { timeZone, offset } = useContext(TimeZoneContext);
  const calendarRef = useRef<FullCalendar>(null);

  const handleToolbarPrev = () => {
    calendarRef.current.getApi().prev();
  };

  const handleToolbarNext = () => {
    calendarRef.current.getApi().next();
  };

  const handleToolbarToday = () => {
    // TODO: make scroll after slots loading and TODAY column blinking
    calendarRef.current.getApi().today();
    // Set a timeout to wait for FullCalendar to change the week view
    // before scrolling to the current time. Otherwise the change in
    // the view overrides the scroll position with the default one.
    setTimeout(() => {
      calendarRef.current.getApi().scrollToTime({
        hours: new Date(new Date().getTime() - (-new Date().getTimezoneOffset() - offset) * 60 * 1000).getHours(),
      });
    }, 200);
  };

  const onViewChanged = (viewType: ViewType) => {
    calendarRef.current.getApi().changeView(viewType);
  };

  const selectAllow = (span: DateSpanApi, event: EventApi): boolean => {
    if (loading || saving) return false;

    // disable select event in few days
    if (!allowMultiDaySelect && span.start.getDay() !== span.end.getDay()) {
      if (span.end.getHours() === 0 && span.end.getMinutes() === 0) return true; // if 0:00 next day then allow
      return false;
    }

    if (event && !event.extendedProps.recurring && span.start.getTime() < Date.now()) {
      return false;
    }

    return true;
  };

  return (
    <div>
      <Toolbar
        onToday={handleToolbarToday}
        onPrev={handleToolbarPrev}
        onNext={handleToolbarNext}
        dateRange={dateRange}
        onViewChanged={onViewChanged}
        onlyWeekView={true}
        loading={loading}
        saving={saving}
      />
      <CalendarContainer>
        {loading && <CalendarLoading />}
        <FullCalendarLoadable
          type="edit"
          ref={calendarRef}
          headerToolbar={{
            left: '',
            center: '',
            right: '',
          }}
          height="70vh"
          scrollTime={{ hours: 8 }}
          initialDate={dateRange?.start}
          timeZone={timeZone}
          datesSet={onDateRangeChange}
          initialView="timeGridWeek"
          slotLabelContent={slotLabelContent}
          dayHeaderContent={(args) => dayHeaderContent(args, timeZone)}
          eventContent={eventContent}
          // nowIndicator is not timezone-friendly, it doesn't rerender
          // when the timzone changes. Disable it until we find a fix
          // nowIndicator={true}
          editable={true}
          selectable={true}
          weekends={true}
          allDaySlot={false}
          slotEventOverlap={false}
          firstDay={1}
          events={events}
          selectAllow={selectAllow}
          eventAllow={selectAllow}
          eventClick={onEventSelect}
          eventChange={onEventsChanged}
          select={onDateSelect}
          unselectAuto={true}
          longPressDelay={200}
          eventLongPressDelay={200}
          selectLongPressDelay={200}
        />
      </CalendarContainer>
    </div>
  );
};

export default AvailabilityCalendar;
