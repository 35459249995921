import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { LexicalEditor } from 'lexical';
import React, { useEffect } from 'react';

const OnInitPlugin = ({ onInit }: { onInit?: (editor: LexicalEditor) => void }) => {
  const [editor] = useLexicalComposerContext();
  useEffect(() => {
    if (onInit) {
      onInit(editor);
    }
  }, [editor, onInit]);
  return <></>;
};

export default OnInitPlugin;
